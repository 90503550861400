<script>
import { VAutocomplete } from "vuetify/lib";
import { useForm, useModelValue, useValidationProvider } from "../../../mixins";

export default {
  name: "VxAutocomplete",
  components: {
    VAutocomplete,
  },
  mixins: [useForm(), useModelValue(), useValidationProvider()],
  inheritAttrs: false,
  methods: {
    /* istanbul ignore next */
    focus() {
      this.$refs.autocomplete.focus();
    },
    /* istanbul ignore next */
    validator() {
      return this.$refs.provider;
    },
  },
};
</script>

<template>
  <ValidationProvider
    ref="provider"
    :vid="name"
    :name="label"
    :rules="rules"
    :mode="mode"
    slim
  >
    <template v-slot="{ errors }">
      <v-autocomplete
        ref="autocomplete"
        v-model="modelValue"
        :name="name"
        :label="label"
        :error-messages="errors"
        :outlined="isOutlined"
        :persistent-placeholder="isPersistentPlaceholder"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template v-slot:append>
          <slot name="append"></slot>
        </template>
        <template v-slot:append-item>
          <slot name="append-item"></slot>
        </template>
        <template v-slot:append-outer>
          <slot name="append-outer"></slot>
        </template>
        <template v-slot:label>
          <slot name="label"></slot>
        </template>
        <template v-slot:prepend>
          <slot name="prepend"></slot>
        </template>
        <template v-slot:prepend-item>
          <slot name="prepend-item"></slot>
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner"></slot>
        </template>
        <template v-slot:progress>
          <slot name="progress"></slot>
        </template>
        <template v-slot:no-data>
          <slot name="no-data"></slot>
        </template>
      </v-autocomplete>
    </template>
  </ValidationProvider>
</template>
