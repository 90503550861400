import axios from "axios";
// import { setupCache } from 'axios-cache-adapter'
import setupInterceptor from "@/utils/interceptor";
import { objectToStringParams } from "@/utils/http";

const $http = axios.create({
  baseURL: `/api/vue`,
});
setupInterceptor($http);

const state = () => ({
  couponCampaigns: [],
  earnableCouponCampaign: { id: null },
  redeemableCouponCampaign: { id: null },
  selectedCouponCampaign: { id: null },
  coupons: [],
});

const mutations = {
  setCoupons(state, coupons) {
    state.coupons = coupons;
  },
  setCouponCampaigns(state, couponCampaign) {
    state.couponCampaigns = couponCampaign;
  },
  setEarnableCouponCampaign(state, couponCampaign) {
    state.earnableCouponCampaign = couponCampaign;
  },
  setRedeemableCouponCampaign(state, couponCampaign) {
    state.redeemableCouponCampaign = couponCampaign;
  },
  setSelectedCouponCampaign(state, couponCampaign) {
    state.selectedCouponCampaign = couponCampaign;
  },
};

const getters = {
  earnableCouponCampaign: (state) => state.earnableCouponCampaign,
};

const actions = {
  getCoupons({ rootGetters }, pagination) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    const url = `${storeId}/coupons?${objectToStringParams(pagination)}`;
    return $http.get(url, { headers });
  },
  getCouponByNumber({ rootGetters }, { number, all }) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    let url = `${storeId}/coupons/search?number=${number.trim()}`;
    if (all) {
      url = url + `&all=${true}`;
    }
    return $http.get(url, { headers });
  },
  apiSearchCoupons({ rootGetters }, { q, all, pagination }) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    let url = `${storeId}/coupons/search?q=${q.trim()}&${objectToStringParams(
      pagination
    )}`;
    if (all) {
      url = url + `&all=${true}`;
    }
    return $http.get(url, { headers });
  },
  async loadCoupons({ commit, dispatch }) {
    const resp = await this.getCoupons();
    // const resp = await dispatch("getCoupons");
    let coupons = resp.data.data;
    commit("setCoupons", coupons);
    return resp.data;
  },
  getEarnableCampaign({ rootGetters }) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    const url = `/${storeId}/coupon_campaigns?earnable=true`;
    return $http.get(url, { headers });
  },
  async loadEarnableCampaign({ commit, dispatch }) {
    const resp = await dispatch("getEarnableCampaign");
    let campaign = resp.data;
    if (Array.isArray(campaign)) {
      campaign = campaign[0];
    }
    commit("setEarnableCouponCampaign", campaign);
  },
  getRedeemableCampaign({ rootGetters }) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    const url = `/${storeId}/coupon_campaigns?redeemable=true`;
    return $http.get(url, { headers });
  },
  async loadRedeemableCampaign({ commit, dispatch }) {
    const resp = await dispatch("getRedeemableCampaign");
    let campaign = resp.data;
    if (Array.isArray(campaign)) {
      campaign = campaign[0];
    }
    commit("setRedeemableCouponCampaign", campaign);
  },
  loadActiveCouponCampaigns({ dispatch }) {
    dispatch("loadEarnableCampaign");
    dispatch("loadRedeemableCampaign");
  },
  getCouponsForCampaign({ rootGetters }, campaignId) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    const url = `/${storeId}/coupon_campaigns/${campaignId}/coupons`;
    return $http.get(url, { headers });
  },
  createCoupon({ rootGetters }, payload) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    const url = `/${storeId}/coupons`;
    return $http.post(url, payload, { headers });
  },
  redeemCoupon({ rootGetters }, couponId) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    const url = `/${storeId}/coupons/${couponId}/redeem`;
    return $http.patch(url, {}, { headers });
  },
  getPrinterJson({ rootGetters }, couponId) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const url = `/${storeId}/coupons/${couponId}?printer=true`;
    return $http.get(url);
  },
  updateCoupon({ rootGetters }, payload) {
    const storeId = rootGetters["stores/selectedStore"].id;
    const headers = rootGetters["auth/authHeaders"];
    const url = `/${payload.coupon.store_id}/coupons/${payload.coupon.id}`;
    return $http.patch(url, payload, { headers });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
