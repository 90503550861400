<template>
  <VxTextField
    ref="textField"
    type="tel"
    :counter-value="(v) => (internalValue ? internalValue.length : 0)"
    :counter="counter"
    v-bind="computedProps"
    v-on="computedListeners"
  />
</template>

<script>
import VxTextField from "../VxTextField/VxTextField.vue";
import {
  cleanPhoneNumber,
  formatPhoneNumber,
} from "../../../formatting/phone-formatting";

// TODO: fix non-numeric values being inputed
// TODO: rename this to RaiPhoneField and move it to appropriate folder
// TODO: implement NA phone validation explicitly because it's going to be only used for NA phones

export default {
  name: "VxPhoneField",
  components: {
    VxTextField,
  },
  inheritAttrs: false,
  props: {
    counter: {
      type: Number,
      default: 10,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      // This should be a clean number, just the digits of the phone
      internalValue: this.value ? cleanPhoneNumber(this.value) : undefined,
    };
  },
  computed: {
    parsedValue() {
      return formatPhoneNumber(this.internalValue);
    },
    computedProps() {
      return { ...this.$attrs, "v-model": undefined, value: this.parsedValue };
    },
    computedListeners() {
      return { ...this.$listeners, input: this.onInput, change: this.onChange };
    },
  },
  watch: {
    value(newValue) {
      this.internalValue = cleanPhoneNumber(newValue);
    },
  },
  mounted() {
    // TODO - check if this mounted is needed at all
    this.internalValue = cleanPhoneNumber(this.value);
  },
  methods: {
    validator() {
      return this.$refs.textField.validator();
    },
    focus() {
      this.$refs.textField.focus();
    },
    onInput(newValue) {
      this.internalValue = cleanPhoneNumber(newValue);
      this.$emit("input", this.internalValue);
    },
    onChange(newValue) {
      this.internalValue = cleanPhoneNumber(newValue);
      this.$emit("change", this.internalValue);
    },
  },
};
</script>
