import { featureApi } from "@/core-ui";

const buysRoute = {
  // This route has no name, as when using nested routes (with a default nested route)
  // it's better to use directly default child route

  components: {
    default: () => import(/* webpackChunkName: "buys" */ "@/views/Buy/AppView"),
    appbar: () =>
      import(/* webpackChunkName: "buys" */ "@/views/Buy/AppView/BuysAppBar"),
    dialog: () => import("@/views/Common/RouterDefaultViewWrapper"),
    bardcodeScan: () => import("@/views/Buy/AppView/BuyScan"),
  },
  meta: {
    requiresAuth: true,
    title: "Buys",
  },
  children: [
    {
      name: "buys_home",
      path: "",
      component: () =>
        import(/* webpackChunkName: "buys" */ "@/views/Common/NewDetailButton"),
      props: (route) => ({
        routeName: featureApi.isVisible("checkinFlowVersion", "V2")
          ? "checkin.new"
          : "buys_new",
      }),
    },
    {
      name: "checkin.new",
      path: "detail/new",
      component: () =>
        import(
          /* webpackChunkName: "buys_v2" */ "@/views/Buy/AppView/CheckinRequestDetailView"
        ),
      props: (route) => ({
        customerRouteName: "buys_customer",
        buyDetailsTab: route.query.buyDetailsTab,
      }),
    },
    {
      name: "buys_edit_checkinrequset",
      path: "detail/edit/checkinRequest/:checkinRequestId",
      component: () =>
        import(
          /* webpackChunkName: "buy_detail" */ "@/views/Buy/AppView/CheckinRequestDetailView"
        ),
      props: (route) => ({
        customerRouteName: "buys_customer",
        buyDetailsTab: route.query.buyDetailsTab,
      }),
    },
    {
      name: "buys_new",
      path: "detail/v1_new",
      component: () => import("@/views/Buy/AppView/StartBuyDialog"),
      props: (route) => ({
        initialLicenseData: route.query.initialLicenseData,
        initialPhoneNumber: route.query.initialPhoneNumber,
        checkinRequestId: route.query.checkinRequestId,
      }),
    },
    {
      name: "buys_edit",
      path: "detail/edit/:buyId",
      component: () =>
        import(
          /* webpackChunkName: "buy_detail" */ "@/views/Buy/AppView/BuyDetailView"
        ),
      props: (route) => ({
        customerRouteName: "buys_customer",
      }),
    },
    {
      name: "buys_customer",
      path: "detail/customer/show/:customerId",
      component: () => import("@/components/customers/CustomerDetailDialog"),
      props: (route) => ({
        buysRouteName: "buys_edit",
      }),
    },
  ],
};

const buysCommonRouteProps = (route) => {
  return {
    currentTab: route.query.buysTab || "open",
  };
};

const buysSetupRoute = {
  path: "",
  meta: {
    requiresAuth: true,
    requiresManager: true,
  },
  components: {
    default: () => import("@/views/StoreSettingsView"),
  },
  children: [
    {
      name: "buy.setup",
      path: "",
      component: () => import("@/views/Buy/SetupView"),
      meta: {
        toolbarTitle: "Buys",
        title: "Buys setup",
        description:
          "The following settings are necessary before you being checking in buys with ResaleAI",
      },
    },
    {
      name: "buy.setup.prewrittensms.edit",
      path: "prewritten-sms/:messageId",
      component: () => import("@/views/Texting/SetupView/EditMessageView"),
      meta: {
        title: "Edit message template",
        toolbarTitle: "Set up prewritten text messages",
        description:
          "For best results, use simple, natural-sounding language. Avoid all-caps, or overly formal or aggressive language that may get marked as spam.",
      },
    },
    {
      name: "buy.setup.prewrittensms.new",
      path: "prewritten-sms",
      component: () => import("@/views/Texting/SetupView/NewMessageView"),
      meta: {
        title: "New message template",
        toolbarTitle: "Set up prewritten text messages",
        description:
          "For best results, use simple, natural-sounding language. Avoid all-caps, or overly formal or aggressive language that may get marked as spam.",
      },
    },
    {
      name: "buy.setup.prewrittensms.edit.autoreply",
      path: "prewritten-sms/:messageId/auto-reply",
      component: () => import("@/views/Texting/SetupView/EditMessageView"),
      props: () => ({
        autoReply: true,
      }),
      meta: {
        title: "Edit auto-reply message",
        toolbarTitle: "Set up prewritten text messages",
        description: [
          `Set up automated responses to answer common questions or give more details. 
          Customers text a shortcode, such as "INFO" or “SELL”, and it will send an automatic response.`,
          `
            Once you create a shortcode, be sure to communicate it to customers. 
            Consider adding a prompt in one of your other messages, such as "Text INFO for more." 
            or create in-store signage to say "Text SELL to 123-456-7890" with your texting number.
            `,
        ],
      },
    },
    {
      name: "buy.setup.prewrittensms.new.autoreply",
      path: "prewritten-sms/auto-reply",
      component: () => import("@/views/Texting/SetupView/NewMessageView"),
      props: () => ({
        autoReply: true,
      }),
      meta: {
        title: "New auto-reply message",
        toolbarTitle: "Set up prewritten text messages",
        description: [
          `Set up automated responses to answer common questions or give more details. 
          Customers text a shortcode, such as "INFO" or “SELL”, and it will send an automatic response.`,
          `
            Once you create a shortcode, be sure to communicate it to customers. 
            Consider adding a prompt in one of your other messages, such as "Text INFO for more." 
            or create in-store signage to say "Text SELL to 123-456-7890" with your texting number.
            `,
        ],
      },
    },
  ],
};

const buysSetupCommonRouteProps = (route) => {
  return {
    raiLinkTo: {
      addMessageTemplate: "buy.setup.prewrittensms.new",
      addAutoReply: "buy.setup.prewrittensms.new.autoreply",
      editMessageTemplate: "buy.setup.prewrittensms.edit",
      editAutoReply: "buy.setup.prewrittensms.edit.autoreply",
    },
  };
};

export {
  buysRoute,
  buysCommonRouteProps,
  buysSetupRoute,
  buysSetupCommonRouteProps,
};
