<script>
import { VIcon } from "vuetify/lib";
import RaiNumber from "../RaiNumber/RaiNumber.vue";

export default {
  name: "RaiTrend",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    decimals: {
      type: [String, Number],
      default: 1,
    },
    appendText: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    upIcon: {
      type: String,
      default: "$trendingUp",
    },
    upSuffix: {
      type: String,
      default: "$vuetify.rai.metricTrend.upSuffix",
    },
    downIcon: {
      type: String,
      default: "$trendingDown",
    },
    downSuffix: {
      type: String,
      default: "$vuetify.rai.metricTrend.downSuffix",
    },
    showSuffix: {
      type: Boolean,
      default: true,
    },
    neutralIcon: {
      type: String,
      default: "$trendingNeutral",
    },
  },
  computed: {
    classes() {
      const { color } = this.options;
      return { [`${color}--text`]: !!color };
    },
    options() {
      let options = null;

      if (this.isUp) {
        options = {
          color: this.color || "success",
          icon: this.upIcon,
          prefix: "+",
          suffix: this.upSuffix,
        };
      } else if (this.isDown) {
        options = {
          color: this.color || "error",
          icon: this.downIcon,
          suffix: this.downSuffix,
        };
      } else {
        options = { color: this.color, icon: this.neutralIcon, suffix: "" };
      }

      options.suffix = this.showSuffix
        ? this.$vuetify.lang.t(options.suffix)
        : "";

      return options;
    },
    isUp() {
      return this.value > 0;
    },
    isDown() {
      return this.value < 0;
    },
  },
  methods: {
    format(value) {
      const { prefix = "", suffix = "" } = this.options;
      return `${prefix}${value} ${suffix} ${this.appendText}`;
    },
  },
  render(h) {
    const { color, icon } = this.options;

    return h(
      "div",
      {
        staticClass: "rai-trend",
        class: this.classes,
      },
      [
        h(VIcon, { props: { left: true, color: color } }, icon),
        h(RaiNumber, {
          props: {
            value: this.value,
            type: "percent",
            decimals: this.decimals,
          },
          scopedSlots: {
            default: ({ number }) => this.format(number),
          },
        }),
      ]
    );
  },
};
</script>
