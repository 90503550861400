import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faGripLines,
  faSignOut,
  faCreditCard,
  faBarcodeRead,
  faDollarSign,
  faStickyNote,
  faAddressCard,
  faCommentAltEdit,
  faCommentAlt,
  faStopwatch,
  faWindowMinimize,
  faSquare,
  faClone,
  faSmile,
  faStar as farStar,
} from "@fortawesome/pro-regular-svg-icons";

import { faShopify } from "@fortawesome/free-brands-svg-icons";

import {
  faStarOfLife,
  faCircle,
  faBook,
  faComments,
  faTags,
  faArchive,
  faUser,
  faUsers,
  faGift,
  faChartLine,
  faCogs,
  faFlask,
  faHeartbeat,
  faFlag,
  faPaperPlane,
  faSignature,
  faPhone,
  faEnvelope,
  faCamera,
  faPrint,
  faPrintSlash,
  faKey,
  faHome,
  faUserAltSlash,
  faInventory,
  faStar as fasStar,
  faCartArrowDown,
  faShoppingCart,
  faThumbsUp as fasThumbsUp,
  faThumbsDown as fasThumbsDown,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faCheck,
  faCalendarAlt,
  faThumbsUp,
  faThumbsDown,
  faTimes,
  faClock,
} from "@fortawesome/pro-light-svg-icons";

Vue.component("FontAwesomeIcon", FontAwesomeIcon); // Register component globally
library.add(
  faGripLines,
  faSignOut,
  faCreditCard,
  faBarcodeRead,
  faDollarSign,
  faStickyNote,
  faAddressCard,
  faCommentAltEdit,
  faCommentAlt,
  faStopwatch,
  faWindowMinimize,
  faSquare,
  faClone,
  faSmile,
  faShopify
);
library.add(
  faStarOfLife,
  faCircle,
  faBook,
  faComments,
  faTags,
  faArchive,
  faUser,
  faUsers,
  faGift,
  faChartLine,
  faCogs,
  faFlask,
  faHeartbeat,
  faFlag,
  faPaperPlane,
  faSignature,
  faPhone,
  faEnvelope,
  faCamera,
  faPrint,
  faPrintSlash,
  faKey,
  faHome,
  faUserAltSlash,
  faInventory,
  faShoppingCart,
  fasThumbsUp,
  fasThumbsDown
);
library.add(faCheck, faCalendarAlt, faThumbsUp, faThumbsDown, faTimes, faClock);

/**
 * defaults:
 *  complete
 *  cancel
 *  close
 *  delete // delete (e.g. v-chip close)
 *  clear
 *  success
 *  info
 *  warning
 *  error
 *  prev
 *  next
 *  checkboxOn
 *  checkboxOff
 *  checkboxIndeterminate
 *  delimiter // for carousel
 *  sort
 *  expand
 *  menu
 *  subgroup
 *  dropdown
 *  radioOn
 *  radioOff
 *  edit
 *  ratingEmpty
 *  ratingFull
 *  ratingHalf
 *  loading
 *  first
 *  last
 *  unfold
 *  file
 *  plus
 *  minus
 */
import {
  mdiLock,
  mdiLockOpen,
  mdiDotsVertical,
  mdiArrowLeft,
  mdiHelpCircle,
  mdiHelpCircleOutline,
  mdiCommentAlert,
  mdiRefresh,
  mdiPlus,
  mdiMinus,
  mdiMagnify,
  mdiBackspaceOutline,
  mdiFormatBold,
  mdiAccountSwitch,
  mdiDelete,
  mdiAlert,
  mdiStarOutline,
  mdiMenuDown,
  mdiCurrencyUsd,
  mdiPackageVariantClosed,
  mdiChevronRight,
  mdiChevronLeft,
  mdiSort,
  mdiSortAscending,
  mdiSortDescending,
  mdiStar,
  mdiRotateLeft,
  mdiRotateRight,
  mdiEye,
  mdiEyeOff,
  mdiAccount,
  mdiAccountPlus,
  mdiNoteMultipleOutline,
  mdiAlarmOff,
  mdiAlertCircleOutline,
  mdiDragHorizontal,
  mdiDragHorizontalVariant,
  mdiCalendarPlus,
  mdiNotePlusOutline,
  mdiTimelinePlusOutline,
  mdiOpenInNew,
  mdiCheckCircle,
  mdiContentSave,
  mdiArrowRight,
  mdiArrowDown,
  mdiMessage,
  mdiFilterMenu,
  mdiFilterCheck,
  mdiDevices,
  mdiCertificate,
  mdiAutoFix,
  mdiSync,
  mdiPodiumGold,
  mdiDownload,
  mdiStore,
  mdiEmail,
  mdiSwapHorizontal,
  mdiAccountMultiple,
  mdiDrag,
  mdiRepeat,
  mdiCalendarClockOutline,
  mdiClockStart,
  mdiClockEnd,
  mdiPencilOff,
  mdiBadgeAccount,
  mdiRayStartArrow,
  mdiCoffee,
  mdiCoffeeOff,
  mdiShopping,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";

export const ICONS_RESALEAI = {
  iconfont: "mdiSvg",

  values: {
    mdiAlertCircleOutline,
    assignTask: mdiAccountPlus,
    noNotes: mdiNoteMultipleOutline,
    noShifts: mdiAlarmOff,
    taskFab: mdiTimelinePlusOutline,
    noteFab: mdiNotePlusOutline,
    shiftFab: mdiCalendarPlus,
    bold: mdiFormatBold,
    more: mdiDotsVertical,
    back: mdiArrowLeft,
    help: mdiHelpCircle,
    helptip: mdiHelpCircleOutline,
    chat: mdiCommentAlert,
    refresh: mdiRefresh,
    add: mdiPlus, // TODO: remove and convert to plus
    plus: mdiPlus,
    minus: mdiMinus,
    search: mdiMagnify,
    backspace: mdiBackspaceOutline,
    lock: mdiLock,
    menuDown: mdiMenuDown,
    unlock: mdiLockOpen,
    up: mdiChevronUp,
    down: mdiChevronDown,
    left: mdiChevronLeft,
    right: mdiChevronRight,
    asc: mdiSortAscending,
    desc: mdiSortDescending,
    rotateLeft: mdiRotateLeft,
    rotateRight: mdiRotateRight,
    shopify: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fab", "shopify"],
      },
      desc: "Shopify logo",
    },
    emergency: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "star-of-life"],
      },
      desc: "Emergency Contact",
    },
    check: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fal", "check"],
      },
    },
    calendar: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fal", "calendar-alt"],
      },
    },
    grip: mdiDragHorizontalVariant,
    gripDots: mdiDragHorizontal,

    dot: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "circle"],
      },
    },
    raiDaybook: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "book"],
        transform: "shrink-3",
      },
    },
    raiTexting: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "comments"],
        transform: "shrink-3",
      },
    },
    raiBuys: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "tags"],
        transform: "shrink-3",
      },
    },
    raiInventory: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "inventory"],
        transform: "shrink-3",
      },
    },
    raiOrders: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "shopping-cart"],
        transform: "shrink-3",
      },
    },
    raiBackstock: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "archive"],
        transform: "shrink-3",
      },
    },
    raiCustomer: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "user"],
        transform: "shrink-3",
      },
    },
    raiTeam: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "users"],
        transform: "shrink-3",
      },
    },
    raiBounceback: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "gift"],
        transform: "shrink-3",
      },
    },
    moveBin: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "sign-out"],
      },
    },
    raiReports: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "chart-line"],
        transform: "shrink-3",
      },
    },
    raiSettings: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "cogs"],
        transform: "shrink-2",
      },
    },
    raiLabs: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "flask"],
      },
    },
    raiBilling: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "credit-card"],
      },
    },
    raiBarcode: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "barcode-read"],
      },
    },
    raiAudits: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "heartbeat"],
        transform: "shrink-3",
      },
    },
    thumbsUp: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fal", "thumbs-up"],
      },
    },
    thumbsDown: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fal", "thumbs-down"],
      },
    },
    thumbsUpFull: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "thumbs-up"],
      },
    },
    thumbsDownFull: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "thumbs-down"],
      },
    },
    flag: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "flag"],
      },
    },

    dollarSign: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "dollar-sign"],
      },
    },
    stickyNote: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "sticky-note"],
      },
    },
    sendText: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "paper-plane"],
      },
    },
    reply: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "paper-plane"],
      },
    },
    signature: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fa", "signature"],
      },
    },
    license: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "address-card"],
      },
    },
    comment: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "comment-alt-edit"],
      },
    },
    noteComment: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "comment-alt"],
      },
    },
    phone: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "phone"],
      },
    },
    email: mdiEmail,
    camera: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "camera"],
      },
    },
    print: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "print"],
      },
    },
    printOff: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "print-slash"],
      },
    },
    user: mdiAccount,
    users: mdiAccountMultiple,
    key: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "key"],
        transform: "shrink-2",
      },
    },

    visibilityOn: mdiEye,
    visibilityOff: mdiEyeOff,
    stopwatch: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "stopwatch"],
        transform: "shrink-2",
      },
    },
    winMinimize: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "window-minimize"],
        transform: "shrink-1",
      },
    },
    winMaximize: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "square"],
        transform: "shrink-3",
      },
    },
    winResize: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "clone"],
        transform: "shrink-4",
      },
    },
    winClose: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fal", "times"],
      },
    },
    home: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "home"],
      },
    },
    clock: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fal", "clock"],
      },
    },
    smile: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "smile"],
      },
    },
    noUser: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "user-alt-slash"],
      },
    },
    drsInitials: mdiAccountSwitch,
    delete: mdiDelete,
    alert: mdiAlert,
    starOn: {
      component: FontAwesomeIcon,
      props: {
        icon: ["fas", "star"],
      },
    },
    starOff: {
      component: FontAwesomeIcon,
      props: {
        icon: ["far", "star"],
      },
    },
    starOff2: mdiStarOutline,
    starOn2: mdiStar,
    orderPaid: mdiCurrencyUsd,
    orderFulfilled: mdiPackageVariantClosed,
    sort: mdiSort,
    dragHandle: mdiDrag,
    openInNew: mdiOpenInNew,
    checkCircle: mdiCheckCircle,
    save: mdiContentSave,
    arrowRight: mdiArrowRight,
    arrowDown: mdiArrowDown,
    message: mdiMessage,
    filterMenu: mdiFilterMenu,
    filterCheck: mdiFilterCheck,
    devices: mdiDevices,
    certificate: mdiCertificate,
    autoFix: mdiAutoFix,
    sync: mdiSync,
    goal: mdiPodiumGold,
    download: mdiDownload,
    store: mdiStore,
    swapHorizontal: mdiSwapHorizontal,
    repeat: mdiRepeat,
    calendarClock: mdiCalendarClockOutline,
    clockStart: mdiClockStart,
    clockEnd: mdiClockEnd,
    editOff: mdiPencilOff,
    badgeAccount: mdiBadgeAccount,
    startOnboarding: mdiRayStartArrow,
    coffee: mdiCoffee,
    coffeeOff: mdiCoffeeOff,
    shoppingBag: mdiShopping,
  },
};
