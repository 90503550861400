const getPreloader = () => document.getElementById("rai-preload");

const displayPreLoader = () => {
  const el = getPreloader();
  el && (el.style.display = "");
};

const hidePreloader = () => {
  const el = getPreloader();
  el && (el.style.display = "none");
};

export const removeRaiLoading = () => {
  document.body.classList.remove("rai-loading");

  // Set the display to "none" after the fadeOut
  setTimeout(() => {
    hidePreloader();
  }, 500);
};

export const addRaiLoading = () => {
  // Ensure the preloader is visible
  displayPreLoader();

  if (document.body.classList.contains("rai-loading")) return;

  document.body.classList.add("rai-loading");
};
