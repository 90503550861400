<script>
import { VForm } from "vuetify/lib";
import VxAlert from "../VxAlert/VxAlert";
import { getSlot, hasListener } from "../../../utils";
import { ValidationObserver } from "vee-validate";

export default {
  name: "VxForm",
  extends: VForm,
  inheritAttrs: false,
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    focusOnInvalid: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    fieldErrorMessages: {
      type: Object,
      default: () => ({}),
    },
    scrollOptions: {
      type: Object,
      default: () => ({
        // ? https://vuetifyjs.com/en/features/scrolling/#api
        offset: 150,
        duration: 500,
        easing: "easeInOutCubic",
      }),
    },
    observer: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    submitting: false,
    errors: [],
  }),
  computed: {
    classes() {
      return {
        "vx-form": true,
        "vx-form--outlined": this.outlined,
        "vx-form--submitting": this.submitting,
      };
    },
    values() {
      const values = (acc, curr) => ({
        ...acc,
        [curr.name || curr.$attrs.name]:
          curr.$attrs.dateValue ?? curr.value ?? curr.inputValue,
      });

      return this.inputs
        .filter((x) => !!x.name || !!x.$attrs.name)
        .reduce(values, {});
    },
    invalidInputs() {
      return this.inputs.filter((x) => x.$parent.flags?.invalid === true);
    },
    defaultSlotProps() {
      return {
        reset: this.reset,
        submit: this.submit,
        submitting: this.submitting,
        errors: this.errors,
      };
    },
    hasErrors() {
      return this.errors.length > 0;
    },
  },
  created() {
    const { errorMessages, fieldErrorMessages } = this.$options.propsData;

    if (errorMessages) {
      this.$watch("errorMessages", (value) => this.setErrors(value), {
        immediate: true,
      });
    }

    if (fieldErrorMessages) {
      this.$watch("fieldErrorMessages", (value) => this.setFieldErrors(value));
      this.$nextTick(() => this.setFieldErrors(fieldErrorMessages));
    }
  },
  methods: {
    async submit(event) {
      const isValidForm = await this.validate();

      if (!isValidForm) {
        this.focusOnInvalid && this.focusInvalidInput();
        return;
      }

      const payload = {
        values: this.values,
        resolve: this.resolve,
        setErrors: this.setErrors,
        setFieldErrors: this.setFieldErrors,
      };

      this.submitting = hasListener(this, "submit");
      this.$emit("submit", payload, event);
    },

    async submitField(fieldNameArr, event, callback) {
      // TODO - try to use validate here, and check for error on this.$refs.observer
      const validateInfo = await this.$refs.observer.validateWithInfo({
        silent: true,
      });

      // For some reasons fields[<field name>] is not in sync with errors[<field name>]
      // TODO - try to add next tick around the rest of function, to resolve it

      function areFieldsValid() {
        for (let i = 0; i < fieldNameArr.length; i++) {
          const isValid =
            (!validateInfo.errors[fieldNameArr[i]] ||
              validateInfo.errors[fieldNameArr[i]].length <= 0) &&
            validateInfo.fields[fieldNameArr[i]].valid;
          if (!isValid) {
            return isValid;
          }
        }
        return true;
      }

      if (!areFieldsValid()) {
        this.focusOnInvalid && this.focusInvalidInput();
        return areFieldsValid();
      }

      const payload = {
        values: fieldNameArr.reduce((acc, curr) => {
          acc[curr] = this.values[curr];
          return acc;
        }, {}),
        resolve: this.resolve,
        setErrors: this.setErrors,
        setFieldErrors: this.setFieldErrors,
      };

      this.submitting = hasListener(this, "submitField");
      this.$emit("submitField", payload, event, callback);
    },

    async resolve() {
      return new Promise((resolve) => {
        this.errors = [];
        this.submitting = false;
        this.$refs.observer.reset();
        setTimeout(resolve, 50); // -> small delay to offset debouncing of onChange method in ValidationObserver
      });
    },
    validate() {
      return this.$refs.observer.validate();
    },
    reset() {
      this.errors = [];
      this.$refs.observer.reset();
      this.$emit("reset");
    },
    setErrors(errors) {
      this.submitting = false;
      this.errors = errors;
    },
    setFieldErrors(errors) {
      this.submitting = false;
      this.$refs.observer.setErrors(errors);
    },
    async focusInvalidInput() {
      const invalidInput = this.invalidInputs[0];

      if (!invalidInput) {
        return;
      }

      return await this.$vuetify
        .goTo(invalidInput, this.scrollOptions)
        .then(() => invalidInput.focus && invalidInput.focus());
    },
  },
  render(h) {
    return h(
      ValidationObserver,
      { ref: "observer", props: { ...this.observer, slim: true } },
      [
        (observerSlotProps) =>
          h(
            "form",
            {
              class: this.classes,
              attrs: { novalidate: true, ...this.$attrs },
              on: {
                submit: (e) => {
                  e.preventDefault();
                  this.submit(e);
                },
              },
            },
            [
              this.hasErrors &&
                h("div", { class: "vx-form__errors" }, [
                  this.errors.map((error) =>
                    h(VxAlert, { props: { type: "error" } }, error)
                  ),
                ]),
              getSlot(this, "default", {
                ...observerSlotProps,
                ...this.defaultSlotProps,
              }),
            ]
          ),
      ]
    );
  },
};
</script>

<style lang="scss">
.vx-form--outlined .v-input .v-label--active {
  font-size: 18px;
  height: 22px;
}

.vx-form__errors {
  padding: 16px 0px;
}
</style>
