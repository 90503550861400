<script>
import { getSlot } from "../../../utils";
import { VBtn, VIcon } from "vuetify/lib";

export default {
  name: "RaiLink",
  inheritAttrs: false,
  props: {
    to: {
      type: [String, Object],
      default: "",
    },
    href: {
      type: [String, Object],
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    target: {
      type: String,
      default: "_self",
      validator(value) {
        return ["_blank", "_self", "_parent", "_top"].indexOf(value) !== -1;
      },
    },
    block: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "text-body-1": true,
        "v-btn--link": !this.isExternal,
        "v-btn--external-link": this.isExternal,
      };
    },
    props() {
      return {
        ...this.$props,
        target: this.isExternal ? "_blank" : this.target,
        plain: true,
        ripple: false,
      };
    },
    isExternal() {
      return this.external || this.target === "_blank";
    },
  },
  render(h) {
    return h(
      VBtn,
      {
        class: this.classes,
        props: this.props,
        on: this.$listeners,
      },
      [
        this.isExternal &&
          h(VIcon, { class: "ml-0", props: { left: true } }, "$openInNew"),
        getSlot(this),
      ].filter(Boolean)
    );
  },
};
</script>

<style lang="scss">
a.v-btn.v-btn--link,
a.v-btn.v-btn--external-link {
  text-indent: 0;
  text-transform: none;

  &:not(.v-btn--block) {
    height: auto !important;
    min-width: 0 !important;
    padding-left: 0px;
    padding-right: 0px;
    vertical-align: baseline;
  }

  .v-btn__content {
    opacity: 1 !important;
  }
}

a.v-btn.v-btn--loading {
  .v-btn__content {
    opacity: 0 !important;
  }
}

a.v-btn.v-btn--external-link:not(.v-btn--block) {
  vertical-align: middle !important;
  margin-bottom: 2.5px;
}
</style>
