<script>
import { props } from "./VxDataListGroupBase";
import { VListItemGroup, VSubheader } from "vuetify/lib";

export default {
  name: "VxDataListGroupMobile",
  components: {
    VListItemGroup,
    VSubheader,
  },
  props,
};
</script>

<template>
  <v-list-item-group>
    <v-subheader
      data-testid="group-title"
      class="group-title-mobile text-overline font-weight-medium px-2 py-1"
      v-text="groupTitle"
    />
    <slot></slot>
  </v-list-item-group>
</template>
