import { camelCase } from "lodash";

/**
 * Generates random number
 * @returns {Number}
 */
export function genRandom() {
  return Math.floor(Math.random() * Date.now());
}

/**
 * Convert string or number value to valid CSS style value
 * @param {String|Number|null|undefined} value - to be converted
 * @param {String} [unit=px] - unit to convert to (eg. px, rem, em)
 * @returns {String|undefined}
 */
export function convertToUnit(value, unit = "px") {
  if (isNullOrEmpty(value)) {
    return;
  }

  return isNaN(+value) ? String(value) : `${Number(value)}${unit}`;
}

/**
 * Checks if given value is null, undefined or empty string
 * @param {*} value - to be checked for validity
 * @returns {Boolean}
 */
export function isNullOrEmpty(value) {
  return value === null || value === undefined || value === "";
}

/**
 * Resolves value when certain condition is met
 * @param {Boolean} condition - The condition to check
 * @param {*} value - The value to return if condition is met
 * @param {*} falseValue - The false value to return if condition is not met
 * @returns {*} value if condition is met and value is not falsy
 */
export function when(condition, value, falseValue = "") {
  return condition === true && value ? value : falseValue;
}

/**
 * Transforms text value to PascalCase
 * @param {String} value - text value to transform
 * @returns value in PascalCase
 */
export function pascalCase(value) {
  return value[0].toUpperCase() + camelCase(value.slice(1, value.length));
}

/**
 * Check if property exists on given object
 * @param {String} name - property name
 * @param {Object} object - object to check for property existence
 * @returns if property exists
 */
export function hasProperty(name, object) {
  return name in object;
}

/**
 * Validates current prop value with enum
 * @param {Object} values - enum values to validate value against
 * @returns {Boolean} is given prop value valid enum value
 * @example
 * { ENUM_KEY_1: "value", ENUM_KEY_2: "value" }
 */
export function enumValidator(values) {
  return (value) => Object.values(values).includes(value);
}

/**
 * Get existing slot from Vue component instance
 * @param {import("vue").default} vm - Vue component
 * @param {String} name - slot name (if not provided it will take default slot)
 * @param {Object|Function} data - slot scoped data
 * @param {Boolean} optional
 * @returns normal or scoped slot for Vue component, otherwise undefined
 */
export function getSlot(vm, name = "default", data = {}, optional = false) {
  if (hasProperty(name, vm.$scopedSlots)) {
    return vm.$scopedSlots[name](data instanceof Function ? data() : data);
  } else if (hasProperty(name, vm.$slots) && (!data || optional)) {
    return vm.$slots[name];
  }

  return undefined;
}

/**
 * Check if given slot exists on Vue component instance
 * @param {import("vue").default} vm - Vue component
 * @param {String} name - slot name
 * @returns true if slot exists, false if not.
 */
export function hasSlot(vm, name = "default") {
  return hasProperty(name, vm.$scopedSlots) || hasProperty(name, vm.$slots);
}

/**
   * Generate slot propagation for Vue component instance
   * @param {import("vue").default} vm - Vue component
   * @param {Array<string>} slots - array of slot names to generate and propagate
   * @returns generated VNode slot elements
   *
   * @example
   // -> showing what one generated slot would look like written as HTML
   * <template slot="slotName">
   *  <slot name="slotName"></slot>
   * </template>
   */
export function genSlots(vm, slots) {
  return slots.map((slot) =>
    vm.$createElement("template", { slot }, getSlot(vm, slot))
  );
}

/**
 * Check if given listener exists on Vue component instance
 * @param {import("vue").default} vm - Vue component
 * @param {String} name - listener name
 * @returns true if listener exists, false if not.
 */
export function hasListener(vm, name) {
  return hasProperty(name, vm.$listeners);
}

/**
 * Check if given prop is defined on Vue component instance
 * @param {import("vue").default} vm - Vue component
 * @param {String} name - prop name
 * @returns true if prop is defined, false if not.
 */
export function hasDefinedProp(vm, name) {
  return hasProperty(name, vm.$options.propsData);
}
