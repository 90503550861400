<script>
import { props, methods } from "./VxDataListItemBase";

export default {
  name: "VxDataListItemDesktop",
  inheritAttrs: false,
  props,
  methods,
};
</script>

<template>
  <tr
    v-bind="$attrs"
    :class="{ disabled: disabled }"
    v-on="$listeners"
    @click="showItem"
  >
    <slot name="desktop"></slot>
  </tr>
</template>

<style scoped lang="scss">
tr {
  cursor: pointer;

  &.empty-loading-data-row {
    cursor: unset;
  }

  &.disabled {
    cursor: unset;
    color: var(--v-ui-lighten1);
  }

  &.disabled * {
    color: var(--v-ui-lighten1);
  }
}
</style>
