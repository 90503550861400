import { render, staticRenderFns } from "./VxDataListItemMobile.vue?vue&type=template&id=5210c306&scoped=true&"
import script from "./VxDataListItemMobile.vue?vue&type=script&lang=js&"
export * from "./VxDataListItemMobile.vue?vue&type=script&lang=js&"
import style0 from "./VxDataListItemMobile.vue?vue&type=style&index=0&id=5210c306&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5210c306",
  null
  
)

export default component.exports