import Pusher from "pusher-js";
import { authHeaders } from "./utils/http";
import EventBus from "./utils/events";

const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  encrypted: true,
  authEndpoint: `${process.env.VUE_APP_RAI_DOMAIN}/pusher/auth`,
  auth: {
    headers: {
      ...authHeaders(),
    },
  },
  disableStats: true,
});

pusher.connection.bind("state_change", (states) => {
  // states = { previous: "oldState", current: "newState" }

  EventBus.$emit(`ws:connection:state_change`, states);
});

export default pusher;
