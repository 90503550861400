import { buildRoute } from "@/utils/router";
import i18n from "@/plugins/vue-i18n";

import { buysSetupRoute, buysSetupCommonRouteProps } from "./store/buys-routes";
import {
  daybookSetupRoute,
  daybookSetupCommonRouteProps,
} from "./store/daybook-routes";
import {
  bouncebackSetupRoute,
  bouncebackSetupCommonRouteProps,
} from "./store/bounceback-routes";
import {
  textingSetupRoute,
  textingSetupCommonRouteProps,
} from "./store/texting-routes";
import {
  backstockSetupRoute,
  backstockSetupCommonRouteProps,
} from "./store/backstock-routes";

export default [
  {
    path: "",
    redirect: "welcome",
  },
  {
    path: "welcome",
    name: "onboarding.welcome",
    component: () => import("@/views/Onboarding/WelcomeView.vue"),
    props: { nextTo: "onboarding.store.intro" },
  },
  {
    path: "store/intro",
    name: "onboarding.store.intro",
    component: () => import("@/views/Onboarding/StoreIntroView.vue"),
    props: { nextTo: "onboarding.store.setup" },
  },
  {
    path: "store/setup",
    name: "onboarding.store.setup",
    component: () => import("@/views/Onboarding/StoreSetupView.vue"),
    props: { nextTo: "onboarding.team.intro" },
  },
  {
    path: "team/intro",
    name: "onboarding.team.intro",
    component: () => import("@/views/Onboarding/TeamIntroView.vue"),
    props: { nextTo: "onboarding.team.setup" },
  },
  {
    path: "team/when-i-work",
    name: "onboarding.team.whenIWork",
    component: () => import("@/views/Onboarding/WhenIWorkSetupView.vue"),
    props: { nextTo: "onboarding.teamAccess.intro" },
  },
  {
    path: "team/homebase",
    name: "onboarding.team.homebase",
    component: () => import("@/views/Onboarding/HomebaseSetupView.vue"),
    props: { nextTo: "onboarding.teamAccess.intro" },
  },
  {
    path: "team/setup",
    name: "onboarding.team.setup",
    component: () => import("@/views/Onboarding/TeamSetupView.vue"),
    props: { nextTo: "onboarding.teamAccess.intro" },
  },
  {
    path: "team-access/intro",
    name: "onboarding.teamAccess.intro",
    component: () => import("@/views/Onboarding/TeamAccessIntroView.vue"),
    props: { nextTo: "onboarding.teamAccess.setup" },
  },
  {
    path: "team-access/setup",
    name: "onboarding.teamAccess.setup",
    component: () => import("@/views/Onboarding/TeamAccessSetupView.vue"),
    props: { nextTo: "onboarding.nightlyReports.intro" },
  },
  {
    path: "nightly-reports/intro",
    name: "onboarding.nightlyReports.intro",
    component: () => import("@/views/Onboarding/NightlyReportsIntroView.vue"),
    props: {
      nextTo: "onboarding.nightlyReports.setup",
      skipTo: "onboarding.install.intro",
      showSkip: true,
    },
  },
  {
    path: "nightly-reports/setup",
    name: "onboarding.nightlyReports.setup",
    component: () => import("@/views/Onboarding/NightlyReportsSetupView.vue"),
    props: { nextTo: "onboarding.install.intro" },
  },
  {
    path: "install/intro",
    name: "onboarding.install.intro",
    component: () => import("@/views/Onboarding/InstallIntroView.vue"),
    props: { nextTo: "onboarding.magicGoals.intro" },
  },
  {
    path: "magic-goals/intro",
    name: "onboarding.magicGoals.intro",
    component: () => import("@/views/Onboarding/MagicGoalsIntroView.vue"),
    props: {
      nextTo: "onboarding.magicGoals.setup",
      skipTo: "onboarding.daybook.intro",
      showSkip: true,
    },
  },
  {
    path: "magic-goals/setup",
    name: "onboarding.magicGoals.setup",
    component: () => import("@/views/Onboarding/MagicGoalsSetupView.vue"),
    props: { nextTo: "onboarding.daybook.intro" },
  },
  {
    path: "daybook/intro",
    name: "onboarding.daybook.intro",
    component: () => import("@/views/Onboarding/DaybookIntroView.vue"),
    props: {
      nextTo: "onboarding.daybook.setup",
      skipTo: "onboarding.textMessage.intro",
      showSkip: true,
    },
  },
  ...buildRoute(
    "daybook/setup",
    {
      component: () => import("@/views/Onboarding/RouterSetupView.vue"),
      props: (route) => ({
        nextTo: "onboarding.textMessage.intro",
        nextText: i18n.t("onboarding.daybookSetupView.nextText"),
      }),
      children: [daybookSetupRoute],
    },
    (route) => {
      return {
        hideStoreSettingsAppBar: true,
        showNext: route.name === "onboarding.daybook.setup",
        ...daybookSetupCommonRouteProps(route),
      };
    },
    { prefix: "onboarding" }
  ),
  {
    path: "text-message/intro",
    name: "onboarding.textMessage.intro",
    component: () => import("@/views/Onboarding/TextMessageIntroView.vue"),
    props: {
      nextTo: "onboarding.texting.setup",
      skipTo: "onboarding.buys.intro",
      showSkip: true,
    },
  },
  ...buildRoute(
    "text-message/setup",
    {
      component: () => import("@/views/Onboarding/RouterSetupView.vue"),
      props: (route) => ({
        nextTo: "onboarding.buys.intro",
        nextText: i18n.t("onboarding.textMessageSetupView.nextText"),
      }),
      children: [textingSetupRoute],
    },
    (route) => {
      return {
        hideStoreSettingsAppBar: true,
        showNext: route.name === "onboarding.texting.setup",
        ...textingSetupCommonRouteProps(route),
      };
    },
    { prefix: "onboarding" }
  ),
  {
    path: "buys/intro",
    name: "onboarding.buys.intro",
    component: () => import("@/views/Onboarding/BuysIntroView.vue"),
    props: {
      nextTo: "onboarding.buy.setup",
      skipTo: "onboarding.backstock.intro",
      showSkip: true,
    },
  },
  ...buildRoute(
    "buys/setup",
    {
      component: () => import("@/views/Onboarding/RouterSetupView.vue"),
      props: (route) => ({
        nextTo: "onboarding.backstock.intro",
        nextText: i18n.t("onboarding.buysSetupView.nextText"),
      }),
      children: [buysSetupRoute],
    },
    (route) => {
      return {
        hideStoreSettingsAppBar: true,
        showNext: route.name === "onboarding.buy.setup",
        ...buysSetupCommonRouteProps(route),
      };
    },
    { prefix: "onboarding" }
  ),
  {
    path: "backstock/intro",
    name: "onboarding.backstock.intro",
    component: () => import("@/views/Onboarding/BackstockIntroView.vue"),
    props: {
      nextTo: "onboarding.backstock.setup",
      skipTo: "onboarding.automations.intro",
      showSkip: true,
    },
  },
  ...buildRoute(
    "backstock/setup",
    {
      component: () => import("@/views/Onboarding/RouterSetupView.vue"),
      props: (route) => ({
        nextTo: "onboarding.automations.intro",
        nextText: i18n.t("onboarding.backstockSetupView.nextText"),
      }),
      children: [backstockSetupRoute],
    },
    (route) => {
      return {
        hideStoreSettingsAppBar: true,
        showNext: route.name === "onboarding.backstock.setup",
        ...backstockSetupCommonRouteProps(route),
      };
    },
    { prefix: "onboarding" }
  ),
  {
    path: "automations/intro",
    name: "onboarding.automations.intro",
    component: () => import("@/views/Onboarding/AutomationsIntroView.vue"),
    props: { nextTo: "onboarding.automations.constantContact.intro" },
  },
  {
    path: "automations/constant-contact/intro",
    name: "onboarding.automations.constantContact.intro",
    component: () => import("@/views/Onboarding/ConstantContactIntroView.vue"),
    props: { nextTo: "onboarding.automations.quickbooks.intro" },
  },
  {
    path: "automations/constant-contact/setup",
    name: "onboarding.automations.constantContact.setup",
    component: () => import("@/views/Onboarding/ConstantContactSetupView.vue"),
    props: { nextTo: "onboarding.automations.quickbooks.intro" },
  },
  {
    path: "automations/quickbooks/intro",
    name: "onboarding.automations.quickbooks.intro",
    component: () => import("@/views/Onboarding/QuickbooksIntroView.vue"),
    props: { nextTo: "onboarding.automations.fivestars.intro" },
  },
  {
    path: "automations/quickbooks/setup",
    name: "onboarding.automations.quickbooks.setup",
    component: () => import("@/views/Onboarding/QuickbooksSetupView.vue"),
    props: { nextTo: "onboarding.automations.fivestars.intro" },
  },
  {
    path: "automations/fivestars/intro",
    name: "onboarding.automations.fivestars.intro",
    component: () => import("@/views/Onboarding/FivestarsIntroView.vue"),
    props: { nextTo: "onboarding.bounceback.intro" },
  },
  {
    path: "automations/fivestars/setup",
    name: "onboarding.automations.fivestars.setup",
    component: () => import("@/views/Onboarding/FivestarsSetupView.vue"),
    props: { nextTo: "onboarding.bounceback.intro" },
  },
  {
    path: "bounceback/intro",
    name: "onboarding.bounceback.intro",
    component: () => import("@/views/Onboarding/BouncebackIntroView.vue"),
    props: { nextTo: "onboarding.billing.intro" },
  },
  ...buildRoute(
    "bounceback/setup",
    {
      component: () => import("@/views/Onboarding/RouterSetupView.vue"),
      props: (route) => ({
        nextTo: "onboarding.billing.intro",
        nextText: i18n.t("onboarding.bouncebackSetupView.nextText"),
      }),
      children: [bouncebackSetupRoute],
    },
    (route) => {
      return {
        hideStoreSettingsAppBar: true,
        showNext: route.name === "onboarding.bounceback.setup",
        ...bouncebackSetupCommonRouteProps(route),
      };
    },
    { prefix: "onboarding" }
  ),
  {
    path: "billing/intro",
    name: "onboarding.billing.intro",
    component: () => import("@/views/Onboarding/BillingIntroView.vue"),
    props: { nextTo: "daybook" },
  },
];
