/**
 * Find matched meta field on current route
 * @param {import("vue").default} vm - Vue instance
 * @param {String} fieldName - field to be searched for on matched routes
 * @returns
 */
function findMetaField(vm, fieldName) {
  return vm.$route.matched.find((x) => x.meta[fieldName])?.meta[fieldName];
}

export { findMetaField };
export default findMetaField;
