<script>
import { VIcon } from "vuetify/lib";
import { SORT_ORDER } from "./SortOrder";
import { props } from "./VxDataListHeaderBase";

export default {
  name: "VxDataListHeaderDesktop",
  components: { VIcon },
  props,
  data() {
    return {
      SORT_ORDER,
    };
  },

  methods: {
    headerClick(header) {
      if (header.sortable) {
        this.$emit("update:sort", header);
      }
    },
  },
};
</script>

<template>
  <thead class="v-data-table-header">
    <tr class="vx-data-list-headers">
      <!-- TODO: refactor this to seperate component because it has custom logic -->
      <th
        v-for="header in headers"
        :key="header.propertyPath"
        :class="{
          sortable: header.sortable,
          active: sortOptions && header.propertyPath === sortOptions.sortBy,
          'text-right': header.align === 'right',
          [header.cssClass]: true,
        }"
        @click.stop="headerClick(header)"
      >
        <span class="header-label">
          {{ header.label }}
        </span>
        <v-icon
          v-if="sortOptions && header.propertyPath === sortOptions.sortBy"
          data-testid="sortIcon"
          :class="{
            'sort-asc': sortOptions.sortOrder === SORT_ORDER.ASC,
            'sort-desc': sortOptions.sortOrder === SORT_ORDER.DESC,
            'v-data-table-header__icon': true,
          }"
          small
        >
          $sort
        </v-icon>
      </th>
    </tr>
  </thead>
</template>

<style scoped lang="scss">
.fake-layout-headers {
  visibility: collapse;
}

th {
  padding-top: 0;
  padding-bottom: 0;

  .header-label {
    // TODO - seee if this style can be picked from veutify
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

th.sortable {
  cursor: unset;

  .v-data-table-header__icon,
  .header-label {
    cursor: pointer;
  }
}

.v-data-table-header th.active .v-data-table-header__icon.sort-desc {
  transform: rotate(180deg);
}
</style>
