<script>
import { enumValidator } from "../../../utils";
import { SetupType, SetupTypeConfig } from "./constants";
import { VSkeletonLoader, VFadeTransition, VIcon } from "vuetify/lib";

export default {
  name: "RaiSetupHeaderIcon",
  props: {
    type: {
      type: String,
      default: SetupType.PENDING,
      validator: enumValidator(SetupType),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setupType() {
      return SetupTypeConfig[this.type];
    },
    classes() {
      return {
        "rai-setup-header__icon": true,
        "rai-setup-header__icon--loading": this.loading,
      };
    },
  },
  render(h) {
    const { icon, color } = this.setupType;

    return h("div", { class: this.classes }, [
      h(VFadeTransition, { props: { mode: "out-in" } }, [
        h(
          VSkeletonLoader,
          {
            props: {
              type: "avatar",
              width: "24px",
              height: "24px",
              loading: this.loading,
            },
          },
          [
            h(
              VIcon,
              {
                props: { color: color },
                attrs: { "data-testid": "setup-type" },
              },
              icon
            ),
          ]
        ),
      ]),
    ]);
  },
};
</script>

<style lang="scss">
.rai-setup-header__icon {
  flex-grow: 0 !important;
  margin-right: 1.5rem;

  &--loading .v-skeleton-loader__avatar {
    height: 100% !important;
    width: 100% !important;
  }
}
</style>
