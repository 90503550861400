/**
 * @param {number} value - duration in seconds to format
 * @returns formatted duration string (> 1 hour will be formatted as <hour>hr, <minutes>min, < 1 hour will be formatted as <minutes>min)
 */
function formatDuration(value) {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);

  if (hours > 0) {
    return `${hours} hr, ${minutes} min`;
  }

  return `${minutes} min`;
}

export { formatDuration };
