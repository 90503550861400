import { snakeCase } from "lodash";
import store from "@/store";

export const objectToStringParams = (params) => {
  return Object.keys(params)
    .map((key) => snakeCase(key) + "=" + params[key])
    .join("&");
};

// Attempt to get auth info from Vuex
const vuexAuth = () => {
  return store && store.getters && store.getters["auth/getAuth"];
};
// Attempt to get auth info from localStorage
// DEPRECATED
const localStorageAuth = () => {
  const storage = JSON.parse(localStorage.getItem("rai"));
  return storage && storage.auth;
};
// localStorageAuth || vuexAuth || invalidAuth
const getAuth = () => {
  return (
    vuexAuth() || {
      email: "no-auth-email-available",
      token: "no-auth-token-available",
    }
  );
};
const getStoreIdFromVuex = () => {
  return store && store.getters && store.getters["auth/activeStoreId"];
};

// These headers do not change during the lifetime of the app
// When user auth changes, the apollo object is re-instantiated
// Currently this happens via multi-entry
// In the future this should happen via nested routes
const stableHeaders = {
  "X-Rai-AppVersion": process.env.VUE_APP_VERSION,
};

const storeHeaders = () => {
  return {
    "X-User-Store": getStoreIdFromVuex(),
  };
};

// Return an object of auth headers
export const authHeaders = () => {
  return {
    ...store.getters["auth/authHeaders"],
    ...storeHeaders(),
    ...stableHeaders,
  };
};
