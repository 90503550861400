import { renderConfirmDialog } from "./helpers";

/**
 * @typedef {Object} ConfirmDialogOptions
 * @property {String|JSX.Element} message - message to show
 * @property {String} [title] - dialog title
 * @property {String} [okText] - button OK text
 * @property {String} [okColor] - button OK color
 * @property {String} [okDisabled] - button OK is disabled
 * @property {String} [cancelText] - button CANCEL text
 * @property {String} [cancelColor] - button CANCEL color
 * @property {String} [attach] - where to attach dialog in DOM
 */

/**
 * Renders confirmation dialog in DOM on demand
 * @param {import("vue").VueConstructor} Vue - Vue constructor
 * @param {{ vuetify: import("vuetify/lib").default }} args - arguments provided by plugin
 * @param {ConfirmDialogOptions} options - for rendering dialog
 * @returns {Promise<boolean>} a promise to be resolved by dialog
 */
function confirm(Vue, args, options) {
  const {
    title,
    message,
    okText,
    okColor,
    okDisabled,
    cancelText,
    cancelColor,
    attach,
  } = options;

  return renderConfirmDialog(Vue, args, {
    title,
    message,
    okText,
    okColor,
    okDisabled,
    cancelText,
    cancelColor,
    attach,
  });
}

export { confirm };
export default confirm;
