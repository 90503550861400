export const TooltipType = {
  INFO: "info",
  WARN: "warn",
  ERROR: "error",
};

export const TooltipTypeConfig = {
  info: {
    icon: "$info",
    color: "info",
  },
  warn: {
    icon: "$alert",
    color: "warning",
  },
  error: {
    icon: "$error",
    color: "error",
  },
};
