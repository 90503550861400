class RaiAudio {
  /**
   * Global audio player for ResaleAI
   *
   * Pass a list of sound objects, and then use a global instance to play sounds by name.
   *
   * @example
   * const sounds = [
   *   { name: "beep", url: "/sounds/beep.wav" },
   *   { name: "honk", url: "/sounds/honk.wav" }
   * ]
   *
   * Vue.prototype.$sounds = new RaiAudio(sounds, raiPos);
   *
   * // in a component
   * this.$sounds.play("beep");
   *
   * @constructor
   * @param {Object[]} sounds
   * @param {string} sounds[].name - the name used to reference this sound
   * @param {string} sounds[].url - the asset URL for this sound
   * @param {Object} [raiPos] - the raiPos instance
   *
   * TODO: add a audio module to vuex for persistent preferences on which sounds to play or not play
   */
  constructor(sounds, raiPos) {
    this.raiPos = raiPos || false;
    if (sounds.length === 0) {
      console.warn("No sounds loaded");
    }
    this.sounds = {};

    // setup the soundmap
    for (const key in sounds) {
      this.sounds[sounds[key].name] = {
        url: sounds[key].url,
        loaded: false,
        player: null,
        playCount: 0,
      };
      if (!!sounds[key].preload) {
        this._load(sounds[key].name, sounds[key].url);
      }
    }
  }

  _sound(name, url) {
    if (!this.sounds[name]) {
      this.sounds[name] = {
        url: url,
        loaded: false,
        player: null,
        playCount: 0,
      };
    }
    // set just the URL if it is missing
    else if (!this.sounds[name].url) {
      this.sounds[name].url = url;
    }
    return this.sounds[name];
  }

  /**
   * Loads a sound so that it is ready to play
   * @param {string} name - name of the sound to load
   */
  async _load(name, url) {
    let sound = this._sound(name, url);

    // if the sound is already loaded, return
    if (sound && sound.loaded && !!sound.player) {
      return true;
    }

    // if raiPos can play it, let it
    if (
      this.raiPos &&
      this.raiPos.raiBeep &&
      this.raiPos.raiBeep.load(name, url)
    ) {
      this.sounds[name].player = () => {
        this.raiPos.raiBeep.play(name);
      };
      this.sounds[name].loaded = true;
      return true;
    }

    // create an Audio instance
    this.sounds[name].player = new Audio(url);
    this.sounds[name].loaded = true;
    return true;
  }

  /**
   * Play the selected audio file
   * @param {string} name
   * @param {string} [url] - optional URL if the sound was not pre-defined
   */
  async play(name, url) {
    return this._load(name, url).then(() => {
      this._sound(name).player.play();
      this.sounds[name].playCount += 1;
      return true;
    });
  }
}

export default RaiAudio;
