// TODO: extract description text to i18n
const textingRoute = {
  components: {
    default: () => import("@/views/Texting/AppView"),
    appbar: () => import("@/views/Texting/AppView/components/TextingAppBar"),
  },
  props: {
    default: (route) => ({
      filter: route.query.filter,
    }),
    appbar: (route) => ({
      filter: route.query.filter,
    }),
  },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "new",
      name: "texting.new",
      components: {
        default: () => import("@/views/Texting/AppView/NewThreadView"),
      },
    },
    {
      path: ":threadId/show",
      name: "texting.thread",
      components: {
        default: () => import("@/views/Texting/AppView/ShowThreadView"),
      },
    },
    {
      path: "",
      name: "texting_home",
      components: {
        default: () => import("@/views/Texting/AppView/NoThreadView"),
      },
    },
  ],
};

const textingSetupRoute = {
  path: "",
  meta: {
    requiresAuth: true,
    requiresManager: true,
  },
  components: {
    default: () => import("@/views/StoreSettingsView"),
  },
  children: [
    {
      name: "texting.setup",
      path: "",
      component: () => import("@/views/Texting/SetupView"),
      meta: {
        toolbarTitle: "Texting",
        title: "Prewritten text messages",
        description: "Create and manage templates to send common messages",
      },
    },
    {
      name: "texting.setup.template.edit",
      path: "template/:messageId",
      component: () => import("@/views/Texting/SetupView/EditMessageView"),
      meta: {
        title: "Edit message template",
        toolbarTitle: "Set up prewritten text messages",
        description:
          "For best results, use simple, natural-sounding language. Avoid all-caps, or overly formal or aggressive language that may get marked as spam.",
      },
    },
    {
      name: "texting.setup.template.new",
      path: "template",
      component: () => import("@/views/Texting/SetupView/NewMessageView"),
      meta: {
        title: "New message template",
        toolbarTitle: "Set up prewritten text messages",
        description:
          "For best results, use simple, natural-sounding language. Avoid all-caps, or overly formal or aggressive language that may get marked as spam.",
      },
    },
    {
      name: "texting.setup.autoresponse.edit",
      path: "auto-response/:messageId",
      component: () => import("@/views/Texting/SetupView/EditMessageView"),
      props: () => ({
        autoReply: true,
      }),
      meta: {
        title: "Edit auto-reply message",
        toolbarTitle: "Set up prewritten text messages",
        description: [
          `Set up automated responses to answer common questions or give more details.
            Customers text a shortcode, such as "INFO" or “SELL”, and it will send an automatic response.`,
          `
              Once you create a shortcode, be sure to communicate it to customers.
              Consider adding a prompt in one of your other messages, such as "Text INFO for more."
              or create in-store signage to say "Text SELL to 123-456-7890" with your texting number.
              `,
        ],
      },
    },
    {
      name: "texting.setup.autoresponse.new",
      path: "auto-response",
      component: () => import("@/views/Texting/SetupView/NewMessageView"),
      props: () => ({
        autoReply: true,
      }),
      meta: {
        title: "New auto-reply message",
        toolbarTitle: "Set up prewritten text messages",
        description: [
          `Set up automated responses to answer common questions or give more details.
            Customers text a shortcode, such as "INFO" or “SELL”, and it will send an automatic response.`,
          `
              Once you create a shortcode, be sure to communicate it to customers.
              Consider adding a prompt in one of your other messages, such as "Text INFO for more."
              or create in-store signage to say "Text SELL to 123-456-7890" with your texting number.
              `,
        ],
      },
    },
  ],
};

const textingSetupCommonRouteProps = (route) => {
  return {
    raiLinkTo: {
      onTemplateAdd: "texting.setup.template.new",
      onTemplateEdit: "texting.setup.template.edit",
      onAutoResponseAdd: "texting.setup.autoresponse.new",
      onAutoResponseEdit: "texting.setup.autoresponse.edit",
    },
  };
};

export { textingRoute, textingSetupRoute, textingSetupCommonRouteProps };
