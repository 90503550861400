const bouncebackRoute = {
  // This route has no name, as when using nested routes (with a default nested route)
  // it's better to use directly default child route
  meta: {
    requiresAuth: true,
  },
  components: {
    default: () => import("@/views/Bounceback/AppView"),
    appbar: () => import("@/views/Bounceback/AppView/BouncebackAppBar"),
    dialog: () => import("@/views/Common/RouterDefaultViewWrapper"),
    bardcodeScan: () => import("@/views/Bounceback/AppView/BouncebackScan"),
  },
  children: [
    {
      name: "bounceback_home",
      path: "",
      component: () => import("@/views/Common/NewDetailButton"),
      props: (route) => ({
        routeName: "bounceback_new",
      }),
    },
    {
      name: "bounceback_new",
      path: "detail/new",
      component: () => import("@/views/Bounceback/AppView/BouncebackNewDialog"),
    },
    {
      // TODO - id -> bouncebackId or couponId
      name: "bounceback_show",
      path: "detail/show/:id",
      component: () =>
        import("@/views/Bounceback/AppView/BouncebackShowDialog"),
    },
    {
      // TODO - id -> bouncebackId or couponId
      name: "bounceback_edit",
      path: "detail/edit/:id",
      component: () =>
        import("@/views/Bounceback/AppView/BouncebackEditDialog"),
    },
  ],
};

const bouncebackSetupRoute = {
  path: "",
  meta: {
    requiresAuth: true,
    requiresManager: true,
  },
  components: {
    default: () => import("@/views/StoreSettingsView"),
  },
  children: [
    {
      path: "",
      name: "bounceback.setup",
      component: () => import("@/views/Bounceback/SetupView"),
      meta: {
        toolbarTitle: "Bounceback campaigns",
        title: "Bounceback campaigns",
        description:
          "Create and manage campaigns to print and redeem bounceback coupons",
      },
    },
    {
      name: "bounceback.setup.campaign.edit",
      path: "campaigns/:campaignId",
      component: () => import("@/views/Bounceback/SetupView/EditCampaignView"),
      meta: {
        title: "Edit campaign",
        toolbarTitle: "Bounceback campaigns",
        description: [
          `Set up your campaign details, including the dates when customers can earn 
          and redeem coupons, and the value of the coupon they will receive.`,
          `The goal of a bounceback campaign is to increase your average
          transaction during a busy time, and bring back customers to shop later
          during a time. Consider setting the earning amount higher than your
          typical average transaction, to encourage customers to spend more to
          earn the coupon.`,
          `You can set up a campaign that earns coupons on just one day, like the
          day of an event, or for a longer period of time. We recommend that you
          let customers use the coupons fairly soon after the earning period ends
          (within the next two weeks).`,
        ],
      },
    },
    {
      name: "bounceback.setup.campaign.new",
      path: "campaigns",
      component: () => import("@/views/Bounceback/SetupView/NewCampaignView"),
      meta: {
        title: "New campaign",
        toolbarTitle: "Bounceback campaigns",
        description: [
          `Set up your campaign details, including the dates when customers can earn 
            and redeem coupons, and the value of the coupon they will receive.`,
          `The goal of a bounceback campaign is to increase your average
            transaction during a busy time, and bring back customers to shop later
            during a time. Consider setting the earning amount higher than your
            typical average transaction, to encourage customers to spend more to
            earn the coupon.`,
          `You can set up a campaign that earns coupons on just one day, like the
            day of an event, or for a longer period of time. We recommend that you
            let customers use the coupons fairly soon after the earning period ends
            (within the next two weeks).`,
        ],
      },
    },
  ],
};

const bouncebackSetupCommonRouteProps = (route) => {
  return {
    campaignId: route.params.campaignId,
    raiLinkTo: {
      addNewCampaign: "bounceback.setup.campaign.new",
      editCampaign: "bounceback.setup.campaign.edit",
    },
  };
};

export {
  bouncebackRoute,
  bouncebackSetupRoute,
  bouncebackSetupCommonRouteProps,
};
