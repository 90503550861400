import { CODES } from "./decode-id";

let prevTime = new Date();
let scanBuffer = "";
let scanType = null;
let maxInterval = 100;
export const MAX_CODE39_LENGTH = 256;
const specialKeys = [
  "Shift",
  "CapsLock",
  "Tab",
  "Backspace",
  "Control",
  "Alt",
  "Meta",
  "PageUp",
  "PageDown",
  "Enter",
  "Insert",
];
const codeRegexStr = CODES.join("|");
// find 0010 before a code (which seems to act as a delimeter)
const delimeterRemovalRegex = new RegExp(`0010(?=${codeRegexStr})`, "g");

/**
 * Resets the scanner to an initial state, with either nothing in the buffer or the initial character.
 * @param {String} initialChar The initial character to put in the buffer.
 */
function reset(initialChar = "") {
  prevTime = new Date();
  if (initialChar === "Enter" || initialChar === "Shift") {
    initialChar = "";
  }
  scanBuffer = initialChar;
  scanType = null;
}

/**
 * Creates a barcode scanner that listens for keypress events and emits events when a barcode is successfully scanned.
 * @param {Number} interval The maximum time between keypresses in milliseconds.
 */
function createBarcodeScanner(interval = 100) {
  maxInterval = interval;
  window.addEventListener("keydown", handleKeypress);
}

/**
 * Removes the event listener for the barcode scanner.
 */
export function teardownBarcodeScanner() {
  window.removeEventListener("keydown", handleKeypress);
}

/**
 * Emits an event when the barcode is successfully scanned.
 */
function emitOnBarcode() {
  // remove NumLocks, and delimeters between codes
  const buff = scanBuffer
    .replace(/NumLock/g, "")
    .replace(delimeterRemovalRegex, "");
  const event = new CustomEvent("onbarcode", {
    detail: {
      data: buff,
    },
  });
  window.dispatchEvent(event);
}

/**
 * Emits an event when the barcode fails to scan.
 */
function emitOnBarcodeFail() {
  const event = new Event("onbarcodefail");
  window.dispatchEvent(event);
}

/**
 * Handles the keypress event, adding the character to the buffer and emitting events as necessary.
 * @param {KeyboardEvent} e The keypress event.
 */
function handleKeypress(e) {
  const now = new Date();
  const maxTimeBetween = maxInterval * (scanType === "license" ? 2 : 1);

  // too much time has passed between keystrokes, reset and use this keystroke
  // as the first character of a new scan
  if (now - prevTime > maxTimeBetween) {
    reset();
  }

  if (scanBuffer.length === 0) {
    // detect '@' or '%' for licenses if first character in scan
    scanType = e.key === "@" || e.key === "%" ? "license" : "code39";
  } else if (scanBuffer.length === 1) {
    // detect '0' for licenses if second character in scan
    scanType =
      scanBuffer[0] === "@" || scanBuffer[0] === "%" ? "license" : "code39";
  } else if (scanType === "code39" && scanBuffer.length > MAX_CODE39_LENGTH) {
    // if the scan is too long, emit fail event, reset, and start over
    emitOnBarcodeFail();
    reset();
  }

  if (scanType === "license") {
    if (!specialKeys.includes(e.key)) {
      scanBuffer += e.key;
    }
  } else if (scanType === "code39") {
    // reset in the case that we are doing code39 scan but the character is invalid
    if (scanBuffer.length == 1 && !scanBuffer[0].match(/[A-Z0-9\-\.\$\+\% ]/)) {
      reset();
    }
    if (!specialKeys.includes(e.key) && e.key.match(/[A-Z0-9\-\.\$\+\% ]/)) {
      scanBuffer += e.key;
    } else if (e.key !== "Enter") {
      // invalid character, reset, and start over
      reset(e.key);
    }
  }

  // if enter is pressed, emit event
  if (e.key === "Enter" && scanBuffer.length > 0) {
    emitOnBarcode();
    reset();
  }

  prevTime = now;
}

export default createBarcodeScanner;
