<script>
import { VTooltip } from "vuetify/lib";
import { TooltipType } from "./constants";
import { enumValidator, genSlots } from "../../../utils";
import VxTooltipActivator from "./VxTooltipActivator.vue";

export default {
  name: "VxTooltip",
  extends: VTooltip,
  props: {
    type: {
      type: String,
      default: undefined,
      validator: enumValidator(TooltipType),
    },
  },
  render(h) {
    const scopedSlots = {
      activator: ({ on, attrs }) => {
        if (!this.type) return;
        return h(VxTooltipActivator, { on, attrs, props: { type: this.type } });
      },
      ...this.$scopedSlots,
    };

    return h(
      VTooltip,
      {
        props: this.$props,
        attrs: this.$attrs,
        scopedSlots: scopedSlots,
        on: this.$listeners,
      },
      genSlots(this, ["default"])
    );
  },
};
</script>
