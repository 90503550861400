<script>
export default {
  name: "VxFormGroup",
  functional: true,
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  render(h, { props, slots }) {
    const title = slots()?.title || props.title;
    return h("div", { class: "vx-form-group" }, [
      title &&
        h(
          "div",
          { class: "vx-form-group__title text-overline grey--text" },
          title
        ),
      h(
        "div",
        { class: "vx-form-group__content text--primary" },
        slots()?.default
      ),
    ]);
  },
};
</script>

<style lang="scss">
.vx-form-group__title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  padding: 16px 0px;
}
</style>
