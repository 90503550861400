export const EMPLOYEE_ACCESS_STATUS = {
  noEmployee: "noEmployee",
  requiredEmployeeAccess: "requiredEmployeeAccess",
  hasEmployee: "hasEmployee",
};

export const NO_EMPLOYEE_ID = false;

const state = () => ({
  // teamMode: false,
  // teamModeLock: false,
  // employeeId: false,
  // employeeToken: null,
  // employeeLastActivity: false,
  // employeeIsManager: false,
  // type: null,
  // lastEmployee: null,
  // lastRoute: null,
  // storeId: null,
  // // userStoreEmployees
  // // key is a store_id
  // // value is an employee object for the current user for that store
  // userStoreEmployees: {},
  // employeeAccessStatus: EMPLOYEE_ACCESS_STATUS.noEmployee,
});

const isAuthenticated = (state) => state.token;

const hasStoreEmployee = (state) =>
  state.userStoreEmployees && !!state.userStoreEmployees[state.storeId];

const getters = {
  hasStoreEmployee,
  isTeamMode: (state) =>
    isAuthenticated(state) && (state.teamMode || !state.employeeId),
  isTeamModeLocked: (state) =>
    state.employeeAccessStatus !== EMPLOYEE_ACCESS_STATUS.hasEmployee,
  activeEmployeeId: (state) => state.employeeId,
  activeStoreId: (state) => state.storeId,
  isManager: (state) => !!state.employeeId && state.employeeIsManager,
  employeeAccessStatus: (state) => state.employeeAccessStatus,
};

const setActiveStore = (state, storeId) => {
  state.storeId = parseInt(storeId);

  const { id: employeeId, isManager } =
    state.userStoreEmployees[state.storeId] || {};
  if (employeeId && state.type !== "PosUser") {
    state.employeeId = employeeId;
    state.employeeIsManager = isManager;
    state.teamMode = false;
    state.teamModeLock = false;

    // TODO - Set active store should become and action as it is called from outside of store!!!
    //dispatch("employeeAccessHasEmployee");
    state.teamModeLock = false;
    state.employeeAccessStatus = EMPLOYEE_ACCESS_STATUS.hasEmployee;
  } else {
    // TODO - refactor this so that we do not need to hack employeeId to false
    // - we do that because of components that rely on if (employeeId) or if (!employeeId) to display/hide content
    // - if we set employeeId to undefined (like in Auth before) then we can't discern the cases when employeeId is needed for mutation as it will just not be defined.
    //   - with false, we can catch and change the employeeId when we intercept the graphQL request
    state.employeeId = NO_EMPLOYEE_ID;
    state.employeeIsManager = false;
    state.teamMode = true;
    state.teamModeLock = false;

    // TODO - Set active store should become and action as it is called from outside of store!!!
    //dispatch("employeeAccessNoEmployee");
    state.employeeAccessStatus = EMPLOYEE_ACCESS_STATUS.noEmployee;
  }
};

const mutations = {
  // This mutation should be called on store change.
  setLastRoute(state, route = undefined) {
    state.lastRoute = route || state.lastRoute;
  },
  setActiveStore,
  clearEmployee(state) {
    state.employeeId = NO_EMPLOYEE_ID;
    state.employeeToken = null;
    state.employeeLastActivity = null;
    state.employeeIsManager = null;
    state.employeeClockedIn = null;
  },
  setEmployeeId(state, employeeId) {
    state.employeeId = employeeId;
  },
  setEmployeeIsManager(state, bool) {
    state.employeeIsManager = bool;
  },
  setEmployeeLastActivity(state) {
    state.employeeLastActivity = Date.now();
  },
  setEmployeeClockedIn(state, value) {
    state.employeeClockedIn = value;
  },
  setTeamMode(state, mode) {
    state.teamMode = mode;
  },
  setTeamModeLock(state, status) {
    state.teamModeLock = status;
  },
  setLastEmployee(state, employee) {
    state.lastEmployee = employee;
  },
  setEmployeeAccessStatus(state, status) {
    state.employeeAccessStatus = status;
  },
};

const isDifferentEmployee = ({ state, employee }) => {
  try {
    return state.lastEmployee.id.toString() !== employee.id.toString();
  } catch (_err) {
    return true;
  }
};
// TODO: remove navigation from Vuex. Should be in router via guards
const shouldNavigateToDashboard = ({ state, employee, didClockIn }) => {
  return (
    isDifferentEmployee({
      state,
      employee,
    }) && didClockIn
  );
};
const unlockNavigateTo = ({ state, employee, didClockIn, storeId }) => {
  return shouldNavigateToDashboard({
    state,
    employee,
    didClockIn,
  })
    ? `/s/${storeId}/daybook`
    : state.lastRoute;
};

const actions = {
  enableTeamMode({ commit, state }) {
    return new Promise((resolve) => {
      // This should probably throw an error or something
      if (state.type !== "PosUser" && state.userEmployeeId)
        return resolve(true);
      commit("clearEmployee");
      commit("setTeamMode", true);
      commit("setTeamModeLock", true);
      console.log("team mode enabled");
      resolve(true);
    });
  },

  employeeAccessNeeded({ commit }, { route } = {}) {
    //commit("clearEmployee");
    commit("setTeamModeLock", true);
    commit(
      "setEmployeeAccessStatus",
      EMPLOYEE_ACCESS_STATUS.requiredEmployeeAccess
    );
  },
  employeeAccessNoEmployee({ dispatch, commit }, { route } = {}) {
    commit("clearEmployee");
    commit("setTeamModeLock", true);
    commit("setEmployeeAccessStatus", EMPLOYEE_ACCESS_STATUS.noEmployee);
  },
  employeeAccessHasEmployee({ commit }, { route } = {}) {
    //commit("clearEmployee");
    commit("setTeamModeLock", false);
    commit("setEmployeeAccessStatus", EMPLOYEE_ACCESS_STATUS.hasEmployee);
  },
  employeeClockIn({ commit }, { clockedIn }) {
    commit("setEmployeeClockedIn", clockedIn);
  },

  lockTeamMode({ commit, dispatch }, { route } = {}) {
    commit("clearEmployee");
    commit("setTeamModeLock", true);
    commit("setEmployeeAccessStatus", EMPLOYEE_ACCESS_STATUS.noEmployee);
    dispatch("teamMode/reset", null, { root: true });
  },
  // Clean this up when we move auth to Apollo
  teamModeAuth({ commit, state, dispatch }, { employee, didClockIn, storeId }) {
    console.log(`Setting auth via auth.teamModeAuth`);
    commit("setEmployeeId", employee.id);
    commit("setEmployeeIsManager", employee.isManager);
    const navigateTo = unlockNavigateTo({
      state,
      employee,
      didClockIn,
      storeId,
    });
    commit("setLastEmployee", employee);
    commit("setTeamModeLock", false);
    commit("setEmployeeLastActivity");
    commit("teamMode/setEmployee", employee, { root: true });

    dispatch("employeeAccessHasEmployee");
    return navigateTo;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
