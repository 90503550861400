<script>
import { VCard, VCardTitle, VCardText, VSkeletonLoader } from "vuetify/lib";

// ! DEPRECATED
// TODO: remove after refactoring components that use it

export default {
  name: "RaiSection",
  components: {
    VCard,
    VCardTitle,
    VCardText,
    VSkeletonLoader,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <VCard flat class="rai-section px-0">
    <VCardTitle class="rai-section__title text-subtitle-2">
      <template v-if="loading">
        <VSkeletonLoader
          data-testid="heading-loader"
          type="heading"
          width="65%"
        />
      </template>
      <template v-else>
        <slot name="title">{{ title }}</slot>
      </template>
    </VCardTitle>
    <VCardText class="rai-section__content">
      <template v-if="loading">
        <slot name="loading">
          <VSkeletonLoader type="paragraph" />
        </slot>
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </VCardText>
  </VCard>
</template>

<style lang="scss" scoped>
.rai-section__title {
  text-transform: uppercase;
}
</style>
