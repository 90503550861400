<script>
import { useModelValue } from "../../../mixins";
import { VExpansionPanels } from "vuetify/lib";

export default {
  name: "RaiSetups",
  components: {
    VExpansionPanels,
  },
  provide() {
    return {
      setups: this,
    };
  },
  mixins: [useModelValue()],
  inheritAttrs: false,
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <v-expansion-panels
    v-model="modelValue"
    :multiple="multiple"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-expansion-panels>
</template>
