import { ApolloLink, Observable } from "apollo-link";

export class ExternalActionLink {
  constructor(externalActionRequiredCB, runExternalActionCB) {
    this.pendingRequests = [];

    this.link = this.#buildConfirmLink(
      externalActionRequiredCB,
      (request) => {
        this.pendingRequests.push(request);
      },
      runExternalActionCB
    );
  }

  continueWaitingRequests(updateRequestCallback = (r) => r) {
    this.pendingRequests.forEach((request) => {
      this.#doRequest(request, updateRequestCallback);
    });
    this.pendingRequests = [];
  }

  throwWaitingRequests(errorMessage) {
    this.pendingRequests.forEach((request) => {
      this.#doErrorRequest(request, new Error(errorMessage));
    });
    this.pendingRequests = [];
  }

  request(operation, forward) {
    return this.link.request(operation, forward);
  }

  #doErrorRequest({ observer }, error) {
    observer.error(error);
  }

  #doRequest({ operation, forward, observer }, updateRequestCallback) {
    const updatedOperation = updateRequestCallback(operation);
    const sub = forward(updatedOperation).subscribe({
      next: (result) => {
        observer.next(result);

        if (sub) sub.unsubscribe();
      },
      error: (errors) => {
        observer.error(errors);

        if (sub) sub.unsubscribe();
      },
      complete: () => {
        observer.complete();
      },
    });
  }

  #buildConfirmLink(
    externalActionRequiredCB,
    addWaitingRequest,
    runExternalActionCB
  ) {
    return new ApolloLink((operation, forward) => {
      if (!externalActionRequiredCB(operation)) {
        return forward(operation);
      }

      return new Observable((observer) => {
        addWaitingRequest({
          operation,
          forward,
          observer,
        });
        runExternalActionCB(operation);

        return () => {};
      });
    });
  }
}
