import i18n from "@/plugins/vue-i18n";

const goalsSetupRoute = {
  meta: {
    requiresAuth: true,
    requiresManager: true,
  },
  components: {
    default: () => import("@/views/StoreSettingsView"),
  },
  children: [
    {
      path: "",
      name: "goal.setup",
      component: () => import("@/views/Goal/SetupView"),
      meta: {
        title: i18n.t("goal.setup.title"),
        toolbarTitle: i18n.t("goal.setup.toolbarTitle"),
        description: i18n.t("goal.setup.description"),
      },
    },
  ],
};

const goalsSetupCommonRouteProps = (route) => {
  const activeSetup =
    route.query.activeSetup === undefined
      ? undefined
      : parseInt(route.query.activeSetup, 10);

  return {
    activeSetup,
  };
};

export { goalsSetupRoute, goalsSetupCommonRouteProps };
