import Vue from "vue";

export default Vue.extend({
  name: "VxTableRow",
  functional: true,
  render(h, { data, children }) {
    data.staticClass = `vx-table__row ${data.staticClass || ""}`.trim();
    return h("tr", data, children);
  },
});
