import VxBlockingError from "../../components/vx/VxBlockingError/VxBlockingError.vue";

/**
 * Renders a blocking error in DOM on demand
 * @param {import("vue").VueConstructor} Vue - Vue constructor
 * @param {{ vuetify: import("vuetify/lib").default }} args - arguments provided by plugin
 * @param {{ message: string | JSX.Element  }} options - for rendering the blocking error
 * @returns {Promise<boolean>} a promise to be resolved by the blocking error
 */
export function renderBlockingError(Vue, args, options) {
  return new Promise((resolve) => {
    const { message, ...propsData } = options;

    /** @type {import("vue").default} */
    const instance = new Vue({
      vuetify: args.vuetify,
      data: () => ({ dialog: true }),
      methods: {
        ok() {
          resolve(true);
          this.dialog = false;
        },
        cancel() {
          resolve(false);
          this.dialog = false;
        },
      },
      render(h) {
        return (
          this.dialog &&
          h(
            VxBlockingError,
            {
              props: {
                value: this.dialog,
                ...propsData,
              },
              on: {
                ok: this.ok,
                cancel: this.cancel,
              },
            },
            message
          )
        );
      },
    });

    instance.$mount(); // -> This will attach to the root v-app component by default.
  });
}
