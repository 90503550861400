function formatNumber(value, decimals, type = "decimal") {
  return Number(+value)?.toLocaleString("en-US", {
    currency: type === "currency" ? "USD" : undefined,
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
    style: type,
  });
}

export { formatNumber };
export default formatNumber;
