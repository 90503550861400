// TODO update date-fns to v2
import {
  addHours,
  addMinutes,
  addWeeks,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  getMinutes,
  isAfter,
  isBefore,
  isSameYear,
  isToday,
  isTomorrow,
  isWithinInterval,
  isYesterday,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfWeek,
  endOfWeek,
  subDays,
  subWeeks,
  isSameDay,
  differenceInDays,
  startOfDay,
  parseISO,
  isValid,
  startOfMonth,
  endOfMonth,
  isThisWeek,
  isThisMonth,
  addDays,
  endOfDay,
} from "date-fns";

import { chain } from "@/utils/lib";

const transformToDate = (arg) => {
  if (typeof arg === "string") {
    return parseISO(arg);
  }
  return arg;
};

const capitalize = (string, shouldCapitalize) => {
  if (!shouldCapitalize) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Material Design Spec formats:
// TODO: ensure this meets our specific needs (the spec is broad guidelines)
//
// Use as filters:
//
//    components: {
//      MyComponent
//    },
//    filters: {
//      bizDay
//    }
//
//    ...
//
//    <MyComponent>{{ value | bizDay }}</MyComponent>

// - bizDay - when Day of Week is important (Daybook, reports):
//   - Today, Tomorrow, Yesterday
//   - Thursday, Jan 9  (same calendar year)
//   - Wednesday, Jan 9, 2019
//   - Wed, Jan 9, 2019 (abbreviated for mobile)
export const bizDay = (date) => {
  return materialDateForDatePicker(date, true);
};

export const bizWeek = (date) => {
  date = transformToDate(date);
  const now = new Date();
  if (isSameYear(date, now)) return format(date, "MMM d");
  return format(date, "MMM d, yyyy");
};

export const bizMonth = (date) => {
  date = transformToDate(date);
  const now = new Date();
  if (isSameYear(date, now)) return format(date, "MMMM");
  return format(date, "MMM, yyyy");
};

//
// Returns a date string that follows the Material spec
// https://material.io/design/communication/data-formats.
export const materialDate = (date) => {
  date = transformToDate(date);

  const now = new Date();
  if (isYesterday(date)) return "yesterday";
  if (isToday(date)) return "today";
  if (isTomorrow(date)) return "tomorrow";

  const thisWeek = { start: startOfWeek(now), end: endOfWeek(now) };
  if (isWithinInterval(date, thisWeek)) return format(date, `iiii`);

  // const lastWeek = thisWeek.map((e) => subWeeks(e, 1));
  const lastWeek = {};
  Object.keys(thisWeek).forEach((key) => {
    lastWeek[key] = subWeeks(thisWeek[key], 1);
  });
  if (isWithinInterval(date, lastWeek)) return `last ${format(date, "iiii")}`;

  // const nextWeek = thisWeek.map((e) => addWeeks(e, 1));
  const nextWeek = {};
  Object.keys(thisWeek).forEach((key) => {
    nextWeek[key] = addWeeks(thisWeek[key], 1);
  });
  if (isWithinInterval(date, nextWeek)) return `next ${format(date, "iiii")}`;

  if (isSameYear(date, now)) return format(date, "MMM d");
  return format(date, "MMM d, yyyy");
};

export const materialDateForDatePicker = (date, shouldCapitalize = false) => {
  date = transformToDate(date);
  if (isToday(date)) return capitalize("today", shouldCapitalize);
  if (isYesterday(date)) return capitalize("yesterday", shouldCapitalize);
  if (isTomorrow(date)) return capitalize("tomorrow", shouldCapitalize);
  const now = new Date();
  if (isSameYear(date, now)) return format(date, "iii, MMM d");
  return format(date, "iii, MMM d, yyyy");
};

// Returns an object of format
// { date: MaterialDate, time: h:MM A }
export const materialAbsoluteDateTimeObject = (date) => {
  date = transformToDate(date);
  const returnDate = materialDate(date);
  const time = materialTime(date);
  return {
    date: returnDate,
    time: time,
  };
};

export const materialTime = (date, uppercase = true) => {
  date = transformToDate(date);
  return uppercase ? format(date, "h:mm a") : format(date, "h:mm aaa");
};

const isAfterDaysAgo = (date, baseDate, nDays) =>
  isAfter(date, chain(baseDate).do(subDays, nDays).do(startOfDay).value());

// Returns a string
// If date >= 24 hours ago, "2:00 pm"
// If date <= 24 hours ago >= 7 days ago, "[1..7] day[s]"
// If date <= 7 days ago, "Feb 8"
export const relativeTime = (date, baseDate = new Date()) => {
  const dateType = transformToDate(date);
  const baseDateType = transformToDate(baseDate);
  // Guards
  if (!baseDate || !chain(dateType).do(isValid).value())
    throw new Error(
      `Expected \`date\` to be a Date or Date String, got ${date}`
    );
  if (!date || !chain(baseDateType).do(isValid).value())
    throw new Error(
      `Expected \`baseDate\` to be a Date or Date String, got ${baseDate}`
    );

  if (isSameDay(dateType, baseDateType)) return format(dateType, "h:mm aaa");
  if (isAfterDaysAgo(dateType, baseDateType, 7)) {
    const days = Math.abs(differenceInDays(baseDateType, dateType)) || 1;
    const val = `${days} day${(days !== 1 && "s") || ""}`;
    return val;
  }
  if (isSameYear(dateType, baseDateType)) return format(dateType, "MMM d");
  return format(dateType, "MMM d, yyyy");
};

// Returns a string of format
// 'MaterialDate at h:MM A'
export const materialAbsoluteDateTimeString = (date) => {
  date = transformToDate(date);
  const dateTimeObject = materialAbsoluteDateTimeObject(date);
  return `${dateTimeObject.date} at ${dateTimeObject.time}`;
};

// Returns a string of format
//  'h:MM A' if it is today
//  'MaterialDate' if it is not today
export const materialDateOrTimeString = (date) => {
  date = transformToDate(date);
  const dateTimeObject = materialAbsoluteDateTimeObject(date);
  if (isToday(date)) {
    return `${dateTimeObject.time}`;
  }
  return `${dateTimeObject.date}`;
};

// Converts 11:30 to March 4, 2019, 11:30
export const timestringToTimestamp = (string, date = null) => {
  const [h, m] = string.split(":");
  date = transformToDate(date);
  let d = (isValid(date) && date) || new Date();
  d.setHours(h, m, 0, 0);
  return d;
};

export const duration = (earlier, later) => {
  if (
    [
      earlier && earlier.getMonth && typeof earlier.getMonth === "function",
      later && later.getMonth && typeof later.getMonth === "function",
    ].some((e) => !e)
  )
    throw new Error(
      `Expected both arguments to be Date objects. Got ${earlier}, ${later}`
    );

  let hours = Math.floor(differenceInHours(later, earlier));
  let minutes = differenceInMinutes(later, earlier);
  let seconds = differenceInSeconds(later, earlier);
  if (hours > 0) {
    minutes = minutes % (60 * hours);
    seconds = seconds % (3600 * hours);
  }
  if (minutes > 0) {
    seconds = seconds % (60 * minutes);
  }
  return {
    hours,
    minutes,
    seconds,
  };
};

// Returns an array of numbers which count up to 60 from `0` by `interval`, excluding 60
// Example: minuteIntervals(15) === [0, 15, 30, 45]
const minuteIntervals = (interval) => {
  const intervals = [];
  for (let i = 0; i <= 60 - interval; i += interval) {
    intervals.push(i);
  }
  return intervals;
};

// Given a date and a minute interval,
// this function will return a date object set to the
// next occurring minute interval which is >= minute interval minutes from now.
//
// Example:
// Given
//   - date = new Date(2019, 6, 3, 11, 27) // Wed Jul 03 2019 11:27:00 GMT-0500 (Central Daylight Time)
//   - interval = 15
// Result
//   - Wed Jul 03 2019 11:45:00 GMT-0500 (Central Daylight Time)
export const nextInterval = (date, minutes = 15) => {
  // Prep the date object
  date = addMinutes(date, minutes);
  date = setSeconds(date, 0);
  date = setMilliseconds(date, 0);
  const currentMinutes = getMinutes(date);
  // Calculate the intervals
  const intervals = minuteIntervals(minutes);
  // Look for an interval that is <= currentMinutes
  const interval = intervals.find((i) => currentMinutes <= i);
  // If one is found, return a date object with that interval set as the minutes
  if (interval !== undefined) {
    return setMinutes(date, interval);
  }
  // Otherwise, return a date object which is rounded up to the next hour
  date = addHours(date, 1);
  return setMinutes(date, 0);
};

// helpers for sortByDate
const descending = ["d", "desc", "descending"];
const ASC = "asc";
const DESC = "desc";

const determineSortOrder = (order) => {
  if (descending.includes(order)) return DESC;
  return ASC;
};

export const sortByDate = (list, opts = { field: "createdAt", order: ASC }) => {
  const order = determineSortOrder(opts.order);

  const comparator = order === DESC ? isAfter : isBefore;

  return list.slice().sort((a, b) => {
    return (
      (comparator(parseISO(a[opts.field]), parseISO(b[opts.field])) && -1) ||
      (comparator(parseISO(b[opts.field]), parseISO(a[opts.field])) && 1) ||
      0
    );
  });
};

/**
 * Checks whether the date is in the current time period
 * @param {Date} date - instance of Date
 * @param {String} type - the time period for which we check, either "week" or "month"
 * @param {Number} weekStartDay - index of the first day of the week(0 - Sunday is default)
 * @returns Boolean
 */
export const isCurrentPeriod = (date, type, weekStartDay = 0) => {
  if (type === "week") {
    return isThisWeek(date, { weekStartsOn: weekStartDay });
  }
  if (type === "month") {
    return isThisMonth(date);
  }
};

/**
 * Returns the start of the time period the date is in
 * @param {Date} date - instance of Date
 * @param {String} type - the time period for which we check, either "week" or "month"
 * @param {Number} weekStartDay - index of the first day of the week(0 - Sunday is default)
 * @returns instance of Date
 */
export const periodStart = (date, type, weekStartDay = 0) => {
  if (type === "week") {
    return startOfWeek(date, { weekStartsOn: weekStartDay });
  }
  if (type === "month") {
    return startOfMonth(date);
  }
};

/**
 * Returns the end of the time period the date is in
 * @param {Date} date - instance of Date
 * @param {String} type - the time period for which we check, either "week" or "month"
 * @param {Number} weekStartDay - index of the first day of the week(0 - Sunday is default)
 * @returns instance of Date
 */
export const periodEnd = (date, type, weekStartDay = 0) => {
  if (type === "week") {
    return startOfDay(endOfWeek(date, { weekStartsOn: weekStartDay }));
  }
  if (type === "month") {
    return startOfDay(endOfMonth(date));
  }
};

/**
 * Returns a string depending on date difference ( comparing to today by default )
 * if <24h, "Today" or "Today, 2:00 pm",
 * if >24h and <48h, "Tomorrow" or "Tomorrow, 2:00 pm",
 * if <7 days, "Friday" or "Friday, 2:00 pm",
 * if >7 days, "Feb 8th" or "Feb 8th, 2:00 pm"
 * @param {Date} date - instance of Date
 * @param {Date} comparisonDate - instance of Date
 * @param {Boolean} showTime - whether to show the time
 * @returns String
 */
export const relativeDateTime = (
  date,
  comparisonDate = new Date(),
  showTime = false
) => {
  if (isSameDay(date, comparisonDate)) {
    return showTime ? `Today, ${materialTime(date, false)}` : "Today";
  } else if (isSameDay(date, addDays(comparisonDate, 1))) {
    return showTime ? `Tomorrow, ${materialTime(date, false)}` : "Tomorrow";
  } else if (
    isWithinInterval(date, {
      start: endOfDay(comparisonDate),
      end: addDays(endOfDay(comparisonDate), 6),
    })
  ) {
    return showTime
      ? `${format(date, `iiii`)}, ${materialTime(date, false)}`
      : format(date, `iiii`);
  } else {
    return showTime
      ? `${format(date, "MMM do")}, ${materialTime(date, false)}`
      : format(date, "MMM do");
  }
};
