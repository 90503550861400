import auth from "./en/auth";
import global from "./en/global";
import onboarding from "./en/onboarding";

import * as buy from "./en/buy";
import * as bounceback from "./en/bounceback";
import * as backstock from "./en/backstock";
import * as daybook from "./en/daybook";
import * as settings from "./en/settings";
import * as report from "./en/report";
import * as goal from "./en/goal";

export default {
  locale: "en",
  messages: {
    en: {
      global,
      settings,
      buy,
      backstock,
      bounceback,
      daybook,
      report,
      goal,
      auth,
      onboarding,
    },
  },
};
