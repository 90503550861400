<script>
import { genSlots } from "../../../utils";
import { VAlert } from "vuetify/lib";

export default {
  name: "VxAlert",
  extends: VAlert,
  props: {
    type: {
      type: String,
      default: undefined,
      validator(value) {
        return ["success", "info", "warning", "error"].includes(value);
      },
    },
    text: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    props() {
      return {
        ...this.$props,
        icon: this.$props.icon || undefined, // -> resolves icon validator warning
      };
    },
  },
  render(h) {
    return h(
      VAlert,
      {
        props: this.props,
        attrs: this.$attrs,
        scopedSlots: this.$scopedSlots,
        on: this.$listeners,
      },
      genSlots(this, ["default", "append", "prepend"])
    );
  },
};
</script>
