import {
  transform,
  camelCase,
  get,
  snakeCase,
  isObject,
  omit,
  cloneDeep,
} from "lodash";
import us from "us";

export const stripNulls = (o) => {
  for (var key in o) {
    if (o[key] === null || o[key] === undefined) {
      delete o[key];
      continue;
    }
    if (typeof o[key] === "object") {
      o[key] = stripNulls(o[key]);
    }
  }
  return o;
};

export const parseIfJson = (object) => {
  if (typeof object === "string") return JSON.parse(object);
  return object;
};

export const canada = {
  provinces: [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
  ],
  territories: ["Northwest Territories", "Nunavut", "Yukon"],
};

export const canadaMap = {
  NL: "Newfoundland and Labrador",
  PE: "Prince Edward Island",
  NS: "Nova Scotia",
  NB: "New Brunswick",
  QC: "Quebec",
  ON: "Ontario",
  MB: "Manitoba",
  SK: "Saskatchewan",
  AB: "Alberta",
  BC: "British Columbia",
  YT: "Yukon",
  NT: "Northwest Territories",
  NU: "Nunavut",
};

export const emptyBuy = () => {
  return {
    id: null,
    trans_id: null,
    created_at: null,
    customer: {
      id: null,
      full_name: "",
      first_name: "",
      last_name: "",
    },
    customer_id: null,
    customer_full_name: "",
    customer_flagged: null,
    container_num: null,
    container_description: "",
    sorter_id: null,
    quote_items: null,
    quote_amount: null,
    take_reasons: [],
    pass_reasons: [],
    checked_in_at: null,
    checked_in_by_employee_id: null,
    started_at: null,
    completed_at: null,
    closed_at: null,
    signature_file_name: "",
    estimated_pickup_at: "",
  };
};

// Recusively transforms all keys to camelCase
export const camelizeKeys = (object) => {
  return transform(object, (result, value, key) => {
    if (value === null || typeof value !== "object") {
      result[camelCase(key)] = value;
      return result;
    }
    result[camelCase(key)] = camelizeKeys(value);
    return result;
  });
};

// camelizesKeys on all objects in an array
export const camelizeArray = (array) => {
  return array.map((e) => (typeof e === "object" && camelizeKeys(e)) || e);
};

export const camelize = (any) => {
  if (Array.isArray(any)) return camelizeArray(any);
  if (isObject(any)) return camelizeKeys(any);
  return any;
};

export const snakeCaseKeys = (object) => {
  return transform(object, (result, value, key) => {
    if (value === null || typeof value !== "object") {
      result[snakeCase(key)] = value;
      return result;
    }
    result[snakeCase(key)] = snakeCaseKeys(value);
    return result;
  });
};

export const snakeCaseArray = (array) => {
  return array.map((e) => (typeof e === "object" && snakeCaseKeys(e)) || e);
};

export const snakify = (any) => {
  if (Array.isArray(any)) return snakeCaseArray(any);
  if (isObject(any)) return snakeCaseKeys(any);
  return any;
};

// Tries to get the snake_case path
// If that path is undefined, tries to get camelCase path
// If that path is undefined, returns undefinedValue or undefined
export const iget = (object, path, undefinedValue = undefined) => {
  const camel = get(object, camelCase(path), undefinedValue);
  if (camel !== undefinedValue) return camel;

  const snake = get(object, snakeCase(path), undefinedValue);
  if (snake !== undefinedValue) return snake;

  return undefinedValue;
};

// Pushes fn into the event loop with a ms timeout of 0
// This should be used for any code that doesn't need to
// run perfectly sequentially and can be safely executed later
export const bg = (fn, args = []) => {
  return setTimeout(fn(...args), 0, ...args);
};

// Builds an object context that can be passed to error aggregates
export const buildComponentContext = (vm, ...args) => {
  return {
    path: get(vm, "$route.fullPath"),
    component: get(vm, "_name"),
    props: get(vm, "$props", {}),
    data: omit(get(vm, "$data", {}), ["$apolloData"]),
    misc: [...args],
  };
};

// All us states, canadian provinces, and canadian territories
export const states = () => {
  return us.STATES_AND_TERRITORIES.map(({ name, abbr }) => ({
    name,
    abbr,
  })).reduce((accum, el) => {
    accum[el.abbr] = el.name;
    return accum;
  }, canadaMap);
};

// Allows wrapping a value and chaining methods
// Ex:
// const add = (a, b) => a + b
// const subtract = (a, b) => a - b
// chain(1)
//    .do(add, 2)       // internal value is 3
//    .do(add, 10)      // internal value is 13
//    .do(subtract, 1)  // internal value is 12
//    .value()          // returns 12
export const chain = (val) => ({
  internalValue: cloneDeep(val),
  do(fn, ...args) {
    const result = fn(this.internalValue, ...args);
    this.internalValue = result;
    return this;
  },
  value() {
    return this.internalValue;
  },
});

export const responsiveComponent = ({ breakpoint, mobile, desktop }) =>
  breakpoint.smAndDown ? mobile : desktop;
