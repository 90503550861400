const state = () => ({
  visible: false,
  text: null,
  timeout: 6000,
  multiline: false,
  action: null,
  button: null,
  color: undefined,
});

const getters = {};

const mutations = {
  /**
   * DO NOT CALL FROM OUTSIDE Vuex
   * @param {*} state
   * @param {*} payload
   */
  showSnackbar(state, payload) {
    state.text = payload.text;
    state.multiline = payload.text.length > 50;
    if (payload.multiline) {
      state.multiline = payload.multiline;
    }
    if (payload.timeout) {
      state.timeout = payload.timeout;
    }
    if (payload.color) {
      state.color = payload.color;
    }
    if (payload.button !== undefined) {
      state.button = payload.button;
    }
    if (payload.action) {
      state.action = payload.action;
    }
    state.visible = true;
  },
  closeSnackbar(state) {
    state.visible = false;
    state.multiline = false;
    state.timeout = 6000;
    state.text = null;
    state.action = null;
    state.button = null;
    state.color = undefined;
  },
  visible(state, val) {
    state.visible = val;
  },
};

const actions = {
  closeSnackbar({ commit }) {
    commit("closeSnackbar");
  },
  /**
   * Displays a Snackbar
   *
   * If showSnackbar is called with and action but without a button, it will throw an error.
   * If there is no action, and no button it will no longer display a "Dismiss" button.
   * If there is a button without an action, the button will close the snackbar
   *
   * @param {*} param0
   * @param {*} payload
   */
  showSnackbar({ commit }, payload) {
    if (!!payload.action && !payload.button) {
      throw "Missing button parameter on Snackbar with an action";
    }
    if (
      !!payload.timeout &&
      (payload.timeout < 4000 || payload.timeout > 10000)
    ) {
      console.warn("Snackbar timeout should be between 4 and 10 seconds");
    }
    commit("showSnackbar", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
