import Vue from "vue";
import { enumValidator, getSlot, isNullOrEmpty } from "../../../../utils";
import { TableCellType, TableCellAlign } from "../constants";
import {
  formatPhoneNumber,
  formatNumber,
  formatBoolean,
  formatDate,
  formatDuration,
} from "../../../../formatting";

export default function useTableCell() {
  return Vue.extend({
    name: "TableCell",
    props: {
      value: {
        type: [String, Number, Date, Boolean],
        default: "",
      },
      type: {
        type: String,
        required: true,
        validator: enumValidator(TableCellType),
      },
      align: {
        type: String,
        default: TableCellAlign.START,
        validator: enumValidator(TableCellAlign),
      },
      hidden: {
        type: Boolean,
        default: false,
      },
      className: {
        type: String,
        default: "",
      },
      dateFormat: {
        type: String,
        default: "MMM d, yyyy",
      },
      decimals: {
        type: Number,
        default: undefined,
      },
      trueValue: {
        type: String,
        default: "Yes",
      },
      falseValue: {
        type: String,
        default: "No",
      },
    },
    computed: {
      formattedValue() {
        return this.formatValue(this.value);
      },
      isText() {
        return this.type === TableCellType.TEXT;
      },
      isNumber() {
        return [
          TableCellType.NUMBER,
          TableCellType.CURRENCY,
          TableCellType.PERCENT,
        ].includes(this.type);
      },
      defaultSlotProps() {
        return { value: this.formattedValue };
      },
      cellClasses() {
        const alignClass = `text-${
          this.isNumber ? TableCellAlign.END : this.align
        }`;

        return {
          "d-none": this.hidden,
          [alignClass]: !!this.align,
        };
      },
    },
    methods: {
      genContent() {
        const defaultSlot = getSlot(
          this,
          "default",
          this.defaultSlotProps,
          this.isText
        );

        if (isNullOrEmpty(this.value) && defaultSlot) {
          const textValue = defaultSlot[0].text?.trim();
          defaultSlot[0].text = this.formatValue(textValue);
        }

        return defaultSlot || this.formattedValue;
      },
      formatValue(value) {
        try {
          if (isNullOrEmpty(value) || this.isText) {
            return value;
          } else if (this.type === TableCellType.PHONE) {
            return formatPhoneNumber(value);
          } else if (this.type === TableCellType.DATE) {
            return formatDate(value, this.dateFormat);
          } else if (this.type === TableCellType.CURRENCY) {
            return formatNumber(value, this.decimals, "currency");
          } else if (this.type === TableCellType.PERCENT) {
            return formatNumber(value, this.decimals, "percent");
          } else if (this.type === TableCellType.NUMBER) {
            return formatNumber(value, this.decimals || 0);
          } else if (this.type === TableCellType.BOOLEAN) {
            return formatBoolean(value, this.trueValue, this.falseValue);
          } else if (this.type === TableCellType.DURATION) {
            return formatDuration(value);
          }
        } catch (e) {
          return e.message;
        }
      },
    },
  });
}
