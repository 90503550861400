import Vue from "vue";

/**
 * @typedef {Object} ModelValueOptions
 * @property {String?} prop - by default is "value"
 * @property {String?} event - by default is "input"
 */

/**
 * @param {ModelValueOptions} [options] - define event to emit on v-model and prop that is passing down value
 * @returns
 */
export function useModelValue(options = {}) {
  const { prop = "value", event = "input" } = options;

  return Vue.extend({
    model: { prop, event },
    props: {
      [prop]: {
        type: null,
        default: "",
      },
    },
    computed: {
      modelValue: {
        get() {
          return this[prop];
        },
        set(value) {
          this.$emit(event, value);
        },
      },
    },
  });
}
