import { default as SORT_ORDER } from "./SortOrder";

function updateGQLCursors(
  currentPaginationOptions,
  serverRequest,
  page,
  itemsPerPage,
  newSortBy,
  newSortOrder
) {
  const oldPage = currentPaginationOptions.page;

  //const oldItemsPerPage = currentPaginationOptions.itemsPerPage;
  // TODO - see if to do this check at all!
  // If the page and items per page didn't change, don't do anything
  // if (page === oldPage && itemsPerPage === oldItemsPerPage) {
  //   return;
  // }

  // TODO - whereever I access GQLPagination - if it doesn't exist, create it
  serverRequest.sortBy = newSortBy;
  serverRequest.descending = newSortOrder === SORT_ORDER.DESC;

  // If we're going to the first page, reset pagination
  if (page === 1) {
    serverRequest.first = itemsPerPage;
    serverRequest.last = null;
    serverRequest.after = null;
    serverRequest.before = null;
  } else if (page > oldPage) {
    // Moving forward
    serverRequest.after = currentPaginationOptions.pageInfo.endCursor;
    serverRequest.first = itemsPerPage;

    serverRequest.before = null;
    serverRequest.last = null;
  } else if (page < oldPage) {
    // Moving backward
    serverRequest.before = currentPaginationOptions.pageInfo.startCursor;
    serverRequest.last = itemsPerPage;

    serverRequest.after = null;
    serverRequest.first = null;
  } else {
    // Changing items per page
    serverRequest.after = currentPaginationOptions.pageInfo.startCursor;
    serverRequest.first = itemsPerPage;

    serverRequest.before = null;
    serverRequest.last = null;
  }
}

export const ServerPaginationUtils = {
  /**
   *
   * @param {Object} apolloQueries - collection of apollo queries
   * @param {String} queryName - query which should load more
   * @param {String} dataItemName - name of return data prop
   * @param {Object} variables - variables for fetching
   */
  fetchMoreData(apolloQueries, queryName, dataItemName, variables) {
    apolloQueries[queryName].fetchMore({
      variables: variables,
      // Transform the previous result with new data
      updateQuery: (previousData, fetchMoreResponse) => {
        const parsedPreviousEdges = previousData[dataItemName].edges;
        const parsedNewEdges =
          fetchMoreResponse.fetchMoreResult[dataItemName].edges;

        const returnData = {};
        returnData[dataItemName] = {
          __typename:
            fetchMoreResponse.fetchMoreResult[dataItemName].__typename,
          edges: [...parsedPreviousEdges, ...parsedNewEdges],
          pageInfo: fetchMoreResponse.fetchMoreResult[dataItemName].pageInfo,
          totalItems:
            fetchMoreResponse.fetchMoreResult[dataItemName].totalItems,
        };

        return returnData;
      },
    });
  },

  /**
   *
   * @param {PaginationOptions} currentPaginationOptions current pagination options - will update those options.
   * @param {SortOptions} currentSortOptions current sort options - will update those options.
   * @param {GQLServerRequest} serverRequest request for server GQL - will update the request
   * @param {Number} page new page value
   */
  updatePage(
    currentPaginationOptions,
    currentSortOptions,
    serverRequest,
    page
  ) {
    updateGQLCursors(
      currentPaginationOptions,
      serverRequest,
      page,
      currentPaginationOptions.itemsPerPage,
      currentSortOptions.sortBy,
      currentSortOptions.sortOrder
    );

    // TODO - see if there is some logic to handle here, like moving just 1 page forward/backward
    currentPaginationOptions.page = page;
  },

  /**
   *
   * @param {PaginationOptions} currentPaginationOptions current pagination options - will update those options.
   * @param {SortOptions} currentSortOptions current sort options - will update those options.
   * @param {GQLServerRequest} serverRequest request for server GQL - will update the request
   * @param {Number} itemsPerPage new items per page value
   */
  updateItemsPerPage(
    currentPaginationOptions,
    currentSortOptions,
    serverRequest,
    itemsPerPage
  ) {
    updateGQLCursors(
      currentPaginationOptions,
      serverRequest,
      currentPaginationOptions.page,
      itemsPerPage,
      currentSortOptions.sortBy,
      currentSortOptions.sortOrder
    );

    // TODO - see if there is some logic to handle here, like moving to first/last page to show results
    currentPaginationOptions.itemsPerPage = itemsPerPage;
  },

  /**
   *
   * @param {PaginationOptions} currentPaginationOptions current pagination options - will update those options.
   * @param {SortOptions} currentSortOptions current sort options - will update those options.
   * @param {GQLServerRequest} serverRequest request for server GQL - will update the request
   * @param {String} newSortBy new sort by value
   */
  updateSort(
    currentPaginationOptions,
    currentSortOptions,
    serverRequest,
    newSortBy
  ) {
    let updateSortBy = newSortBy;
    let updateSortOrder = currentPaginationOptions.sortOrder;

    if (currentSortOptions.sortBy === newSortBy) {
      if (currentSortOptions.sortOrder === SORT_ORDER.ASC) {
        updateSortOrder = SORT_ORDER.DESC;
      } else {
        updateSortOrder = SORT_ORDER.ASC;
      }
    } else {
      updateSortOrder = SORT_ORDER.ASC;
    }

    updateGQLCursors(
      currentPaginationOptions,
      serverRequest,
      1,
      currentPaginationOptions.itemsPerPage,
      updateSortBy,
      updateSortOrder
    );

    currentSortOptions.sortBy = updateSortBy;
    currentSortOptions.sortOrder = updateSortOrder;
    currentPaginationOptions.page = 1;
  },

  /**
   *
   * @param {PaginationOptions} paginationOptions current pagination options - will update those options.
   * @param {GQLPaginatedInfoResponse} serverItems server response from which to update data
   */
  updateOptionsFromServerData(paginationOptions, serverItems) {
    // TODO - maybe also update first/last ?
    paginationOptions.totalItems = serverItems.totalItems;
    paginationOptions.pageInfo = serverItems.pageInfo;
  },
};
