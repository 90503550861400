<template>
  <VxTooltip top>
    <template v-slot:activator="{ on }">
      <div :class="['d-flex', customClasses]" v-bind="$attrs" v-on="on">
        <RaiEmployeeAvatar
          v-for="(employee, index) in employees"
          :key="employee.id"
          :employee="employee"
          :size="size"
          :list-item="false"
          :style="{
            marginLeft: index !== 0 ? `${size * -0.5}px !important` : '0px',
            zIndex: employees.length - index,
          }"
        />
      </div>
    </template>
    <span v-text="employeesNames"></span>
  </VxTooltip>
</template>

<script>
import { VxTooltip } from "../../vx";
import RaiEmployeeAvatar from "../RaiEmployeeAvatar/RaiEmployeeAvatar.vue";

export default {
  name: "RaiEmployeeAvatarList",
  components: {
    VxTooltip,
    RaiEmployeeAvatar,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    size: {
      type: Number,
      default: 40,
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    employeesNames() {
      return this.employees.map((employee) => employee.fullName).join(", ");
    },
  },
};
</script>
