import store from "@/store";
import { baseGuard } from "./baseGuard";

export const checkManagerAccess = (options = { active: () => true }) => {
  return baseGuard(options, (to, from, next) => {
    if (!store.getters["auth/isManager"]) {
      // If not a manager tries to access route, just route him back to index of app
      // TODO - route him to error page !
      next({ path: "/" });
    } else {
      next();
    }
  });
};
