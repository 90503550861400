import { render, staticRenderFns } from "./VxDialogDesktop.vue?vue&type=template&id=2501c6cc&"
import script from "./VxDialogDesktop.vue?vue&type=script&lang=js&"
export * from "./VxDialogDesktop.vue?vue&type=script&lang=js&"
import style0 from "./VxDialogDesktop.vue?vue&type=style&index=0&id=2501c6cc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports