<script>
import { getSlot } from "../../../utils";
import { VStepperStep } from "vuetify/lib";

export default {
  name: "VxStepperStep",
  extends: VStepperStep,
  props: {
    step: {
      type: Number,
      required: true,
    },
    complete: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    optional: {
      type: Boolean,
      default: false,
    },
    optionalText: {
      type: String,
      default: "$vuetify.vx.stepper.optionalText",
    },
  },
  render(h) {
    return h(
      VStepperStep,
      {
        props: this.$props,
        on: this.$listeners,
      },
      [
        getSlot(this, "default", null) || this.title,
        this.optional &&
          h(
            "small",
            { class: "mt-1" },
            this.$vuetify.lang.t(this.optionalText)
          ),
      ].filter(Boolean)
    );
  },
};
</script>
