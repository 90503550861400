<script>
import { VRadioGroup } from "vuetify/lib";
import { useModelValue, useValidationProvider } from "../../../mixins";

export default {
  name: "VxRadioGroup",
  components: {
    VRadioGroup,
  },
  mixins: [useModelValue({ event: "change" }), useValidationProvider()],
  inheritAttrs: false,
  methods: {
    validator() {
      /* istanbul ignore next */
      return this.$refs.provider;
    },
  },
};
</script>

<template>
  <ValidationProvider
    ref="provider"
    :vid="name"
    :name="label"
    :rules="rules"
    :mode="mode"
    slim
  >
    <template v-slot="{ errors }">
      <VRadioGroup
        ref="radioGroup"
        v-model="modelValue"
        :name="name"
        :label="label"
        :error-messages="errors"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <slot name="default"></slot>
        <slot name="append"></slot>
        <slot name="label"></slot>
        <slot name="prepend"></slot>
      </VRadioGroup>
    </template>
  </ValidationProvider>
</template>
