import store from "@/store";
import { baseGuard } from "./baseGuard";

export const ensureStoreId = (options = { active: () => true }) => {
  return baseGuard(options, (to, from, next) => {
    if (!to.params.storeId) {
      const storeId =
        store.getters["auth/activeStoreId"] ||
        store.getters["auth/currentUserDefaultStoreId"];
      next({ name: "daybook", params: { storeId: storeId?.toString() } });
    } else {
      next();
    }
  });
};
