import en from "vuetify/lib/locale/en";

export default {
  ...en,
  rai: {
    editable: {
      saveText: "Save",
      cancelText: "Cancel",
    },
    inlineEdit: {
      saveText: "Save",
      cancelText: "Cancel",
    },
    setup: {
      nextText: "Next",
    },
    metricTrend: {
      upSuffix: "over",
      downSuffix: "under",
    },
  },
  vx: {
    dataList: {
      emptyDataText: "No data",
      dataLoadingText: "Loading data...",
    },
    table: {
      loadingText: "Loading items...",
      noDataText: "No data available",
    },
    dataPaginator: {
      desktop: {
        paginationText: "{0}-{1} of {2}",
      },
      mobile: {
        paginationText: "Displaying {0}/{1}",
        showNextText: "Show next {0}",
      },
    },
    confirmDialog: {
      okText: "Ok",
      cancelText: "Cancel",
    },
    stepper: {
      nextText: "Next",
      previousText: "Previous",
      optionalText: "Optional",
    },
    blockingError: {
      defaultPrimaryText: "Ok",
      defaultSecondaryText: "Cancel",
    },
  },
};
