<script>
import { VList, VListItemGroup } from "vuetify/lib";
import { props } from "./VxDataListBase";
import VxDataPaginator from "./VxDataPaginator";
import { getSlot } from "../../../utils";

// Use !!this.loading - because loading could be a number
export default {
  name: "VxDataListMobile",
  props,
  render(h) {
    return h(
      VList,
      { class: "vx-data-list vx-data-list--mobile", attrs: this.$attrs },
      [
        getSlot(this, "headerExtension", { mobile: this.mobile }),
        getSlot(this, "header", { mobile: this.mobile }),
        getSlot(this, "groups", { mobile: this.mobile }) ||
          h(VListItemGroup, {}, [
            getSlot(this, "body", { mobile: this.mobile }) ||
              h("p", { class: "text-center ui--text pt-6" }, [
                !this.loading && this.$vuetify.lang.t(this.emptyDataText),
                !!this.loading && this.$vuetify.lang.t(this.dataLoadingText),
              ]),
          ]),
        getSlot(this, "pagination", { mobile: this.mobile }) ||
          (this.paginationOptions &&
            h(VxDataPaginator, {
              class: "pagination ml-4",
              props: {
                mobile: this.mobile,
                loading: !!this.loading,
                totalItems: this.paginationOptions.totalItems,
                pageSizes: this.paginationOptions.pageSizes,
                itemsPerPage: this.paginationOptions.itemsPerPage,
                itemsPerpageStep: this.paginationOptions.itemsPerpageStep,
                page: this.paginationOptions.page,
              },
              on: this.$listeners,
            })),
      ]
    );
  },
};
</script>

<style lang="scss">
.vx-data-list.vx-data-list--mobile {
  width: 100%; // -> default same as v-data-table
}
</style>
