const Celebrate = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install(Vue, options) {
    Vue.prototype.$celebrate = function (count, type, color, element) {
      var particleList = [];
      for (var i = 0; i < count; i++) {
        var scale = Math.random();
        var endSize = scale * 55 + 20;
        var duration = Math.random() * 300 + 100;
        var particle = document.createElement("p");
        var rect = particle.getBoundingClientRect();
        particle.innerHTML = type;
        particle.className = "particle";
        particle.style.position = "absolute";
        particle.style.zIndex = 9999;
        particle.style.color = color;
        particle.style.transform = "rotate(" + Math.random() * 70 + "deg)";
        element.appendChild(particle);
        particle.animate([{ fontSize: "15px" }, { fontSize: endSize + "px" }], {
          duration: duration,
        });
        particle.animate([{ opacity: 1 }, { opacity: 0.5 }], {
          duration: duration,
        });
        particle.animate(
          [
            { top: rect.top + "px" },
            {
              top:
                rect.top +
                (Math.random() < 0.5 ? -1 : 1) * (Math.random() * 75) +
                "px",
            },
          ],
          {
            duration: duration,
          }
        );
        particle.animate(
          [
            { left: rect.left + "px" },
            {
              left:
                rect.left +
                (Math.random() < 0.5 ? -1 : 1) * (Math.random() * 75) +
                "px",
            },
          ],
          {
            duration: duration,
          }
        );
        particle.style.opacity = 0;
        particleList.push(particle);
      }
      setTimeout(function () {
        particleList.forEach((particle) => {
          element.removeChild(particle);
        });
      }, 1000);
    };
  },
};

export default Celebrate;
