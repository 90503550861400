<script>
import { SetupType } from "./constants";
import { getSlot, genSlots } from "../../../utils";
import { VExpansionPanel } from "vuetify/lib";
import RaiSetupHeader from "./RaiSetupHeader.vue";
import RaiSetupContent from "./RaiSetupContent.vue";

export default {
  name: "RaiSetup",
  inject: {
    setups: {
      default: {
        loading: false,
      },
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: SetupType.PENDING,
    },
    nextText: {
      type: String,
      default: "$vuetify.rai.setup.nextText",
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
    nextLoading: {
      type: Boolean,
      default: false,
    },
    nextEmphasis: {
      type: String,
      default: "text",
    },
    hideNext: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isLoading() {
      return this.loading || this.setups.loading;
    },
  },
  render(h) {
    return h(
      VExpansionPanel,
      {
        class: "rai-setup",
        props: {
          disabled: this.disabled,
          readonly: this.readonly,
        },
      },
      [
        h(
          RaiSetupHeader,
          {
            props: {
              type: this.type,
              loading: this.isLoading,
              errorMessage: this.errorMessage,
            },
          },
          [getSlot(this, "title") || this.title]
        ),
        h(
          RaiSetupContent,
          {
            props: {
              eager: this.eager,
              loading: this.isLoading,
              nextText: this.$vuetify.lang.t(this.nextText),
              nextDisabled: this.nextDisabled || this.disabled,
              nextLoading: this.nextLoading,
              nextEmphasis: this.nextEmphasis,
              hideNext: this.hideNext,
            },
            scopedSlots: this.$scopedSlots,
            on: this.$listeners,
          },
          [genSlots(this, ["default", "loading", "extension"])]
        ),
      ]
    );
  },
};
</script>
