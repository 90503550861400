import "regenerator-runtime/runtime"; // workaround. Should not be needed
import Vue from "vue";
import { HttpLink } from "apollo-link-http";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import VueApollo from "vue-apollo";
import { ApolloLink, split } from "apollo-link";
import { PusherLink } from "graphql-ruby-client";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-cache-inmemory";
import { toIdValue } from "apollo-utilities";
import resolvers from "@/apollo-client-resolvers";
import { onError } from "apollo-link-error";
import { BatchHttpLink } from "apollo-link-batch-http";
import { authHeaders } from "@/utils/http";
import store from "@/store";
import { EmployeeConfirmLink } from "./employeeConfirmLink";

// Create a link using `setContext` so we always have
// the current and valid auth headers.
// FIXME: this executes with every request
//        It would be better to only execute when auth changes
//        ideally we may not even setup Apollo until after auth.
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "X-Apollo-Middleware": true,
      ...authHeaders(),
    },
  };
});

// TODO - add store and employee to each request

// Create a link that handles network and graphql errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.error("graphQLErrors", graphQLErrors);
  }
  if (networkError) {
    console.error("networkError", networkError);
  }
});

const dataIdFromObject = (object) => `${object.__typename}:${object.id}`;

// shouldUseHttp: GraphQLContext -> Boolean
const shouldUseHttp = (context) => {
  const {
    kind,
    operation,
    name: { value } = { value: null },
  } = getMainDefinition(context.query);

  // Use Http link for Introspection
  if (value === "IntrospectionQuery") {
    console.log("IntrospectionQuery run");
    return true;
  }
  // Use Http link for uploads
  if (context.getContext().hasUpload) return true;
  // Use Http link for queries that request to not be batched
  if (context.getContext().skipBatching) return true;
  // Use Http link for subs. Use batch for everything else
  return kind === "OperationDefinition" && operation === "subscription";
};

const defaultOptions = (pusherClient, disableBatching = false) => {
  const httpLink = ApolloLink.split(
    (operation) => {
      return operation.getContext().hasUpload;
    },
    createUploadLink({
      uri: "/graphql",
    }),
    new HttpLink({
      uri: "/graphql",
    })
  );
  const batchLink = new BatchHttpLink({
    uri: "/graphql",
    batchInterval: 100,
  });
  const pusherLink = new PusherLink({ pusher: pusherClient });

  const employeeConfirmLink = new EmployeeConfirmLink({ store: store });

  const splitLink = disableBatching
    ? httpLink
    : split(shouldUseHttp, httpLink, batchLink);

  return {
    httpEndpoint: process.env.VUE_APP_GRAPHQL_HTTP,
    link: ApolloLink.from([
      errorLink,
      authLink,
      pusherLink,
      employeeConfirmLink,
      splitLink,
    ]),
    defaultHttpLink: false,
    cache: new InMemoryCache({
      cacheRedirects: {
        Query: {
          employee: (root, { id }) =>
            toIdValue(dataIdFromObject({ __typename: "Employee", id })),
          store: (root, { id }) =>
            toIdValue(dataIdFromObject({ __typename: "Store", id })),
          task: (root, { id }) =>
            toIdValue(dataIdFromObject({ __typename: "Task", id })),
        },
      },
    }),
    resolvers,
  };
};

export const createApolloProvider = (
  config = { pusherClient, disableBatching },
  options = {}
) => {
  //const { pusherClient, disableBatching } =
  const { apolloClient } = createApolloClient({
    ...defaultOptions(config.pusherClient, config.disableBatching),
    ...options,
    shouldBatch: true,
  });

  const handleSubscriptionsRetry = (
    component,
    operationName,
    operation,
    operationOptions
  ) => {
    if (
      operation === "subscription" &&
      component?.$apollo?.subscriptions[operationName]
    ) {
      const subscription = component.$apollo.subscriptions[operationName];
      if (
        subscription.retryConnectCount === undefined ||
        subscription.retryConnectCount === null
      ) {
        subscription.retryConnectCount =
          operationOptions.retryConnectCount || 0;
      }
      if (!subscription.retryConnectCount) {
        subscription.skip = true;
      } else {
        subscription.retryConnectCount = subscription.retryConnectCount - 1;
      }
    }
  };

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network",
      },
    },

    errorHandler(error, component, operationName, operation, operationOptions) {
      handleSubscriptionsRetry(
        component,
        operationName,
        operation,
        operationOptions
      );
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });
  return apolloProvider;
};

Vue.use(VueApollo);
