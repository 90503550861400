export default [
  {
    name: "auth.login",
    path: "login",
    component: () => import("@/views/Auth/LoginView"),
  },
  {
    name: "auth.forgot-password",
    path: "forgot-password",
    component: () => import("@/views/Auth/ForgotPasswordView.vue"),
  },
  {
    name: "auth.reset-password",
    path: "reset-password",
    component: () => import("@/views/Auth/ResetPasswordView.vue"),
  },
];
