<script>
import { VSelect, VBtn, VIcon } from "vuetify/lib";
import { props, computed } from "./VxDataPaginatorBase";

export default {
  name: "VxDataPaginatorDesktop",
  components: {
    VSelect,
    VBtn,
    VIcon,
  },
  inheritAttrs: false,
  props,
  computed: {
    ...computed,
    paginationText() {
      const paginationText = (start, end, totalItems) =>
        this.$vuetify.lang.t(
          "$vuetify.vx.dataPaginator.desktop.paginationText",
          start,
          end,
          totalItems
        );

      // Only one page
      if (this.totalItems < this.itemsPerPage) {
        return paginationText(1, this.totalItems, this.totalItems);
      }

      // First page
      if (this.page === 1) {
        return paginationText(1, this.itemsPerPage, this.totalItems);
      }

      // All other pages
      const start = this.itemsPerPage * (this.page - 1) + 1;
      let end = start + this.itemsPerPage - 1;

      if (end > this.totalItems) {
        end = this.totalItems;
      }

      return `${start}-${end} of ${this.totalItems}`;
    },
  },
};
</script>

<template>
  <div
    class="vx-data-paginator vx-data-paginator--desktop align-center text-body-2"
  >
    <div class="items-per-page-label mr-3">Rows per page:</div>
    <div class="items-per-page-selector mr-3">
      <!-- TODO: replace with VxSelect -->
      <v-select
        :value="itemsPerPage"
        :disabled="!!loading"
        :items="pageSizes"
        :hide-details="true"
        class="select-items-per-page"
        @input="$emit('update:itemsPerPage', $event)"
      >
      </v-select>
    </div>
    <div class="pagination-actions">
      {{ paginationText }}
      <v-btn :disabled="page <= 1 || !!loading" icon @click="localValue--">
        <v-icon>$left</v-icon>
      </v-btn>
      <v-btn
        :disabled="page >= pageCount || !!loading"
        icon
        @click="localValue++"
      >
        <!-- TODO - see about vuetifyt icons, how to include -->
        <v-icon>$right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.vx-data-paginator {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .items-per-page-label {
    flex: 0 1 auto;
  }

  .items-per-page-selector {
    flex: 0 100 60px;
  }

  .pagination-actions {
    flex: 0 1 auto;
  }

  .select-items-per-page {
    padding-top: 0;
    margin-top: 0;
  }
}

.vx-data-paginator::v-deep .v-select__slot {
  // TODO - check if I can get this from vuetify variable ?
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}
</style>
