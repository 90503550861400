import { baseGuard } from "./baseGuard";

export const redirectThroughSplash = (options = { active: () => true }) => {
  return baseGuard(options, (to, from, next) => {
    if (to.path !== "/") {
      next({ path: "/", query: { redirectTo: to.fullPath } });
    } else {
      next();
    }
  });
};
