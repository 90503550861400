<script>
import { props } from "./VxDataPaginatorBase";
import VxDataPaginatorMobile from "./VxDataPaginatorMobile.vue";
import VxDataPaginatorDesktop from "./VxDataPaginatorDesktop.vue";
import { defineResponsiveComponent } from "../../../utils/component";

export default defineResponsiveComponent({
  name: "VxDataPaginator",
  desktop: VxDataPaginatorDesktop,
  mobile: VxDataPaginatorMobile,
  props,
});
</script>
