<script>
// TODO: this can be aliased
import { VChip } from "vuetify/lib";
import { genSlots } from "../../../utils";

export default {
  name: "VxChip",
  extends: VChip,

  render(h) {
    return h(
      VChip,
      {
        props: this.$props,
        attrs: this.$attrs,
        scopedSlots: this.$scopedSlots,
        on: this.$listeners,
      },
      genSlots(this, ["default"])
    );
  },
};
</script>
