<script>
import { VCheckbox } from "vuetify/lib";
import { useModelValue, useValidationProvider } from "../../../mixins";

export default {
  name: "VxCheckbox",
  components: {
    VCheckbox,
  },
  mixins: [
    useModelValue({ event: "change", prop: "inputValue" }),
    useValidationProvider(),
  ],
  inheritAttrs: false,
  props: {
    inputValue: {
      type: [Boolean, Array],
      default: null,
    },
  },
  methods: {
    /* istanbul ignore next */
    validator() {
      return this.$refs.provider;
    },
  },
};
</script>

<template>
  <ValidationProvider
    ref="provider"
    :vid="name"
    :name="label"
    :rules="rules"
    :mode="mode"
    slim
  >
    <template v-slot="{ errors }">
      <v-checkbox
        ref="checkbox"
        v-model="modelValue"
        :name="name"
        :label="label"
        :error-messages="errors"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template v-slot:default>
          <slot></slot>
        </template>
        <template v-slot:append>
          <slot name="append"></slot>
        </template>
        <template v-slot:label>
          <slot name="label"></slot>
        </template>
        <template v-slot:prepend>
          <slot name="prepend"></slot>
        </template>
      </v-checkbox>
    </template>
  </ValidationProvider>
</template>
