/**
 * Clean phone number value
 * @param {string} value - phone number to clean
 * @returns
 */
export function cleanPhoneNumber(value) {
  let formattedNumber = value?.trim() || "";

  if (formattedNumber.indexOf("+1") === 0) {
    // remove country code if on first place
    formattedNumber = formattedNumber.substring(2);
  }

  // Remove anything that is not a digit
  formattedNumber = formattedNumber.replace(/[^\d]+/gi, "");

  return formattedNumber ? formattedNumber : undefined;
}

/**
 * Format phone number value
 * @param {string} value - phone number to format
 * @returns
 */
export function formatPhoneNumber(value) {
  const cleanedNumber = cleanPhoneNumber(value);

  if (!cleanedNumber) {
    return undefined;
  }

  // Check if there is area code and one more digit after it
  const areaCodeRegex = /^(\d{3})(\d+)/;
  const areaCodeMatch = cleanedNumber.match(areaCodeRegex);

  if (!areaCodeMatch) {
    // If the number doesn't have area code, just return original string
    return cleanedNumber;
  }
  const [, areaCode, restOfNumber] = areaCodeMatch;

  /* istanbul ignore if  */
  if (!areaCode) {
    // If the number doesn't have area code, just return original string
    return cleanedNumber;
  }

  // If the number has area code check for central office code and one more digit after it
  const centralOfficeCodeRegex = /^(\d{3})(\d+)/;
  const centralOfficeMatch = restOfNumber.match(centralOfficeCodeRegex);

  if (!centralOfficeMatch) {
    // If the number does not have central office code, return area code + the reminder of number
    return `(${areaCode}) ${restOfNumber}`;
  }

  const [, centralOfficeCode, subscriberNum] = centralOfficeMatch;
  return `(${areaCode}) ${centralOfficeCode}-${subscriberNum}`;
}
