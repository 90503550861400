export const props = {
  paginationOptions: {
    type: Object,
    default: () => undefined,
  },
  sortOptions: {
    type: Object,
    default: () => undefined,
  },
  loading: {
    type: [Boolean, Number],
    default: false,
  },
  emptyDataText: {
    type: String,
    default: "$vuetify.vx.dataList.emptyDataText",
  },
  dataLoadingText: {
    type: String,
    default: "$vuetify.vx.dataList.dataLoadingText",
  },
  headers: {
    type: Array,
    default: () => [],
  },
  mobile: {
    type: Boolean,
    default: undefined,
  },
  dense: {
    type: Boolean,
    default: false,
  },
  isList: {
    type: Boolean,
    default: false,
  },
};
