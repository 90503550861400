import axios from "axios";
import { find } from "lodash";
import interceptorSetup from "@/utils/interceptor";

const $http = axios.create({
  baseURL: "/api/vue",
});
interceptorSetup($http);

const state = () => ({
  id: false,
  email: false,
  default_store_id: false,
  account_id: false,
  full_name: false,
  role: false,
  stores: [{}],
  employee: false,
});

const getters = {
  getUser(state) {
    return {
      id: state.id,
      email: state.email,
      default_store_id: state.default_store_id,
      account_id: state.account_id,
      full_name: state.full_name,
      role: state.role,
      stores: state.stores,
    };
  },
  userEmployee: (state) => state.employee,
  currentUserHasEmployee: (state) => !!state.employee,
};

const mutations = {
  setUser(state, user) {
    if (user.email) state.email = user.email;
    if (user.id) state.id = user.id;
    if (user.default_store_id) state.default_store_id = user.default_store_id;
    if (user.account_id) state.account_id = user.account_id;
    if (user.full_name) state.full_name = user.full_name;
    if (user.role) state.role = user.role;
    if (user.stores) state.stores = user.stores;
    if (user.employee) state.employee = user.employee;
  },
  clearUser(state) {
    state.id = null;
    state.email = null;
    state.default_store_id = null;
    state.account_id = null;
    state.full_name = null;
    state.role = null;
    state.stores = [];
    state.employee = null;
  },
};

const actions = {
  loadUser({ commit, rootGetters }, user) {
    commit("setUser", user);
    commit("stores/setAvailableStores", user.stores, { root: true });
    commit("stores/setSelectedStore", getDefaultStore(user), { root: true });
  },
  fetchUser({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      $http
        .get("user", {
          params: rootGetters.getAuth,
          headers: headers(rootGetters.authPayload),
        })
        .then((response) => {
          const user = response.data;
          commit("setUser", user);
          commit("stores/setAvailableStores", user.stores, { root: true });
          commit("stores/setSelectedStore", getDefaultStore(user), {
            root: true,
          });
          resolve(true);
        })
        .catch((error) => {
          commit("auth/clearAuth", null, { root: true });
          reject(error);
        });
    });
  },
};

// Private
const headers = (authParams) => {
  return {
    "X-User-Email": authParams.email,
    "X-User-Token": authParams.token,
  };
};
const getDefaultStore = (user) => {
  const defaultStoreId = user.default_store_id;
  const defaultStore = find(user.stores, { id: defaultStoreId });
  return defaultStore;
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
