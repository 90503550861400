import { cloneDeep, each } from "lodash";
import { parseFilter } from "@/utils/filter";

function getRoutePropsMapFunction(
  commonPropsMapping,
  existingPropsMapping,
  options
) {
  let filterMapFunction = (route) => ({});
  let paramsMapFunction = (route) => ({});
  let storeMapFunction = (route) => ({});
  let commonPropsMapFunction = (route) => ({});

  if (options.applyFilter) {
    filterMapFunction = (route) => {
      return {
        filter: route.query.filter,
        parsedFilter: parseFilter(route.query.filter),
      };
    };
  }

  if (options.applyParams) {
    paramsMapFunction = (route) => ({
      ...route.params,
    });
  }

  if (options.applyStore) {
    storeMapFunction = (route) => ({ storeId: route.params.storeId });
  }

  if (!!commonPropsMapping) {
    commonPropsMapFunction = (route) => {
      let mapping = commonPropsMapping(route);

      if (options.prefix && mapping.raiLinkTo) {
        Object.keys(mapping.raiLinkTo).forEach((key) => {
          mapping.raiLinkTo[
            key
          ] = `${options.prefix}.${mapping.raiLinkTo[key]}`;
        });
      }

      return {
        ...mapping,
      };
    };
  }

  // TODO - see if this will map the function or the value imediatelly,
  // because this should return values base on route sent in

  // In the end, override with existing props
  return (route) => ({
    ...filterMapFunction(route),
    ...paramsMapFunction(route),
    ...storeMapFunction(route),
    ...commonPropsMapFunction(route),
    ...existingPropsMapping(route),
  });
}

function buildRouteRecursive(route, commonPropsMapping, options) {
  if (!!route.component) {
    let existingPropsMapping = (route) => {
      return {};
    };

    if (options.prefix) {
      if (route.name) {
        route.name = `${options.prefix}.${route.name}`;
      }
    }

    if (!route.props) {
      route.props = (route) => {};
    }

    if (!!route.props) {
      // If something already defined, first pick that
      existingPropsMapping = route.props;
    }

    // Then override the function
    route.props = getRoutePropsMapFunction(
      commonPropsMapping,
      existingPropsMapping,
      options
    );
  }

  each(route.components, (component, slotName) => {
    let existingPropsMapping = (route) => {
      return {};
    };

    if (!route.props) {
      route.props = {};
    }

    if (!!route.props[slotName]) {
      // If something already defined, first pick that
      existingPropsMapping = route.props[slotName];
    }

    // Then override the function
    route.props[slotName] = getRoutePropsMapFunction(
      commonPropsMapping,
      existingPropsMapping,
      options
    );
  });

  // And build routes for all children
  each(route.children, (childRoute) =>
    buildRouteRecursive(childRoute, commonPropsMapping, options)
  );
}

const defaultRouteOptions = {
  applyFilter: true,
  applyParams: true,
  applyStore: true,
};

/**
 *
 * @param {String} baseRoutePath Base path of the route
 * @param {Object} routeDefinition Definition of route and child routes
 * @param {Function} commonPropsMapping Mapping of common props for the route and child routes
 * @param {Object} options applyFilter=true - set fitler as prop. applyParams=true - sets params as props. applyStore=true - sets storeId as prop
 * @returns Built route with applied props for filter, store and params in route - depending on options.
 */
export function buildRoute(
  baseRoutePath,
  routeDefinition,
  commonPropsMapping = (route) => ({}),
  options = {}
) {
  const route = cloneDeep(routeDefinition);

  const parsedOptions = Object.assign({}, defaultRouteOptions, options);

  route.path = baseRoutePath;

  buildRouteRecursive(route, commonPropsMapping, parsedOptions);

  return [route];
}
