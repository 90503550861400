import VxGlobalTooltip from "../../components/vx/VxTooltip/VxGlobalTooltip.vue";

/**
 * Renders tooltip in DOM on demand
 * @param {import("vue").VueConstructor} Vue - Vue constructor
 * @param {{ vuetify: import("vuetify/lib").default }} args - arguments provided by plugin
 * @param {{ message: string | JSX.Element }} options - for rendering tooltip
 * @returns {VueConstructor}
 */
let instance = null;

export function renderTooltip(Vue, args) {
  if (instance) {
    return instance;
  }
  /** @type {import("vue").default} */
  instance = new Vue({
    vuetify: args.vuetify,
    data: () => ({ tooltip: false, message: null, propsData: {} }),
    methods: {
      show(options) {
        const { message, ...propsData } = options;
        this.message = message;
        this.propsData = propsData;
        this.tooltip = true;
      },
      hide() {
        this.tooltip = false;
      },
    },
    render(h) {
      return h(
        VxGlobalTooltip,
        {
          props: {
            value: this.tooltip,
            ...this.propsData,
          },
          on: {
            show: this.show,
            hide: this.hide,
          },
        },
        this.message
      );
    },
  });

  instance.$mount(); // -> This will attach to the root v-app component by default.
  return instance;
}
