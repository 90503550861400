/**
 * Validates phone number.
 * Implemented from here:
 * https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
 * section: Eliminate invalid phone numbers
 */
const fullValidRegex = /^([2-9][0-8][0-9])([2-9][0-9]{2})([0-9]{4})$/;
// Regex must be one line
// eslint-disable-next-line vue/max-len
const partialValidRegex =
  /^([2-9]|[2-9][0-8]|[2-9][0-8][0-9]|([2-9][0-8][0-9])[2-9]|([2-9][0-8][0-9])[2-9][0-9]|([2-9][0-8][0-9])[2-9][0-9]{2}|([2-9][0-8][0-9])([2-9][0-9]{2})[0-9]{1,4})$/;

/**
 *
 * @param {} phoneNumber Cleaned phone number (just digits)
 * @returns true if the whole number is a valid phone number. It means that it needs to have 10 digits and conform to the standard
 * written on this link https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
 * section: Eliminate invalid phone numbers
 */
function phoneNumberValidFull(phoneNumber) {
  return fullValidRegex.test(phoneNumber);
  //return /^\d{10}$/.test(phoneNumber);
}

/**
 *
 * @param {} phoneNumber Cleaned phone number (just digits)
 * @returns true if any part of number is a valid phone number.
 * for ex. 2092139485 is a valid number. 2, 20, 209, 2092, ... up to full number 2092139485 - are all partially valid.
 */
function phoneNumberValidPartial(phoneNumber) {
  // Regex that check if any substring is valid number.
  // for ex. 2092139485 is a valid number.

  if (!phoneNumber && phoneNumber != "0") {
    return true;
  }

  return partialValidRegex.test(phoneNumber);
}

export { phoneNumberValidFull, phoneNumberValidPartial };
