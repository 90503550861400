import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

// Modules
import auth from "./modules/auth";
import bizdate from "./modules/bizdate";
import buys from "./modules/buys";
import coupons from "./modules/coupons";
import notice from "./modules/notice";
import snackbar from "./modules/snackbar";
import sockets from "./modules/sockets";
import ui from "./modules/ui";
import user from "./modules/user";
import teamMode from "./modules/teamMode";

import rootActions from "@/store/actions.js";
import rootMutations from "@/store/mutations.js";

Vue.use(Vuex);

// only persist local settings and not data
// TODO: remove stores from state completely
const deviceState = new createPersistedState({
  key: "x-rai-device",
  paths: [
    "auth.deviceId",
    "auth.email",
    "auth.token",
    "ui.theme",
    "ui.idle",
    "ui.keepAlive",
    "ui.printer",
  ],
  /*
  rehydrated: (store) => {
    console.log("rehydrating state");
    //store.state.commit("rehydrated", false);
    // refresh authentication when rehydrating
    store.dispatch("auth/refresh");
    // There could be a race condition when setting up the state
    // If so, we should add a "refreshed" prop to state and set only when rehydrated
    //store.state.commit("rehydrated", true);
    console.log("rehydrated");
  },
  */
  fetchBeforeUse: true,
});

export default new Vuex.Store({
  modules: {
    auth,
    bizdate,
    buys,
    coupons,
    notice,
    snackbar,
    sockets,
    ui,
    user,
    teamMode,
  },
  strict: process.env.NODE_ENV !== "production",
  state: {
    raiDomain: process.env.VUE_APP_RAI_DOMAIN,
    unreadMessagesExist: false,
    rehydrated: false,
  },
  getters: {
    getRaiAppUrl: (state) => (path) => {
      return `${state.raiDomain}${path}`;
    },
    unreadMessagesExist: (state) => state.unreadMessagesExist,
  },
  mutations: {
    ...rootMutations,
  },
  actions: rootActions,
  plugins: [deviceState],
});
