<script>
import { SetupType } from "./constants";
import { getSlot } from "../../../utils";
import RaiSetupHeaderIcon from "./RaiSetupHeaderIcon.vue";
import {
  VSkeletonLoader,
  VExpansionPanelHeader,
  VFadeTransition,
} from "vuetify/lib";

export default {
  name: "RaiSetupHeader",
  inheritAttrs: false,
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: SetupType.PENDING,
    },
  },
  computed: {
    classes() {
      return {
        "rai-setup-header": true,
        "rai-setup-header--loading": this.loading,
      };
    },
  },
  render(h) {
    return h(
      VExpansionPanelHeader,
      { class: this.classes, props: this.$attrs },
      [
        h(RaiSetupHeaderIcon, {
          props: { loading: this.loading, type: this.type },
        }),
        h(VFadeTransition, [
          h(
            VSkeletonLoader,
            { props: { type: "heading", width: "65%", loading: this.loading } },
            [
              h("div", { class: "rai-setup-header__content" }, [
                h("div", { class: "text-subtitle-2" }, getSlot(this)),
                this.errorMessage &&
                  h(
                    "div",
                    { class: "text-caption grey--text mt-1" },
                    this.errorMessage
                  ),
              ]),
            ]
          ),
        ]),
      ]
    );
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header--active.rai-setup-header {
  border-bottom: 1px solid #ccc;
}
</style>
