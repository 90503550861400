<script>
import { VxAppBar } from "../../components";
import { genSlots, getSlot } from "../../utils";
import SetupLayoutHeader from "./SetupLayoutHeader.vue";
import SetupLayoutContent from "./SetupLayoutContent.vue";
import { VContainer, VAppBarNavIcon, VIcon, VMain } from "vuetify/lib";

export default {
  name: "SetupLayout",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    toolbarTitle: {
      type: String,
      default: "",
    },
    app: {
      type: Boolean,
      default: true,
    },
    hideAppBar: {
      type: Boolean,
      default: false,
    },
  },
  render(h) {
    const Container = h(VContainer, [
      h(
        SetupLayoutHeader,
        { props: this.$props },
        genSlots(this, ["description"])
      ),
      h(SetupLayoutContent, getSlot(this)),
    ]);

    return h("div", { class: "setup-layout" }, [
      !this.hideAppBar &&
        (getSlot(this, "app-bar") ||
          h(VxAppBar, { props: { title: this.toolbarTitle } }, [
            h("template", { slot: "nav-icon" }, [
              h(VAppBarNavIcon, { on: { click: () => this.$emit("back") } }, [
                h(VIcon, "$back"),
              ]),
            ]),
            h("template", { slot: "title" }, getSlot(this, "app-bar-title")),
            h("template", { slot: "left" }, getSlot(this, "app-bar-left")),
            h("template", { slot: "right" }, getSlot(this, "app-bar-right")),
          ])),
      this.app ? h(VMain, [Container]) : Container,
    ]);
  },
};
</script>
