import i18n from "@/plugins/vue-i18n";

const settingsRoute = {
  components: {
    default: () => import("@/views/StoreSettingsView"),
  },
  meta: {
    requiresAuth: true,
    requiresManager: true,
  },
  children: [
    {
      path: "",
      name: "settings.home",
      component: () => import("@/views/Settings"),
      meta: {
        title: "Settings",
        description: "Quick access to all setups from one place",
      },
    },
    {
      path: "store",
      name: "settings.store",
      component: () => import("@/views/Settings/StoreView"),
      meta: {
        title: i18n.t("settings.store.title"),
        description: i18n.t("settings.store.description"),
        toolbarTitle: i18n.t("settings.store.toolbarTitle"),
      },
      children: [
        {
          path: "new",
          name: "settings.store.new",
          component: () =>
            import("@/views/Settings/StoreView/StoreDialogView.vue"),
        },
      ],
    },
    {
      path: "device",
      name: "settings.device",
      component: () => import("@/views/Settings/DeviceView"),
      meta: { title: "Device" },
    },
    {
      path: "automation",
      name: "settings.automation",
      component: () => import("@/views/Settings/AutomationView"),
      meta: {
        title: i18n.t("settings.automation.title"),
        description: i18n.t("settings.automation.description"),
        toolbarTitle: i18n.t("settings.automation.toolbarTitle"),
      },
    },
    {
      path: "automation/when-i-work",
      name: "settings.automation.when-i-work",
      component: () => import("@/views/Settings/AutomationView/WhenIWorkView"),
      meta: {
        title: i18n.t("settings.automation.whenIWork.title"),
        description: i18n.t("settings.automation.whenIWork.description"),
        toolbarTitle: i18n.t("settings.automation.whenIWork.toolbarTitle"),
      },
    },
    {
      path: "automation/homebase",
      name: "settings.automation.homebase",
      component: () => import("@/views/Settings/AutomationView/HomebaseView"),
      meta: {
        title: i18n.t("settings.automation.homebase.title"),
        description: i18n.t("settings.automation.homebase.description"),
        toolbarTitle: i18n.t("settings.automation.homebase.toolbarTitle"),
      },
    },
    {
      path: "automation/fivestars",
      name: "settings.automation.fivestars",
      component: () => import("@/views/Settings/AutomationView/FivestarsView"),
      meta: {
        title: i18n.t("settings.automation.fivestars.title"),
        description: i18n.t("settings.automation.fivestars.description"),
        toolbarTitle: i18n.t("settings.automation.fivestars.toolbarTitle"),
      },
    },
    {
      path: "automation/nightly-reports",
      name: "settings.automation.nightly-reports",
      component: () =>
        import("@/views/Settings/AutomationView/NightlyReportsView"),
      meta: {
        title: i18n.t("settings.automation.nightlyReports.title"),
        description: i18n.t("settings.automation.nightlyReports.description"),
        toolbarTitle: i18n.t("settings.automation.nightlyReports.toolbarTitle"),
      },
    },
    {
      path: "automation/constant-contact",
      name: "settings.automation.constant-contact",
      component: () =>
        import("@/views/Settings/AutomationView/ConstantContactView"),
      meta: {
        title: i18n.t("settings.automation.constantContact.title"),
        description: i18n.t("settings.automation.constantContact.description"),
        toolbarTitle: i18n.t(
          "settings.automation.constantContact.toolbarTitle"
        ),
      },
    },
    {
      path: "automation/quickbooks",
      name: "settings.automation.quickbooks",
      component: () => import("@/views/Settings/AutomationView/QuickbooksView"),
      meta: {
        title: i18n.t("settings.automation.quickbooks.title"),
        description: i18n.t("settings.automation.quickbooks.description"),
        toolbarTitle: i18n.t("settings.automation.quickbooks.toolbarTitle"),
      },
    },
    {
      path: "change-password",
      name: "settings.change-password",
      component: () => import("@/views/ChangePasswordView"),
      meta: {
        requiresNotPosUser: true,
      },
    },
  ],
};

export { settingsRoute };
export default settingsRoute;
