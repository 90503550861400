<script>
import VxTooltip from "./VxTooltip.vue";
import { useModelValue } from "../../../mixins";
import { getSlot } from "../../../utils";

export default {
  name: "VxGlobalTooltip",
  mixins: [useModelValue()],
  inheritAttrs: false,
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    positionX: {
      type: Number,
      default: 0,
    },
    positionY: {
      type: Number,
      default: 0,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: "",
    },
  },
  render(h) {
    return h(
      VxTooltip,
      {
        props: {
          ...this.$props,
          value: this.modelValue,
        },
        attrs: this.$attrs,
        on: this.$listeners,
      },
      getSlot(this, "default", null)
    );
  },
};
</script>
