import { SORT_ORDER } from "./SortOrder";

export const props = {
  sortOptions: {
    type: Object,
    default: () => ({
      sortBy: "",
      sortOrder: SORT_ORDER.ASC,
    }),
  },
  headers: {
    type: Array,
    default: () => [],
  },
};
