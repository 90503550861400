<script>
import { props } from "./VxDataListHeaderBase";

export default {
  name: "VxDataListHeaderMobile",
  props,
};
</script>

<template>
  <div>
    <slot name="extension" />
  </div>
</template>

<style scoped></style>
