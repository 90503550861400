import { Notice } from "./base";

class DRSNotConnectedNotice extends Notice {
  constructor() {
    super({
      id: "drsNotConnected",
      text: "ResaleAI is not connected to DRS!",
      color: "error",
      priority: 8,
    });
  }
}

export default new DRSNotConnectedNotice();
