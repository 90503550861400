import store from "@/store";
import { baseGuard } from "./baseGuard";

export const setActiveStore = (options = { active: () => true }) => {
  return baseGuard(options, (to, from, next) => {
    try {
      const hasToStoreId = !!to.params.storeId;
      const storeIdIsChanged =
        store.getters["auth/activeStoreId"]?.toString() != to.params.storeId;

      if (hasToStoreId && storeIdIsChanged) {
        store.commit("auth/setActiveStore", to.params.storeId);
      }
    } catch (e) {
      // If anything fails here, better to just write ti to console and continue, than to break routing
      console.log("Error while setting active store", e);
    }

    next();
  });
};
