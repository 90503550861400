const state = {
  buys: [],
  checkinRequests: [],
};

const getters = {
  buysOpen(state) {
    return (state.buys || []).filter((buy) => buy.status === "open");
  },
  buysComplete(state) {
    return (state.buys || []).filter((buy) => buy.status === "complete");
  },
  buysPending(state) {
    return state.checkinRequests;
  },
};

const mutations = {
  updateBuys(state, buyUpdated) {
    state.buys = buyUpdated;
  },
  updateCheckinRequests(state, checkinRequestUpdated) {
    state.checkinRequests = checkinRequestUpdated;
  },
};

const actions = {
  updateBuys({ commit }, buyUpdated) {
    commit("updateBuys", buyUpdated);
  },
  updateCheckinRequests({ commit }, checkinRequestUpdated) {
    commit("updateCheckinRequests", checkinRequestUpdated);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
