<script>
import RaiSetup from "./RaiSetup.vue";
import VxForm from "../../vx/VxForm/VxForm.vue";
import { genSlots, getSlot, hasListener } from "../../../utils";

export default {
  name: "RaiSetupForm",
  inheritAttrs: false,
  data: () => ({
    submitting: false,
  }),
  computed: {
    listeners() {
      return { ...this.$listeners, next: this.onNext };
    },
    props() {
      return { ...this.$attrs, nextLoading: this.isNextLoading };
    },
    isNextLoading() {
      return this.$attrs.nextLoading || this.submitting;
    },
  },
  methods: {
    onNext() {
      this.$refs.form.submit();
    },
    reset() {
      this.$refs.form.reset();
    },
    submit(formContext) {
      this.submitting = true;

      const resolve = () => {
        this.submitting = false;
        formContext.resolve();
        this.$emit("next");
      };

      const setErrors = (errors) => {
        this.submitting = false;
        formContext.setErrors(errors);
      };

      if (!hasListener(this, "submit")) resolve();
      this.$emit("submit", { ...formContext, resolve, setErrors });
    },
  },
  render(h) {
    const scopedSlots = {
      action: (props) => {
        return getSlot(this, "action", {
          ...props,
          submitting: this.submitting,
        });
      },
    };

    return h(
      RaiSetup,
      {
        props: this.props,
        on: this.listeners,
        scopedSlots: scopedSlots,
      },
      [
        ...genSlots(this, ["title", "loading", "extension"]),
        h(VxForm, {
          ref: "form",
          on: { submit: this.submit },
          scopedSlots: {
            default: (formProps) => getSlot(this, "default", formProps),
          },
        }),
      ]
    );
  },
};
</script>
