<script>
import { VMenu, VDatePicker } from "vuetify/lib";
import VxTextField from "../VxTextField/VxTextField.vue";
import { useModelValue, useValidationProvider } from "../../../mixins";
import { DatePart, toISOString } from "../../../utils/date";
import { formatDate } from "../../../formatting/date";

export default {
  name: "VxDatePicker",
  mixins: [useModelValue(), useValidationProvider()],
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, Date],
      default: "",
    },
    type: {
      type: String,
      default: "date",
    },
    format: {
      type: String,
      default: "MMM d, yyyy",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: "",
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number, Date],
      default: "",
    },
    max: {
      type: [String, Number, Date],
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    modelValue: {
      get() {
        return this.toISOString(this.value);
      },
      set(value) {
        this.menu = false;
        this.$emit("input", value);
      },
    },
    formattedDate() {
      return formatDate(this.modelValue, this.format);
    },
    minDate() {
      return this.toISOString(this.min);
    },
    maxDate() {
      return this.toISOString(this.max);
    },
  },
  watch: {
    formattedDate: {
      handler(value) {
        this.$emit("change", { value: this.value, formatted: value });
      },
    },
  },
  methods: {
    focus() {
      this.$refs.textField.focus();
    },
    validator() {
      return this.$refs.textField.validator();
    },
    onClickClear() {
      this.modelValue = null;
      this.$emit("click:clear");
    },
    toISOString(value) {
      const isoString = toISOString(value, DatePart.DATE);
      return this.type === "month" ? isoString.substring(0, 7) : isoString;
    },
  },
  render(h) {
    const scopedSlots = {
      activator: ({ on, attrs }) =>
        h(VxTextField, {
          ref: "textField",
          class: this.inputClass,
          props: {
            value: this.formattedDate,
            name: this.name,
            mode: this.mode,
            label: this.label,
            rules: this.rules,
          },
          attrs: {
            dateValue: this.modelValue,
            readonly: true,
            hint: this.hint,
            appendIcon: "$calendar",
            disabled: this.disabled,
            persistentHint: this.persistentHint,
            clearable: !this.disabled && this.clearable,
            ...this.$attrs,
            ...attrs,
          },
          on: {
            "click:clear": this.onClickClear,
            ...on,
          },
        }),
    };

    return h(
      VMenu,
      {
        props: {
          value: this.menu,
          closeOnContentClick: false,
          transition: "slide-y-transition",
          minWidth: "auto",
          nudgeTop: -10,
          offsetY: true,
        },
        on: {
          input: (value) => {
            this.menu = value;
          },
        },
        scopedSlots: scopedSlots,
      },
      [
        h(VDatePicker, {
          props: {
            value: this.modelValue,
            noTitle: true,
            scrollable: true,
            type: this.type,
            min: this.minDate,
            max: this.maxDate,
          },
          on: {
            input: (value) => {
              this.modelValue = value;
            },
          },
          attrs: this.$attrs,
        }),
      ]
    );
  },
};
</script>
