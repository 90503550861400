import Vue from "vue";
import { ValidationProvider } from "vee-validate";

export function useValidationProvider() {
  return Vue.extend({
    components: { ValidationProvider },
    props: {
      name: {
        type: String,
        default: "",
      },
      label: {
        type: String,
        default: "",
      },
      mode: {
        type: String,
        default: "aggressive",
        validator(value) {
          return ["aggressive", "passive", "eager", "lazy"].includes(value);
        },
      },
      rules: {
        type: [Object, String],
        default: "",
      },
    },
  });
}
