<script>
import VxDataListHeader from "./VxDataListHeader";
import VxDataPaginator from "./VxDataPaginator";
import { VSimpleTable, VProgressLinear } from "vuetify/lib";
import { props } from "./VxDataListBase";
import { getSlot, hasSlot } from "../../../utils";

export default {
  name: "VxDataListDesktop",
  props,
  render(h) {
    return h("div", { attrs: this.$attrs }, [
      !!this.loading &&
        h(VProgressLinear, {
          props: { height: "4", indeterminate: true },
          class: { "ma-0": true, visible: !!this.loading },
        }),
      (hasSlot(this, "headerExtension") ||
        hasSlot(this, "pagination") ||
        this.paginationOptions) &&
        h("div", { class: "d-flex mb-2" }, [
          getSlot(this, "headerExtension", { mobile: this.mobile }),
          getSlot(this, "pagination", { mobile: this.mobile }) ||
            (this.paginationOptions &&
              h(VxDataPaginator, {
                class: "pagination ml-auto",
                props: {
                  mobile: this.mobile,
                  loading: !!this.loading,
                  totalItems: this.paginationOptions.totalItems,
                  pageSizes: this.paginationOptions.pageSizes,
                  itemsPerPage: this.paginationOptions.itemsPerPage,
                  page: this.paginationOptions.page,
                },
                on: this.$listeners,
              })),
        ]),
      h(
        VSimpleTable,
        {
          class: {
            "vx-data-list": true,
            "vx-data-list--desktop": true,
            "vx-data-list--table": true,
            "no-border": this.isList,
          },
          props: { dense: this.dense },
        },
        [
          getSlot(this, "header", { mobile: this.mobile }) ||
            (!!this.headers.length &&
              h(VxDataListHeader, {
                props: {
                  mobile: this.mobile,
                  headers: this.headers,
                  sortOptions: this.sortOptions,
                },
                on: this.$listeners,
              })),
          getSlot(this, "groups", { mobile: this.mobile }) ||
            h("tbody", {}, [
              getSlot(this, "body", { mobile: this.mobile }) ||
                h("tr", { class: "empty-loading-data-row" }, [
                  h(
                    "td",
                    {
                      class: "text-center ui--text pt-6",
                      attrs: { colspan: this.headers.length },
                    },
                    [
                      !this.loading && this.$vuetify.lang.t(this.emptyDataText),
                      !!this.loading &&
                        this.$vuetify.lang.t(this.dataLoadingText),
                    ]
                  ),
                ]),
            ]),
          getSlot(this, "footer", { mobile: this.mobile }),
        ]
      ),
    ]);
  },
};
</script>

<style scoped lang="scss">
.v-progress-linear {
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.vx-data-list::v-deep table {
  table-layout: fixed;
  width: 100%;
}

.vx-data-list.no-border::v-deep {
  th,
  td {
    border: none !important;
  }
}
</style>
