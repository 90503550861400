<template>
  <VxSelect
    v-model="modelValue"
    :class="classes"
    :items="items"
    v-bind="props"
    v-on="$listeners"
  >
    <template v-if="incremental" #prepend>
      <v-btn
        data-testid="decrement"
        text
        icon
        :disabled="decrementDisabled"
        @click="decrement"
      >
        <v-icon>$minus</v-icon>
      </v-btn>
    </template>
    <template v-if="incremental" #append-outer>
      <v-btn
        data-testid="increment"
        text
        icon
        :disabled="incrementDisabled"
        @click="increment"
      >
        <v-icon>$plus</v-icon>
      </v-btn>
    </template>
    <template #selection="{ item }">
      <span data-testid="selection" class="rai-quoted-time__selection">
        {{ item }}
      </span>
    </template>
  </VxSelect>
</template>

<script>
import { when } from "../../../utils";
import QuotedTime from "./QuotedTime.js";

import { useModelValue } from "../../../mixins";
import { VxSelect } from "../../vx";
import { VBtn, VIcon } from "vuetify/lib";

function createListHeaderItem(value) {
  return { header: value.toUpperCase() };
}

function resolveInitalValue(value, items) {
  const listItems = items.filter((x) => !x.header && !x.divider);
  const dateValue = value instanceof Date ? value : new Date(value);
  const hasDateValue = listItems.some(
    (x) => x.value.getTime() === dateValue.getTime()
  );
  return hasDateValue ? dateValue : listItems[0]?.value;
}

function removeHiddenSelectionInput() {
  const selector =
    '.rai-quoted-time .v-select__selections input[type="text"][readonly="readonly"]';
  document.querySelectorAll(selector).forEach((el) => el.remove());
}

export default {
  name: "RaiQuotedTime",
  components: { VxSelect, VBtn, VIcon },
  mixins: [useModelValue()],
  props: {
    value: {
      type: [Date, String],
      default: "",
    },
    label: {
      type: String,
      default: "Quoted time",
    },
    interval: {
      type: Number,
      default: 15,
      validator(value) {
        return value >= 1 && value <= 60;
      },
    },
    fromHour: {
      type: Number,
      default: 9,
      validator(value) {
        return value >= 0 && value <= 24;
      },
    },
    toHour: {
      type: Number,
      default: 22,
      validator(value) {
        return value >= 0 && value <= 24;
      },
    },
    today: {
      type: Boolean,
      default: true,
    },
    todayText: {
      type: String,
      default: "Today",
    },
    tomorrow: {
      type: Boolean,
      default: true,
    },
    tomorrowText: {
      type: String,
      default: "Tomorrow",
    },
    incremental: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    quotedTime: null,
  }),
  computed: {
    classes() {
      return {
        "rai-quoted-time": true,
        "rai-quoted-time--incremental": this.incremental,
      };
    },
    props() {
      const defaultProps = { ...this.$attrs, label: this.label };

      const incrementalProps = {
        label: undefined,
        dense: true,
        flat: true,
        solo: true,
        appendIcon: null,
      };

      return {
        ...defaultProps,
        ...(this.incremental && incrementalProps),
      };
    },
    items() {
      return [
        // => Today
        this.today && createListHeaderItem(this.todayText),
        ...when(this.today, this.quotedTime.forToday()),

        // => Tomorrow
        this.tomorrow && createListHeaderItem(this.tomorrowText),
        ...when(this.tomorrow, this.quotedTime.forTomorrow()),
      ].filter((value) => !!value);
    },
    selectedIndex() {
      return this.items.findIndex(
        (x) => x.value?.toString() === this.modelValue.toString()
      );
    },
    decrementDisabled() {
      // => Handle case where first item maybe unselectable header
      const startingIndex = this.items[0]?.header ? 1 : 0;
      return this.selectedIndex <= startingIndex;
    },
    incrementDisabled() {
      return this.selectedIndex === this.items.length - 1;
    },
    quotedTimeConfig() {
      return {
        interval: this.interval,
        fromHour: this.fromHour,
        toHour: this.toHour,
      };
    },
  },
  watch: {
    quotedTimeConfig: {
      deep: true,
      immediate: true,
      handler(config) {
        this.quotedTime = new QuotedTime(config);
        this.modelValue = resolveInitalValue(this.modelValue, this.items);
      },
    },
  },
  mounted() {
    if (this.incremental) {
      // => There is a hidden filler input that has to be removed in order for text to be in the middle
      removeHiddenSelectionInput();
    }
  },
  methods: {
    decrement() {
      if (this.selectedIndex > 0) {
        const { value } = this.items[this.selectedIndex - 1];
        this.modelValue = value;
        this.$emit("decrement", value);
      }
    },
    increment() {
      if (this.selectedIndex < this.items.length - 1) {
        const selected = this.selectedIndex === -1 ? 0 : this.selectedIndex;
        const { value } = this.items[selected + 1];
        this.modelValue = value;
        this.$emit("increment", value);
      }
    },
  },
};
</script>

<style lang="scss">
.rai-quoted-time.rai-quoted-time--incremental {
  .theme--light .v-input__slot {
    background-color: #f5f5f5 !important;
  }

  .v-input__prepend-outer,
  .v-input__append-outer {
    margin-top: 0 !important;
  }

  .rai-quoted-time__selection {
    flex: 1;
    text-align: center;
  }
}
</style>
