<script>
import { props } from "./VxDataListBase";
import VxDataListMobile from "./VxDataListMobile.vue";
import VxDataListDesktop from "./VxDataListDesktop.vue";
import { defineResponsiveComponent } from "../../../utils/component";

export default defineResponsiveComponent({
  name: "VxDataList",
  desktop: VxDataListDesktop,
  mobile: VxDataListMobile,
  props,
});
</script>
