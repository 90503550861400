import RaiAudio from "@/utils/rai-audio";

// sounds defined here will be pre-configured, but not loaded until they are first played
const sounds = [{ name: "beep", url: "/sounds/rai-notify.mp3", preload: true }];

// export a function so that raiPos can be passed in.
export function createSounds(raiPos) {
  const raiAudio = new RaiAudio(sounds, raiPos);
  return raiAudio;
}
