import { findDefinitionByName } from "../graphql";
import employeeFragments from "../graphql/employee/fragments.gql";

const Query = {
  employee: (_root, { id: employeeId }, { cache, getCacheKey }) => {
    // determine the cache id for the employee
    const id = getCacheKey({ __typename: "Employee", id: employeeId });

    // find the correct employee fragment
    const fragment = findDefinitionByName(
      employeeFragments,
      "EmployeeBasicInfo"
    );

    // if we have a fragment, read the fragment from the cache
    // and return it
    if (fragment) {
      const data = cache.readFragment({ id, fragment });
      return data;
    }
  },
};

export default {
  Query,
};
