<script>
import { VListItem } from "vuetify/lib";
import { props, methods } from "./VxDataListItemBase";

export default {
  name: "VxDataListItemMobile",
  components: {
    VListItem,
  },
  inheritAttrs: false,
  props,
  methods,
};
</script>

<template>
  <v-list-item
    v-bind="$attrs"
    :class="{ disabled: disabled }"
    :disabled="disabled"
    v-on="$listeners"
    @click="showItem"
  >
    <slot name="mobile"></slot>
  </v-list-item>
</template>

<style scoped lang="scss">
.v-list-item {
  cursor: pointer;

  &.empty-loading-data-row {
    cursor: unset;
  }

  &.disabled {
    cursor: unset;
    color: var(--v-ui-lighten1) !important;
  }

  &.disabled::v-deep * {
    color: var(--v-ui-lighten1) !important;
  }
}
</style>
