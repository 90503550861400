import { enumValidator } from "../../../utils";
import { DialogSize } from "./constants";

export const props = {
  title: {
    type: String,
    default: "",
  },
  toolbarColor: {
    type: String,
    default: "",
  },
  alignActionsRight: {
    type: Boolean,
    default: true,
  },
  vSize: {
    type: String,
    default: "auto",
  },
  hSize: {
    type: String,
    default: DialogSize.MEDIUM,
    validator: enumValidator(DialogSize),
  },
  errorToolbar: {
    type: Boolean,
    default: false,
  },
  noBack: {
    type: Boolean,
    default: false,
  },
  eager: {
    type: Boolean,
    default: false,
  },
};
