<script>
import { getSlot, hasSlot } from "../../../utils";
import { ValidationObserver } from "vee-validate";
import { VStepperContent, VBtn } from "vuetify/lib";

export default {
  name: "VxStepperContent",
  inject: ["getStepper", "stepPrevious", "stepNext"],
  props: {
    step: {
      type: Number,
      required: true,
    },
    previousText: {
      type: String,
      default: "$vuetify.vx.stepper.previousText",
    },
    nextText: {
      type: String,
      default: "$vuetify.vx.stepper.nextText",
    },
    beforeNext: {
      type: Function,
      default: null,
    },
    validate: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({ stepper: null }),
  computed: {
    stepIndex() {
      return this.stepper?.steps?.findIndex((x) => x.step === this.step);
    },
    actionSlotProps() {
      return {
        ...this.previousSlotProps,
        ...this.nextSlotProps,
      };
    },
    previousSlotProps() {
      return {
        previousText: this.previousText,
        previous: this.previous,
      };
    },
    nextSlotProps() {
      return {
        nextText: this.nextText,
        next: this.next,
      };
    },
    showPreviousButton() {
      return this.stepIndex > 0;
    },
  },
  mounted() {
    this.stepper = this.getStepper();
  },
  methods: {
    setErrors(errors) {
      /* istanbul ignore next */
      this.$refs.observer.setErrors(errors);
    },
    previous() {
      return new Promise((resolve) => {
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
          this.stepPrevious(this.step);
          resolve();
        });
      });
    },
    async next() {
      let moveToNext = true;

      if (moveToNext && this.validate) {
        moveToNext = await this.$refs.observer.validate();
      }

      if (moveToNext && typeof this.beforeNext === "function") {
        moveToNext = await this.beforeNext({
          setErrors: this.setErrors,
          step: this.step,
        });
      }

      return moveToNext && this.stepNext(this.step);
    },
  },
  render(h) {
    const PreviousButton =
      getSlot(this, "previous", this.previousSlotProps) ||
      h(
        VBtn,
        {
          attrs: {
            "data-testid": "previous",
            type: "button",
          },
          on: {
            click: this.previous,
          },
        },
        [this.$vuetify.lang.t(this.previousText)]
      );

    const NextButton =
      getSlot(this, "next", this.nextSlotProps) ||
      h(
        VBtn,
        {
          attrs: {
            "data-testid": "next",
            type: "button",
          },
          props: {
            color: "primary",
          },
          on: {
            click: this.next,
          },
        },
        [this.$vuetify.lang.t(this.nextText)]
      );

    const Actions = hasSlot(this, "actions")
      ? h(
          "div",
          {
            class: "vx-stepper-content__actions mt-5",
          },
          [getSlot(this, "actions", this.actionSlotProps)]
        )
      : h(
          "div",
          {
            class: "vx-stepper-content__actions mt-5",
          },
          [this.showPreviousButton && PreviousButton, NextButton]
        );

    return h(
      VStepperContent,
      {
        class: "vx-stepper-content",
        props: {
          step: this.step,
        },
      },
      [
        h(
          ValidationObserver,
          {
            ref: "observer",
            props: {
              slim: true,
            },
          },
          [
            (observerProps) =>
              h("div", [getSlot(this, "default", observerProps), Actions]),
          ]
        ),
      ]
    );
  },
};
</script>

<style lang="scss">
.vx-stepper-content__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
