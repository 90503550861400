<script>
import { VBtn } from "vuetify/lib";
import { props, computed } from "./VxDataPaginatorBase";

export default {
  name: "VxDataPaginatorMobile",
  components: {
    VBtn,
  },
  inheritAttrs: false,
  props,
  computed: {
    ...computed,
    totalLoadedCount() {
      return this.itemsPerPage * this.page;
    },
    isAllLoaded() {
      return this.totalLoadedCount >= this.totalItems;
    },
    paginationText() {
      const loaded =
        this.totalLoadedCount > this.totalItems
          ? this.totalItems
          : this.totalLoadedCount;

      return this.$vuetify.lang.t(
        "$vuetify.vx.dataPaginator.mobile.paginationText",
        loaded,
        this.totalItems
      );
    },
    showNextText() {
      return this.$vuetify.lang.t(
        "$vuetify.vx.dataPaginator.mobile.showNextText",
        this.itemsPerPageStep
      );
    },
  },
};
</script>

<template>
  <div class="text-center paginator-footer pa-4">
    <div class="paginator-total-loaded-info mb-4">
      {{ paginationText }}
    </div>
    <v-btn
      v-if="!isAllLoaded"
      :loading="!!loading"
      color="primary"
      @click="$emit('showMore')"
    >
      {{ showNextText }}
    </v-btn>
  </div>
</template>

<style scoped lang="scss">
.goto-bottom-button {
  margin-left: 55px;
  pointer-events: auto;
  z-index: 1;
}

.goto-top-button {
  margin-left: 0;
  pointer-events: auto;
  z-index: 1;

  .v-icon {
    transform: rotate(180deg);
  }
}
</style>
