<script>
import embed from "vega-embed";
import { merge } from "lodash";
import RaiTrend from "../RaiTrend/RaiTrend.vue";
import {
  VDivider,
  VRow,
  VSheet,
  VSkeletonLoader,
  VSpacer,
  VToolbar,
  VToolbarTitle,
} from "vuetify/lib";

import {
  convertToUnit,
  genRandom,
  getSlot,
  isNullOrEmpty,
} from "../../../utils";

export default {
  name: "RaiGraph",
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: "",
    },
    spec: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    trend: {
      type: Number,
      default: null,
    },
    elevation: {
      type: [String, Number],
      default: 1,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    chartHeight: {
      type: [String, Number],
      default: 300,
    },
    chartWidth: {
      type: [String, Number],
      default: "100%",
    },
    chartFontSize: {
      type: Number,
      default: 14,
    },
    toolbarColor: {
      type: String,
      default: "",
    },
    manualDraw: {
      type: Boolean,
      default: false,
    },
    delayed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerSpec: {},
  }),
  computed: {
    classes() {
      return { "rai-graph--loading": this.loading };
    },
    compElevation() {
      return this.flat ? 0 : this.elevation;
    },
    showToolbar() {
      return this.showTitle || this.showTrend;
    },
    showTitle() {
      return !isNullOrEmpty(this.title);
    },
    showTrend() {
      return !isNullOrEmpty(this.trend);
    },
    chartId() {
      return `rai-graph__chart-${genRandom()}`;
    },
    chartStyles() {
      return {
        height: convertToUnit(this.chartHeight),
        width: convertToUnit(this.chartWidth),
      };
    },
    chartColor() {
      return this.dark || this.$vuetify.theme.dark ? "white" : "black";
    },
    /** @returns {import('vega-embed').VisualizationSpec} */
    specDefaults() {
      const textOptions = {
        labelColor: this.chartColor,
        titleColor: this.chartColor,
        labelFontSize: this.chartFontSize,
      };

      return {
        background: "transparent",
        width: "container",
        height: "container",
        config: {
          title: { color: this.chartColor },
          axis: textOptions,
          legend: textOptions,
        },
      };
    },
  },
  watch: {
    spec: {
      deep: true,
      immediate: true,
      handler(value) {
        this.specHandler(value, this.specDefaults);
      },
    },
    specDefaults: {
      deep: true,
      handler(value) {
        this.specHandler(this.innerSpec, value);
      },
    },
    loading: {
      handler(value) {
        if (!value && !this.manualDraw) this.draw();
      },
    },
  },
  methods: {
    async internalDraw() {
      const chart = await embed(`#${this.chartId}`, this.innerSpec, {
        actions: false,
      });

      chart.view.addEventListener("click", this.clickMark);
      this.$emit("draw", chart);
    },
    /* istanbul ignore next: untestable Vega funcionality */
    clickMark(_, context) {
      if (!context || isNullOrEmpty(context.datum)) return;
      this.$emit("click:mark", context.datum);
    },
    specHandler(currValue, newValue) {
      this.innerSpec = merge(currValue, newValue);

      if (!this.manualDraw && !this.loading) {
        this.draw();
      }
    },
    draw() {
      this.delayed
        ? setTimeout(async () => {
            this.internalDraw();
          }, 150)
        : this.internalDraw();
    },
  },
  render(h) {
    return h(
      VSheet,
      {
        staticClass: "rai-graph",
        class: this.classes,
        attrs: this.$attrs,
        props: {
          elevation: this.compElevation,
          color: this.color,
          dark: this.dark,
        },
      },
      [
        getSlot(this, "toolbar") ||
          (this.showToolbar &&
            h(
              VToolbar,
              {
                class: "rai-graph__toolbar",
                props: {
                  color: this.toolbarColor || this.color,
                  flat: true,
                },
              },
              [
                h(VRow, { props: { align: "center", noGutters: true } }, [
                  this.showTitle &&
                    h(
                      VSkeletonLoader,
                      {
                        class: "rai-graph__title",
                        props: {
                          type: "heading",
                          loading: this.loading,
                        },
                      },
                      [h(VToolbarTitle, this.title)]
                    ),
                  h(VSpacer),
                  this.showTrend &&
                    h(
                      VSkeletonLoader,
                      {
                        class: "rai-graph__trend",
                        props: {
                          type: "heading",
                          loading: this.loading,
                        },
                      },
                      [
                        h(RaiTrend, {
                          class: "text-h6",
                          props: {
                            showSuffix: false,
                            value: this.trend,
                          },
                        }),
                      ]
                    ),
                ]),
              ]
            )),
        this.showToolbar && h(VDivider),
        h("div", { class: "rai-graph__wrapper" }, [
          this.loading
            ? h(VSkeletonLoader, {
                props: { type: "image", height: this.chartStyles.height },
              })
            : h("div", {
                attrs: { id: this.chartId },
                style: this.chartStyles,
              }),
        ]),
      ]
    );
  },
};
</script>

<style lang="scss">
.rai-graph {
  border-radius: 5px;
  overflow: hidden;

  &.rai-graph--loading {
    .rai-graph__title .v-skeleton-loader__heading {
      width: 200px;
    }

    .rai-graph__trend .v-skeleton-loader__heading {
      width: 60px;
    }

    .v-skeleton-loader__image {
      height: 100%;
    }
  }

  .rai-graph__wrapper {
    padding: 16px;
    height: 100%;
  }

  .rai-graph__toolbar ~ .rai-graph__wrapper {
    height: calc(100% - 64px);
  }
}
</style>
