<script>
import { getSlot, hasSlot } from "../../utils";
import { useResponsiveness } from "../../mixins";

export default {
  name: "SetupLayoutHeader",
  mixins: [useResponsiveness()],
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    hasTitle() {
      return !!this.title;
    },
    hasDescription() {
      return hasSlot(this, "description") || !!this.description;
    },
    titleClasses() {
      return {
        "mb-4 text-center": true,
        "text-h1": this.isDesktop,
        "text-h2 ": this.isMobile,
      };
    },
  },
  render(h) {
    return h("div", { class: "setup-layout-header" }, [
      this.hasTitle && h("div", { class: this.titleClasses }, this.title),
      this.hasDescription &&
        h(
          "div",
          {
            class:
              "setup-layout-header__description text-body-1 text--secondary",
          },
          getSlot(this, "description") || this.description
        ),
    ]);
  },
};
</script>

<style lang="scss" scoped>
.setup-layout-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 18px 0px;
}
</style>
