<script>
import VxTableRow from "./VxTableRow";
import VxTableDataCell from "./VxTableDataCell.vue";
import VxTableHeaderCell from "./VxTableHeaderCell.vue";
import { VProgressLinear, VSimpleTable, VSlideYTransition } from "vuetify/lib";
import { getSlot } from "../../../utils";

export default {
  name: "VxTable",
  inheritAttrs: false,
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
    striped: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "$vuetify.vx.table.loadingText",
    },
    noDataText: {
      type: String,
      default: "$vuetify.vx.table.noDataText",
    },
  },
  computed: {
    classes() {
      return {
        "vx-table--striped": this.striped,
        "vx-table--hoverable": this.hoverable,
        "vx-table--loading": this.loading,
        "vx-table--empty": this.isEmpty,
      };
    },
    isEmpty() {
      return this.items.length === 0;
    },
    headProps() {
      return {
        headers: this.compHeaders,
      };
    },
    bodyProps() {
      return {
        items: this.items,
        headers: this.compHeaders,
      };
    },
    compHeaders() {
      return this.headers.filter((x) => x.hidden !== true);
    },
    compHeadersLength() {
      return this.compHeaders.length;
    },
  },
  methods: {
    genHeaders() {
      const h = this.$createElement;
      return this.compHeaders.map((header, index) =>
        h(
          VxTableHeaderCell,
          {
            props: { ...header, value: header.title, type: "text" },
            key: index,
          },
          [getSlot(this, `head.${header.field}`, header, true)]
        )
      );
    },
    genRows() {
      if (this.loading) {
        return this.genInfoRow(this.loadingText, "loading");
      } else if (!this.loading && this.isEmpty) {
        return this.genInfoRow(this.noDataText, "no-data");
      }

      return this.items.map((item, index) => {
        const itemSlot = getSlot(this, "item", { item, index });
        return itemSlot || this.genRow(item, index);
      });
    },
    genRow(item, index) {
      const h = this.$createElement;
      return h(
        VxTableRow,
        { key: index },
        this.compHeaders.map((header, colIndex) => {
          const key = `${header.field}_${index}${colIndex}`;
          return this.genCell(item, header, key);
        })
      );
    },
    genCell(item, header, key) {
      const h = this.$createElement;
      const cellComponent = header.fixed ? VxTableHeaderCell : VxTableDataCell;
      return h(cellComponent, {
        key,
        class: header.cellClassName,
        props: { ...header, value: item[header.field] },
        scopedSlots: {
          default: ({ value }) => {
            return getSlot(this, `item.${header.field}`, { item, value });
          },
        },
      });
    },
    genInfoRow(textValue, slotName) {
      const h = this.$createElement;
      return h(VxTableRow, { key: slotName }, [
        h(
          VxTableDataCell,
          {
            class: "text-body-2",
            props: {
              align: "center",
              type: "text",
            },
            attrs: {
              colSpan: this.compHeadersLength,
            },
          },
          [getSlot(this, slotName) || this.$vuetify.lang.t(textValue)]
        ),
      ]);
    },
    genProgress() {
      const h = this.$createElement;
      return (
        getSlot(this, "progress") ||
        h(VProgressLinear, {
          props: {
            height: "2px",
            active: this.loading,
            indeterminate: this.loading,
          },
        })
      );
    },
  },
  render(h) {
    return h(
      VSimpleTable,
      {
        staticClass: "vx-table",
        class: this.classes,
        attrs: this.$attrs,
      },
      [
        h("template", { slot: "top" }, [this.genProgress()]),
        getSlot(this, "head", this.headProps, true) ||
          h("thead", [h(VxTableRow, {}, [this.genHeaders()])]),
        getSlot(this, "body", this.bodyProps, true) ||
          h(
            VSlideYTransition,
            {
              props: {
                group: true,
                hideOnLeave: true,
              },
              attrs: {
                tag: "tbody",
              },
            },
            [this.genRows()]
          ),
        getSlot(this, "foot", this.bodyProps, true),
      ]
    );
  },
};
</script>

<style lang="scss">
$table-dark-striped-row: #141414;
$table-light-striped-row: #f4f4f4;

.vx-table tbody tr td,
.vx-table thead tr th {
  white-space: nowrap !important;
}

.vx-table.vx-table--loading table,
.vx-table.vx-table--empty table {
  min-height: 100%;
}

.vx-table:not(.vx-table--hoverable) tbody tr:hover {
  background-color: inherit !important;
}

.vx-table.theme--light {
  &.v-data-table--fixed-header thead th,
  th.vx-table__header-cell--fixed {
    background-color: var(--v-ui-lighten4);
    color: rgba(0, 0, 0, 0.6);
  }

  &.vx-table--striped tbody tr:nth-child(odd) {
    background-color: $table-light-striped-row;
  }

  &.vx-table--striped:not(.vx-table--hoverable) tbody tr:nth-child(odd):hover {
    background-color: $table-light-striped-row !important;
  }
}

.vx-table.theme--dark {
  &.v-data-table--fixed-header thead th,
  th.vx-table__header-cell--fixed {
    background-color: var(--v-ui-darken5);
    color: rgba(255, 255, 255, 0.7);
  }

  &.vx-table--striped tbody tr:nth-child(odd) {
    background-color: $table-dark-striped-row;
  }

  &.vx-table--striped:not(.vx-table--hoverable) tbody tr:nth-child(odd):hover {
    background-color: $table-dark-striped-row !important;
  }
}
</style>
