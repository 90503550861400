export const baseGuard = (
  options = { active: (to, from) => true },
  specificGuard
) => {
  return (to, from, next) => {
    if (!options.active(to, from)) {
      next();
    } else {
      specificGuard(to, from, next);
    }
  };
};
