import QuotedTimeSlot from "./QuotedTimeSlot";
import {
  setHours,
  startOfHour,
  addDays,
  eachMinuteOfInterval,
  subMinutes,
  addMinutes,
} from "date-fns";

/**
 * @typedef {Object} QuotedTimeConfig
 * @property {Number} interval - interval by which to create quoted time
 * @property {Number} fromHour - quoted time start range (exclusive)
 * @property {Number} toHour - quoted time end range (exclusive)
 */
export default class QuotedTime {
  static startOfGivenHour(date, hour) {
    return startOfHour(setHours(date, hour));
  }

  /**
   * Creates QuotedTime instance
   * @param {QuotedTimeConfig} config
   * @returns {QuotedTime} instance of QuotedTime class
   */
  constructor(config) {
    const errors = this.validateConfig(config);

    if (errors.length > 0) {
      throw errors;
    }

    this.config = Object.freeze(config);
  }

  /**
   * Validates QuotedTime config
   * @param {QuotedTimeConfig} config
   * @returns {Error[]}
   */
  validateConfig({ interval, fromHour, toHour }) {
    const errors = [];

    if (interval < 1 || interval > 60) {
      errors.push(
        new Error(
          "`interval` has invalid value expected value to be from 1 to 60"
        )
      );
    }

    if (fromHour > toHour || fromHour < 0 || fromHour > 24) {
      errors.push(
        new Error(
          "`fromHour` has invalid value expected value to be from 0 to 24 and lesser then `toHour`"
        )
      );
    }

    if (toHour < fromHour || toHour < 0 || toHour > 24) {
      errors.push(
        new Error(
          "`toHour` has invalid value expected value to be from 0 to 24 and greater then `fromHour`"
        )
      );
    }

    return errors;
  }

  /**
   * @returns current date object
   */
  get currentDateTime() {
    return new Date();
  }

  /**
   * Gets available time slots for today
   * @returns {QuotedTimeSlot[]} available time slots
   */
  forToday() {
    const { interval, fromHour, toHour } = this.config;
    const today = this.currentDateTime;

    if (today.getHours() < fromHour) {
      today.setHours(fromHour, 0, 0, 0);
    }

    return this.createTimeSlots(
      today,
      QuotedTime.startOfGivenHour(today, toHour),
      interval
    );
  }

  /**
   * Gets available time slots for tomorrow
   * @returns {QuotedTimeSlot[]} available time slots
   */
  forTomorrow() {
    const { interval, fromHour, toHour } = this.config;
    const tomorrow = addDays(this.currentDateTime, 1);
    return this.createTimeSlots(
      QuotedTime.startOfGivenHour(tomorrow, fromHour),
      QuotedTime.startOfGivenHour(tomorrow, toHour),
      interval
    );
  }

  /**
   * Creates time slots for given range and interval
   * @param {Date} start - time where slots start
   * @param {Date} end - time where slots end
   * @param {Number} interval - time interval by which to create slots
   * @returns {QuotedTimeSlot[]} an array of time slots
   */
  createTimeSlots(start, end, interval) {
    const options = {
      start: addMinutes(start, interval),
      end: subMinutes(end, interval),
    };

    let timeSlots = [];

    try {
      timeSlots = eachMinuteOfInterval(options, { step: interval });
      return timeSlots.map((date) => new QuotedTimeSlot(date, interval));
    } catch (e) {
      // ?  QUICKFIX: `eachMinuteOfInterval` throws error when today time exceeds end hour
      // TODO: investigate values of start and end
    }

    return timeSlots;
  }
}
