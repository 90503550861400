import { blocking } from "./blocking";

const RaiErrorPlugin = {
  /**
   * Defines $raiError object on Vue instance
   * @param {import("vue").VueConstructor} Vue
   * @param {{ vuetify: import("vuetify/lib").default}} args
   */
  install(Vue, args = { vuetify: null }) {
    if (!args.vuetify) {
      throw new Error(
        "Vuetify instance is required for raiError plugin to work correctly."
      );
    }

    const raiError = {
      blocking: (options) => blocking(Vue, args, options),
    };

    Vue.prototype.$raiError = raiError;
  },
};

export { RaiErrorPlugin };
export default RaiErrorPlugin;
