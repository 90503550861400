import { default as SORT_ORDER } from "./SortOrder";
import { orderBy, get } from "lodash";

export const LocalPaginationUtils = {
  /**
   *
   * @param {PaginationOptions} currentPaginationOptions current pagination options - will update those options.
   */
  fetchMoreData(currentPaginationOptions) {
    this.updateItemsPerPage(
      currentPaginationOptions,
      currentPaginationOptions.itemsPerPage +
        currentPaginationOptions.itemsPerPageStep
    );
  },

  /**
   *
   * @param {PaginationOptions} currentPaginationOptions current pagination options - will update those options.
   * @param {Number} page new page value
   */
  updatePage(currentPaginationOptions, page) {
    currentPaginationOptions.page = page;
  },

  /**
   *
   * @param {PaginationOptions} currentPaginationOptions current pagination options - will update those options.
   * @param {Number} itemsPerPage new items per page value
   */
  updateItemsPerPage(currentPaginationOptions, itemsPerPage) {
    // TODO - see what we can do in this case! - for server side if we can decide what is last!
    const oldPageStartIndex =
      (currentPaginationOptions.page - 1) *
      currentPaginationOptions.itemsPerPage;

    let newPage = Math.floor(oldPageStartIndex / itemsPerPage) + 1;
    const newPageAfterEndIndex = newPage * itemsPerPage;

    // If on per page change WOULD show non existing items, show the last page
    if (newPageAfterEndIndex >= currentPaginationOptions.totalItems) {
      newPage =
        Math.floor((currentPaginationOptions.totalItems - 1) / itemsPerPage) +
        1;
    }

    currentPaginationOptions.itemsPerPage = itemsPerPage;
    currentPaginationOptions.page = newPage;
  },

  /**
   *
   * @param {SortOptions} currentSortOptions current sort options - will update those options.
   * @param {PaginationOptions} currentPaginationOptions current pagination options - will update those options.
   * @param {String} newSortBy new sort by value
   */
  // TODO - change this so that pagination options is the first parameter (same as on serverpaginationutils)
  //        - but we need to do this togethe with changes where it is used
  updateSort(
    currentSortOptions,
    currentPaginationOptions,
    newSortBy,
    newSortOrder = undefined
  ) {
    let updateSortBy = newSortBy;
    let updateSortOrder = currentSortOptions.sortOrder;

    if (!newSortOrder) {
      if (currentSortOptions.sortBy === newSortBy) {
        if (currentSortOptions.sortOrder === SORT_ORDER.ASC) {
          updateSortOrder = SORT_ORDER.DESC;
        } else {
          updateSortOrder = SORT_ORDER.ASC;
        }
      } else {
        updateSortOrder = SORT_ORDER.ASC;
      }
    } else {
      updateSortOrder = newSortOrder;
    }

    currentSortOptions.sortBy = updateSortBy;
    currentSortOptions.sortOrder = updateSortOrder;

    // Also update to page 1
    currentPaginationOptions.page = 1;
  },

  /**
   *
   * @param {any} localItems all the items to paginate
   * @param {PaginationOptions} paginationOptions pagination options
   * @param {SortOptions} sortOptions sort options
   * @param {*} param3 options how to paginate.
   * @returns Sorted and paginated items
   */
  paginateLocalItems(
    localItems,
    paginationOptions,
    sortOptions,
    { ignoreCase = true } = {}
  ) {
    if (!localItems) {
      return [];
    }

    let itemsPaginated = orderBy(
      localItems,
      [
        (localItem) => {
          const localItemsProperty = get(localItem, sortOptions.sortBy);

          if (
            ignoreCase &&
            (typeof localItemsProperty === "string" ||
              localItemsProperty instanceof String)
          ) {
            return localItemsProperty.toLowerCase();
          }
          return localItemsProperty;
        },
      ],
      [sortOptions.sortOrder === SORT_ORDER.ASC ? "asc" : "desc"]
    );

    const pageStartIndex =
      (paginationOptions.page - 1) * paginationOptions.itemsPerPage;
    const pageAfterEndIndex =
      paginationOptions.page * paginationOptions.itemsPerPage;
    itemsPaginated = itemsPaginated.slice(pageStartIndex, pageAfterEndIndex);

    return itemsPaginated;
  },

  /**
   * Updates pagination options from local data
   */
  updateOptionsFromLocalData(paginationOptions, localItems) {
    paginationOptions.totalItems = localItems ? localItems.length : 0;
  },
};
