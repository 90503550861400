import Vue from "vue";

export function useResponsiveness() {
  /* istanbul ignore next reason: Vue and Vuetify implementation */
  return Vue.extend({
    computed: {
      isMobile() {
        // -> see mobileBreakpoint in themes/rai/preset/index.js
        return this.$vuetify.breakpoint.mobile;
      },
      isDesktop() {
        return !this.isMobile;
      },
    },
  });
}
