export const props = {
  disabled: {
    type: [Boolean, Number],
    default: false,
  },
};

export const methods = {
  showItem(event) {
    if (!this.disabled) {
      this.$emit("showItem", event);
    }
  },
};
