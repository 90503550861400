<script>
import useTableCell from "./mixins/useTableCell";

export default {
  name: "VxTableDataCell",
  mixins: [useTableCell()],
  render(h) {
    return h(
      "td",
      {
        class: [
          this.cellClasses,
          this.className,
          "vx-table__data-cell text-data",
        ],
      },
      this.genContent()
    );
  },
};
</script>
