<script>
import VxDialog from "./VxDialog";
import VxBtn from "../VxBtn/VxBtn.vue";
import { props } from "./VxDialogBase";
import { useModelValue } from "../../../mixins";

export default {
  name: "VxConfirmDialog",
  components: {
    VxDialog,
    VxBtn,
  },
  mixins: [useModelValue()],
  inheritAttrs: false,
  props: {
    ...props,
    okText: {
      type: String,
      default: "$vuetify.vx.confirmDialog.okText",
    },
    okColor: {
      type: String,
      default: "primary",
    },
    okDisabled: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "$vuetify.vx.confirmDialog.cancelText",
    },
    cancelColor: {
      type: String,
      default: "grey",
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: [String, Boolean],
      default: false,
    },
    hSize: {
      type: String,
      default: "xsmall",
    },
  },
  computed: {
    showCancel() {
      return !this.hideCancel;
    },
  },
  methods: {
    show() {
      this.modelValue = true;
      this.$emit("show");
    },
    hide() {
      this.modelValue = false;
      this.$emit("hide");
    },
    cancel(e) {
      this.$emit("cancel", e);
      if (e.defaultPrevented) return;
      this.hide();
    },
    ok(e) {
      this.$emit("ok", e);
      if (e.defaultPrevented) return;
      this.hide();
    },
  },
};
</script>

<template>
  <VxDialog
    v-model="modelValue"
    v-bind="{ ...$props, noBack: true }"
    :attach="attach"
    persistent
    v-on="$listeners"
  >
    <slot></slot>

    <template #toolbarTitle>
      <slot name="toolbarTitle"></slot>
    </template>

    <template #actions>
      <VxBtn v-if="showCancel" :color="cancelColor" text @click="cancel">
        {{ $vuetify.lang.t(cancelText) }}
      </VxBtn>

      <VxBtn text :color="okColor" :disabled="okDisabled" @click="ok">
        {{ $vuetify.lang.t(okText) }}
      </VxBtn>
    </template>
  </VxDialog>
</template>
