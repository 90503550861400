<script>
import { VIcon } from "vuetify/lib";
import { enumValidator } from "../../../utils";
import { TooltipType, TooltipTypeConfig } from "./constants";

export default {
  name: "VxTooltipActivator",
  functional: true,
  props: {
    type: {
      type: String,
      required: true,
      validator: enumValidator(TooltipType),
    },
  },
  render(h, { props, data }) {
    const { icon, color } = TooltipTypeConfig[props.type];
    return h(
      VIcon,
      {
        props: { color },
        attrs: data.attrs,
        on: data.on,
      },
      icon
    );
  },
};
</script>
