<script>
import { props } from "./VxDataListItemBase";
import VxDataListItemMobile from "./VxDataListItemMobile.vue";
import VxDataListItemDesktop from "./VxDataListItemDesktop.vue";
import { defineResponsiveComponent } from "../../../utils/component";

export default defineResponsiveComponent({
  name: "VxDataListItem",
  desktop: VxDataListItemDesktop,
  mobile: VxDataListItemMobile,
  props,
});
</script>
