const customersRoute = {
  // This route has no name, as when using nested routes (with a default nested route)
  // it's better to use directly default child route
  components: {
    default: () => import("@/views/CustomersListView"),
    appbar: () => import("@/views/CustomersListView/CustomersAppBar"),
    dialog: () => import("@/views/Common/RouterDefaultViewWrapper"),
  },
  meta: {
    requiresAuth: true,
    title: "Customers",
  },
  children: [
    {
      name: "customers_home",
      path: "",
    },
    {
      // TODO - have init active tab as prop
      name: "customers_show",
      path: "detail/show/:customerId",
      component: () => import("@/components/customers/CustomerDetailDialog"),
      props: (route) => ({
        buysRouteName: "customers_buy",
      }),
    },
    {
      name: "customers_buy",
      path: "detail/buys/show/:buyId",
      component: () => import("@/views/Buy/AppView/BuyDetailView"),
      props: (route) => ({
        customerRouteName: "customers_show",
      }),
    },
  ],
};

export { customersRoute };
