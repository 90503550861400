export const props = {
  page: {
    type: Number,
    default: 1,
  },
  totalItems: {
    type: Number,
    default: 1,
  },
  itemsPerPage: {
    type: Number,
    default: 25,
  },
  itemsPerPageStep: {
    type: Number,
    default: 25,
  },
  pageSizes: {
    type: Array,
    default: () => [25, 50, 100],
  },
  loading: {
    type: [Boolean, Number],
    default: false,
  },
};

export const computed = {
  localValue: {
    get() {
      return this.page;
    },
    set(v) {
      this.$emit("update:page", v);
    },
  },
  localItemsPerPage: {
    get() {
      return this.itemsPerPage;
    },
    set(v) {
      this.$emit("update:itemsPerPage", v);
    },
  },
  pageCount() {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  },
};
