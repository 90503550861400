<script>
import { VSlideYTransition, VSheet, VSkeletonLoader } from "vuetify/lib";
import { getSlot, isNullOrEmpty } from "../../../utils";
import RaiNumber from "../RaiNumber/RaiNumber.vue";
import RaiTrend from "../RaiTrend/RaiTrend.vue";

export default {
  name: "RaiMetric",
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    /** @deprecated in favour of `title` prop; remove in next version */
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "decimal",
      validator(value) {
        return ["decimal", "currency", "percent"].indexOf(value) !== -1;
      },
    },
    notation: {
      type: String,
      default: "standard",
      validator(value) {
        return ["standard", "compact"].indexOf(value) !== -1;
      },
    },
    decimals: {
      type: Number,
      default: 0,
    },
    elevation: {
      type: [String, Number],
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    trend: {
      type: Number,
      default: null,
    },
    trendText: {
      type: String,
      default: "",
    },
    trendDecimals: {
      type: [String, Number],
      default: 2,
    },
    trendColor: {
      type: String,
      default: "",
    },
  },
  computed: {
    classes() {
      return {
        "rai-metric": true,
        "rai-metric--loading": this.loading,
      };
    },
    isCurrency() {
      return this.type === "currency";
    },
    isPercent() {
      return this.type === "percent";
    },
    isBigNumber() {
      return this.value >= 100000 && this.$vuetify.breakpoint.md;
    },
    numberClasses() {
      return {
        "d-flex": true,
        "align-baseline": true,
        "big-number": this.isBigNumber,
      };
    },
  },
  render(h) {
    return h(
      VSheet,
      {
        class: this.classes,
        attrs: this.$attrs,
        props: {
          elevation: this.elevation,
        },
      },
      [
        getSlot(this, "prepend"),
        h(
          "div",
          { class: "rai-metric__label text--secondary text-subtitle-2" },
          [
            h(
              VSkeletonLoader,
              {
                props: {
                  type: "text",
                  loading: this.loading,
                },
                class: "text-truncate",
              },
              [getSlot(this, "title") || this.title || this.label]
            ),
          ]
        ),
        h("div", { class: "rai-metric__value text-h4 text-truncate my-1" }, [
          getSlot(this) ||
            h(
              VSkeletonLoader,
              {
                props: {
                  type: "heading",
                  loading: this.loading,
                },
              },
              [
                h(RaiNumber, {
                  props: {
                    tag: "div",
                    value: this.value,
                    notation: this.notation,
                    decimals: this.decimals,
                  },
                  scopedSlots: {
                    default: ({ number }) =>
                      h("div", { class: this.numberClasses }, [
                        this.isCurrency &&
                          h(
                            "span",
                            { class: "text-h5 font-weight-light" },
                            "$"
                          ),
                        number,
                        this.isPercent &&
                          h(
                            "span",
                            { class: "text-h5 font-weight-light" },
                            "%"
                          ),
                      ]),
                  },
                }),
              ]
            ),
        ]),
        h(VSlideYTransition, { props: { hideOnLeave: true } }, [
          !isNullOrEmpty(this.trend) &&
            h(RaiTrend, {
              class: "text-body-2",
              props: {
                value: this.trend,
                color: this.trendColor,
                decimals: this.trendDecimals,
                appendText: this.trendText,
              },
            }),
        ]),
        getSlot(this, "append"),
      ]
    );
  },
};
</script>

<style lang="scss">
.rai-metric {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  min-height: 150px;

  .rai-metric__label,
  .rai-metric__value {
    display: flex;
    justify-content: center;
  }

  &.rai-metric--loading {
    .rai-metric__label > .v-skeleton-loader {
      width: 100px;
    }

    .rai-metric__value > .v-skeleton-loader {
      width: 150px;
    }

    .v-skeleton-loader__heading {
      width: 100%;
    }
  }

  .big-number {
    letter-spacing: -0.14em;
  }
}
</style>
