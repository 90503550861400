import { renderBlockingError } from "./helpers";

/**
 * @typedef {Object} BlockingErrorOptions
 * @property {String|JSX.Element} message - message to show
 * @property {String} [title] - error title
 * @property {String} [okText] - button OK text
 * @property {String} [okColor] - button OK color
 * @property {String} [okDisabled] - button OK is disabled
 * @property {String} [cancelText] - button CANCEL text
 * @property {String} [cancelColor] - button CANCEL color
 * @property {String} [attach] - where to attach blocking error in DOM
 * @property {Boolean} [cancelRefresh] - cancel refreshing page on primary click
 */

/**
 * Renders blocking error in DOM on demand
 * @param {import("vue").VueConstructor} Vue - Vue constructor
 * @param {{ vuetify: import("vuetify/lib").default }} args - arguments provided by plugin
 * @param {BlockingErrorOptions} options - for rendering the blocking error
 * @returns {Promise<boolean>} a promise to be resolved by the blocking error
 */
function blocking(Vue, args, options) {
  const {
    title,
    message,
    okText,
    okColor,
    okDisabled,
    cancelText,
    cancelColor,
    attach,
    cancelRefresh,
  } = options;

  return renderBlockingError(Vue, args, {
    title,
    message,
    okText,
    okColor,
    okDisabled,
    cancelText,
    cancelColor,
    attach,
    cancelRefresh,
  });
}

export { blocking };
export default blocking;
