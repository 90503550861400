<script>
import { props } from "./VxDialogBase";
import VxDialogMobile from "./VxDialogMobile.vue";
import VxDialogDesktop from "./VxDialogDesktop.vue";
import { defineResponsiveComponent } from "../../../utils/component";

export default defineResponsiveComponent({
  name: "VxDialog",
  desktop: VxDialogDesktop,
  mobile: VxDialogMobile,
  props,
});
</script>
