var render, staticRenderFns
import script from "./RaiEditable.vue?vue&type=script&lang=js&"
export * from "./RaiEditable.vue?vue&type=script&lang=js&"
import style0 from "./RaiEditable.vue?vue&type=style&index=0&id=2ff9bfca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff9bfca",
  null
  
)

export default component.exports