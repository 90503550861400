import i18n from "@/plugins/vue-i18n";

const backstockHistoryRoute = {
  // This route has no name, as when using nested routes (with a default nested route)
  // it's better to use directly default child route
  // Also it has no path, because addition of path is managed by buildRoute function (in store-routes.js)
  components: {
    default: () => import("@/views/Report/AppView/BackstockHistoryListView"),
    appbar: () => import("@/views/Report/AppView/BackstockHistoryAppBar"),
    dialog: () => import("@/views/Common/RouterDefaultViewWrapper"),
  },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      meta: {
        title: i18n.t("report.app.backstockHistory.toolbarTitle"),
        showBack: true,
      },
      name: "reports.backstockHistory",
      path: "",
    },
  ],
};

const backstockHistoryCommonRouteProps = (route) => {
  return {};
};

export { backstockHistoryRoute, backstockHistoryCommonRouteProps };
