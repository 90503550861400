<script>
import { VSelect } from "vuetify/lib";
import { useForm, useModelValue, useValidationProvider } from "../../../mixins";

export default {
  name: "VxSelect",
  components: {
    VSelect,
  },
  mixins: [useForm(), useModelValue(), useValidationProvider()],
  inheritAttrs: false,
  methods: {
    focus() {
      this.$refs.selectField.focus();
    },
    validator() {
      return this.$refs.provider;
    },
    openMenu() {
      return (this.$refs.selectField.isMenuActive = true);
    },
    closeMenu() {
      return (this.$refs.selectField.isMenuActive = false);
    },
  },
};
</script>

<template>
  <ValidationProvider
    ref="provider"
    :vid="name"
    :name="label"
    :rules="rules"
    :mode="mode"
    slim
  >
    <template v-slot="{ errors }">
      <VSelect
        ref="selectField"
        v-model="modelValue"
        :name="name"
        :label="label"
        :error-messages="errors"
        :outlined="isOutlined"
        :persistent-placeholder="isPersistentPlaceholder"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template
          v-for="(value, name) in $scopedSlots"
          v-slot:[name]="slotProps"
        >
          <slot :name="name" v-bind="slotProps"></slot>
        </template>
        <template v-slot:append>
          <slot name="append"></slot>
        </template>
        <template v-slot:append-item>
          <slot name="append-item"></slot>
        </template>
        <template v-slot:append-outer>
          <slot name="append-outer"></slot>
        </template>
        <template v-slot:label>
          <slot name="label"></slot>
        </template>
        <template v-slot:no-data>
          <slot name="no-data"></slot>
        </template>
        <template v-slot:prepend>
          <slot name="prepend"></slot>
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner"></slot>
        </template>
        <template v-slot:prepend-item>
          <slot name="prepend-item"></slot>
        </template>
        <template v-slot:progress>
          <slot name="progress"></slot>
        </template>
      </VSelect>
    </template>
  </ValidationProvider>
</template>
