import { format } from "date-fns";

const state = () => ({
  bizDate: null,
});

const mutations = {
  setBizDate(state, bizDate) {
    state.bizDate = bizDate;
  },
  setToday(state) {
    state.bizDate = format(new Date(), "yyyy-MM-dd");
  },
};

const actions = {
  ensureDate({ commit, getters }) {
    if (getters.bizDate == null) {
      commit("setToday");
    }
  },
};

const getters = {
  getBizDate(state) {
    return state.bizDate;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
