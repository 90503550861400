import { renderTooltip } from "./helpers";

const TooltipPlugin = {
  /**
   * Defines $tooltip object on Vue instance
   * @param {import("vue").VueConstructor} Vue
   * @param {{ vuetify: import("vuetify/lib").default}} args
   */
  install(Vue, args = { vuetify: null }) {
    if (!args.vuetify) {
      throw new Error(
        "Vuetify instance is required for tooltip plugin to work correctly."
      );
    }

    const tooltip = renderTooltip(Vue, args);

    Vue.prototype.$tooltip = tooltip;
  },
};

export { TooltipPlugin };
export default TooltipPlugin;
