import { cleanPhoneNumber } from "../formatting/phone-formatting";

/**
 * Validates phone number.
 * Implemented from here:
 * https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
 * section: Eliminate invalid phone numbers
 */
const fullValidRegex = /^([2-9][0-8][0-9])([2-9][0-9]{2})([0-9]{4})$/;

/**
 * @param {String} phoneNumber Phone number (will be cleaned to just digits internally before checking)
 * @returns true if the whole number is a valid North America phone number. It means that it needs to have 10 digits and conform to the standard
 * written on this link https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
 * section: Eliminate invalid phone numbers
 */
export function naPhoneFull(phoneNumber) {
  return fullValidRegex.test(phoneNumber);
}

// Regex that check if any substring is valid number.
// for ex. 2092139485 is a valid number.
const partialValidRegex =
  /^([2-9]|[2-9][0-8]|[2-9][0-8][0-9]|([2-9][0-8][0-9])[2-9]|([2-9][0-8][0-9])[2-9][0-9]|([2-9][0-8][0-9])[2-9][0-9]{2}|([2-9][0-8][0-9])([2-9][0-9]{2})[0-9]{1,4})$/;

/**
 * @param {String} phoneNumber Phone number (will be cleaned to just digits internally before checking)
 * @returns true if any part of number is a valid North America phone number.
 * @example ex. 2092139485 is a valid number. 2, 20, 209, 2092, ... up to full number 2092139485 - are all partially valid.
 */
export function naPhonePartial(phoneNumber) {
  return partialValidRegex.test(phoneNumber);
}

/** @type {import("vee-validate/dist/types/types").ValidationRule} */
export const naPhone = {
  message: "{_field_} must be a valid North America phone number",
  params: [{ name: "isPartial", cast: (value) => value === "partial" }],
  validate(value, { isPartial }) {
    const phoneNumber = cleanPhoneNumber(value);

    if (!phoneNumber) {
      return false;
    }

    return isPartial ? naPhonePartial(phoneNumber) : naPhoneFull(phoneNumber);
  },
};

export default naPhone;
