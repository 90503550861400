import { default as SORT_ORDER } from "./SortOrder";

export const PaginationOptionsUtils = {
  /**
   *
   * @param {PaginationOptions} overrideDefaults overrides for default pagination options
   * @returns default pagination options with overrides
   */
  buildDefaultPaginationOptions(overrideDefaults) {
    const pagination = Object.assign(
      {
        page: 1,
        itemsPerPage: 25,
        pageSizes: [25, 50, 100],
        totalItems: 0,
      },
      overrideDefaults
    );

    pagination.itemsPerPageStep = pagination.itemsPerPage;

    pagination.pageInfo = {
      startCursor: null,
      endCursor: null,
    };

    return pagination;
  },

  /**
   *
   * @param {SortOptions} overrideDefaults overrides for default sort options
   * @returns default sort options with overrides
   */
  buildDefaultSortOptions(overrideDefaults) {
    const sort = Object.assign(
      {
        sortBy: undefined,
        sortOrder: SORT_ORDER.ASC,
      },
      overrideDefaults
    );

    return sort;
  },

  /**
   *
   * @param {PaginationOptions} paginationOptions pagination options
   * @param {SortOptions} sortOptions sort options
   * @returns initial server request base on pagination and sort
   */
  buildDefaultServerPaginationRequest(paginationOptions, sortOptions) {
    let serverRequest = {
      first: 25,
      last: null,
      after: null,
      before: null,
      sortBy: undefined,
      descending: true,
    };

    serverRequest.sortBy = sortOptions.sortBy;
    if (sortOptions.sortOrder !== undefined) {
      serverRequest.descending = sortOptions.sortOrder === SORT_ORDER.DESC;
    }
    serverRequest.first = paginationOptions.itemsPerPage;

    return serverRequest;
  },
};
