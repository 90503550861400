const SORT_ORDER = {
  /**
   * Ascending
   */
  ASC: "asc",
  /**
   * Descending
   */
  DESC: "desc",
};

export { SORT_ORDER };
export default SORT_ORDER;
