const orderRoute = {
  // This route has no name, as when using nested routes (with a default nested route)
  // it's better to use directly default child route
  components: {
    default: () => import("@/views/OrderListView"),
    appbar: () => import("@/views/OrderListView/OrderAppBar"),
    dialog: () => import("@/views/Common/RouterDefaultViewWrapper"),
  },
  meta: {
    requiresAuth: true,
    title: "Orders",
  },
  children: [
    {
      name: "orders_home",
      path: "",
    },
    {
      name: "order_show",
      path: "detail/show/:orderId",
      component: () => import("@/views/OrderListView/OrderDetailView"),
      meta: {
        requiresAuth: true,

        title: "Orders",
      },
    },
  ],
};

export { orderRoute };
