import Vue from "vue";
import invoke from "lodash/invoke";

const EventBus = (() => {
  let instance;

  const createInstance = function () {
    return new Vue();
  };

  return {
    getInstance() {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    $on(...args) {
      console.log("EventBus/$on - mounting listener with:", args);
      return this.getInstance().$on(...args);
    },
    $off(...args) {
      console.log("EventBus/$off - dismounting listener with:", args);
      return this.getInstance().$off(...args);
    },
    $emit(...args) {
      console.log("EventBus/$emit - emitting event with:", args);
      return this.getInstance().$emit(...args);
    },
  };
})();

export default EventBus;
