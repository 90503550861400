import VxConfirmDialog from "../../components/vx/VxDialog/VxConfirmDialog.vue";

/**
 * Renders confirmation dialog in DOM on demand
 * @param {import("vue").VueConstructor} Vue - Vue constructor
 * @param {{ vuetify: import("vuetify/lib").default }} args - arguments provided by plugin
 * @param {{ message: string | JSX.Element }} options - for rendering dialog
 * @returns {Promise<boolean>} a promise to be resolved by dialog
 */
export function renderConfirmDialog(Vue, args, options) {
  return new Promise((resolve) => {
    const { message, ...propsData } = options;

    /** @type {import("vue").default} */
    const instance = new Vue({
      vuetify: args.vuetify,
      data: () => ({ dialog: true }),
      methods: {
        ok() {
          resolve(true);
          this.dialog = false;
        },
        cancel() {
          resolve(false);
          this.dialog = false;
        },
      },
      render(h) {
        return (
          this.dialog &&
          h(
            VxConfirmDialog,
            {
              props: {
                value: this.dialog,
                ...propsData,
              },
              on: {
                ok: this.ok,
                cancel: this.cancel,
              },
            },
            message
          )
        );
      },
    });

    instance.$mount(); // -> This will attach to the root v-app component by default.
  });
}
