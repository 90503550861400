<script>
export default {
  name: "RaiNumber",
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: "decimal",
      validator(value) {
        return ["decimal", "currency", "percent"].indexOf(value) !== -1;
      },
    },
    locale: {
      type: String,
      default: "en-US",
    },
    decimals: {
      type: [String, Number],
      default: undefined,
      validator(value) {
        return value >= 0 && value <= 20;
      },
    },
    tag: {
      type: String,
      default: "span",
    },
  },
  computed: {
    numberValue() {
      return this.value?.toLocaleString(this.locale, {
        currency: this.type === "currency" ? "USD" : undefined,
        maximumFractionDigits: this.decimals,
        minimumFractionDigits: this.decimals,
        ...this.$attrs,
        style: this.type,
      });
    },
  },
  render(h) {
    return h(this.tag, { staticClass: "rai-number", class: "text-data" }, [
      this.$scopedSlots.default
        ? this.$scopedSlots.default({ number: this.numberValue })
        : this.numberValue,
    ]);
  },
};
</script>
