<script>
import { getSlot } from "../../../utils";
import { useModelValue } from "../../../mixins";
import { ValidationProvider } from "vee-validate";
import {
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VScrollYTransition,
  VSwitch,
} from "vuetify/lib";

export default {
  name: "RaiSetupToggle",
  mixins: [useModelValue()],
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: null,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    inset: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    validator() {
      /* istanbul ignore next */
      return this.$refs.provider;
    },
  },
  render(h) {
    return h(ValidationProvider, {
      ref: "provider",
      props: {
        name: this.title,
        rules: this.rules,
        vid: this.$attrs.name,
        slim: true,
      },
      scopedSlots: {
        default: ({ errors, invalid, valid }) =>
          h(
            VListItem,
            {
              class: "rai-setup-toggle",
              props: {
                disabled: this.disabled,
                threeLine: true,
              },
            },
            [
              h(VListItemContent, [
                h(
                  VListItemTitle,
                  {
                    class: {
                      "text-overline": true,
                      "grey--text": valid,
                      "error--text": invalid,
                    },
                  },
                  getSlot(this, "title") || this.title
                ),
                h(
                  VListItemSubtitle,
                  { class: "text--primary" },
                  getSlot(this, "subtitle") || this.subtitle
                ),
                h(VListItemSubtitle, { class: "text-caption" }, [
                  h(VScrollYTransition, { props: { mode: "in-out" } }, [
                    invalid
                      ? h("span", { class: "messages error--text" }, errors[0])
                      : h("span", { class: "messages" }, "\u00A0"),
                  ]),
                ]),
              ]),
              h(VListItemAction, [
                h(VSwitch, {
                  model: {
                    value: this.modelValue,
                    callback: (val) => {
                      this.modelValue = val;
                    },
                  },
                  props: {
                    inset: this.inset,
                    error: invalid,
                    loading: this.loading,
                    readonly: this.loading,
                    disabled: this.disabled,
                  },
                  attrs: this.$attrs,
                  on: this.$listeners,
                }),
              ]),
            ]
          ),
      },
    });
  },
};
</script>

<style lang="scss">
.rai-setup-toggle {
  // ? CSS reset because we will not use it as list item
  flex: 0;
  padding-left: 0;
  padding-right: 0;
}
</style>
