<script>
import { providesCurrentTime } from "@/mixins/time";
import { deviceOfflineNotice } from "@/utils/notices";
import { serviceWorkerUpdateNotice } from "@/utils/notices";

export default {
  name: "App",
  mixins: [providesCurrentTime],
  data() {
    return {
      loaded: false,

      // PWA / ServiceWorker
      swRefreshing: false,
      swRegistration: null,

      // router view key updates on reload
      appKey: 1,

      teamMode: true,
    };
  },
  created() {
    // Listen for swRegistered events. This happens on every "install"
    // and we use it to set +this.swRegistration+
    document.addEventListener(
      "swRegistered",
      (event) => {
        console.log("Service worker registered");
        this.swRegistration = event.detail;
      },
      { once: true }
    );

    // Listen for swUpdated event and display refresh snackbar as required.
    // This is used to send a +SKIP_WAITING+ message to the service worker
    // and allow it to take control of the active page
    document.addEventListener("swUpdated", this.showUpdateSnackbar, {
      once: true,
    });

    // I don't know how useful this is
    const worker = navigator.serviceWorker;
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener("controllerchange", (evt) => {
      console.log("controllerchange fired", worker.state, evt);
      console.log("Showing update snackbar");
      // Show the snackbar with a prompt to activate the new service worker
      this.$store.dispatch("snackbar/showSnackbar", {
        text: "Update available",
        button: "Continue",
        action: this.reloadApp,
        timeout: -1,
      });

      serviceWorkerUpdateNotice.show();

      // go ahead and reload
      //this.reloadApp();
    });

    // listen for online / offline changes
    window.addEventListener("online", () => deviceOfflineNotice.clear());
    window.addEventListener("offline", () => deviceOfflineNotice.show());

    if (process.env.NODE_ENV !== "production") {
      window.$q = (...args) => document.querySelector(...args);
      window.$Q = (...args) => document.querySelectorAll(...args);
    }
  },
  async mounted() {
    // try token auth at first
    try {
      await this.tokenAuth();
    } catch (e) {
      console.log("failed to authenticate");
      console.error(e);
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    // ServiceWorker new version
    async showUpdateSnackbar(e) {
      // Show the snackbar with a prompt to activate the new service worker
      this.$store.dispatch("snackbar/showSnackbar", {
        text: "Update available",
        button: "Reload",
        action: this.reloadApp,
        timeout: -1,
      });
    },
    async tokenAuth() {
      // on POS / Flex, autologin if needed
      if (window.raiPosAsync && !this.$store.getters["auth/isAuthenticated"]) {
        // check for url params
        let auth = new URL(window.location).searchParams;

        // use tokenAuth if we can
        if (auth.has("user_email") && auth.has("user_token")) {
          await this.$store.dispatch("auth/tokenAuth", {
            email: auth.get("user_email"),
            token: auth.get("user_token"),
          });
        }

        let authInfoStr;

        // still not authenticated? use autoLogin
        if (!this.$store.getters["auth/isAuthenticated"]) {
          authInfoStr = await window.raiPosAsync.autoLogin();
        }

        // still not authenticated? check if beta mode is on and use info from that
        if (!this.$store.getters["auth/isAuthenticated"]) {
          if (
            window.raiPosAsync.hasOwnProperty("betaMode") &&
            (await window.raiPosAsync.betaMode())
          ) {
            if (authInfoStr) {
              const authInfo = JSON.parse(authInfoStr);
              await this.$store.dispatch("auth/tokenAuth", {
                email: authInfo.User,
                token: authInfo.Token,
              });
            }
          }
        }
      }
    },
    // Calls +postMessage+ to send a +SKIP_WAITING+ call to the SW
    async reloadApp() {
      if (this.refreshing) return;
      this.refreshing = true;
      console.log("Reloading page to activate new service worker");
      window.location.reload();
    },
  },
};
</script>

<template>
  <v-fade-transition mode="out-in">
    <router-view v-if="loaded" :key="appKey"></router-view>
  </v-fade-transition>
</template>
