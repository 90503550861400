import { trim } from "lodash";

function cleanPhoneNumber(numberToFormat) {
  let formattedNumber = trim(numberToFormat);

  if (formattedNumber.indexOf("+1") === 0) {
    // remove country code if on first place
    formattedNumber = formattedNumber.substring(2);
  }

  // Remove anything that is not a digit
  formattedNumber = formattedNumber.replace(/[^\d]+/gi, "");

  return !!formattedNumber ? formattedNumber : undefined;
}

function formatPhoneNumber(numberToFormat) {
  numberToFormat = cleanPhoneNumber(numberToFormat);

  if (!numberToFormat) {
    return undefined;
  }

  // Check if there is area code and one more digit after it
  const areaCodeRegex = /^(\d{3})(\d+)/;
  const areaCodeMatch = numberToFormat.match(areaCodeRegex);

  if (!areaCodeMatch) {
    // If the number doesn't have area code, just return original string
    return numberToFormat;
  }
  const [, areaCode, restOfNumber] = areaCodeMatch;

  if (!areaCode) {
    // If the number doesn't have area code, just return original string
    return numberToFormat;
  }

  // If the number has area code check for central office code and one more digit after it
  const centralOfficeCodeRegex = /^(\d{3})(\d+)/;
  const centralOfficeMatch = restOfNumber.match(centralOfficeCodeRegex);

  if (!centralOfficeMatch) {
    // If the number does not have central office code, return area code + the reminder of number
    return `(${areaCode}) ${restOfNumber}`;
  }

  const [, centralOfficeCode, subscriberNum] = centralOfficeMatch;
  return `(${areaCode}) ${centralOfficeCode}-${subscriberNum}`;
}

export { cleanPhoneNumber, formatPhoneNumber };
