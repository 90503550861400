import { ExternalActionLink } from "./externalActionLink";
import {
  EMPLOYEE_ACCESS_STATUS,
  NO_EMPLOYEE_ID,
} from "@/store/modules/teamAuth";
import i18n from "@/plugins/vue-i18n";

export class EmployeeConfirmLink extends ExternalActionLink {
  constructor({ store }) {
    // Call external action link with proper callbacks.
    // First to confirm when employee access is needed, second to do the action in the moment employee access is requested.
    super(
      (operation) => isEmployeeAccessNeeded(store, operation),
      () => startEmployeeAccessFlow(store)
    );

    store.watch(
      (_, getters) => {
        return getters["auth/employeeAccessStatus"];
      },
      (newValue, oldValue) => {
        if (oldValue === EMPLOYEE_ACCESS_STATUS.requiredEmployeeAccess) {
          this.#continueEmployeeAccessFlow(store, newValue);
        }
      }
    );
  }

  #continueEmployeeAccessFlow(store, newValue) {
    if (newValue === EMPLOYEE_ACCESS_STATUS.hasEmployee) {
      this.continueWaitingRequests((operation) => {
        operation.setContext((currentContext) => {
          return {
            headers: {
              ...currentContext.headers,
              "X-User-Employee": store.getters["auth/activeEmployeeId"],
            },
          };
        });

        if (
          operation.variables.input &&
          operation.variables.input.employeeId === NO_EMPLOYEE_ID
        ) {
          operation.variables.input.employeeId =
            store.getters["auth/activeEmployeeId"];
        }

        if (
          operation.variables &&
          operation.variables.employeeId === NO_EMPLOYEE_ID
        ) {
          operation.variables.employeeId =
            store.getters["auth/activeEmployeeId"];
        }

        return operation;
      });
    } else {
      this.throwWaitingRequests(
        i18n.t(
          "auth.employeeConfirm.employeeConfirmLink.actionNeedsEmployeeError"
        )
      );
    }
  }
}

const isEmployeeAccessNeeded = (store, operation) => {
  const operationContext = operation.getContext();
  if (operationContext.skipEmployeeValidation) {
    return false;
  }

  const operationNeedsEmployee = !!operation?.query?.definitions?.find(
    (def) => def.kind === "OperationDefinition" && def.operation === "mutation"
    // TODO - queries with some metadata should also require employee
  );

  return operationNeedsEmployee && !store.getters["auth/activeEmployeeId"];
};

const startEmployeeAccessFlow = (store) => {
  // Start the new flow.
  store.dispatch("auth/employeeAccessNeeded");
};
