<script>
export default {
  name: "VxFormActions",
  functional: true,
  render(h, context) {
    return h("div", { class: "vx-form-actions" }, context.slots()?.default);
  },
};
</script>

<style lang="scss" scoped>
.vx-form-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 0px;
}
</style>
