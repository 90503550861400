import axios from "axios";
import { camelize } from "@/utils/lib";
import teamAuth from "./teamAuth";
import {
  EMPLOYEE_ACCESS_STATUS,
  NO_EMPLOYEE_ID,
} from "@/store/modules/teamAuth";
import { featureApi } from "@/core-ui";
import { Role, UserType } from "@/constants";

const $http = axios.create({
  baseURL: "/api/vue/auth",
});

if (process.env.NODE_ENV === "development") {
  console.log(
    "\x1b[34m\nUse auth.js for all user and employee authorization.\n\x1b[0m"
  );
}

const proxyWithTeamAuth = (methodCB) => {
  return (...args) => {
    const proxyMethod = methodCB(teamAuth) || (() => {});
    return proxyMethod(...args);
  };
};

const state = () => ({
  accountId: null,
  email: false,
  token: false,
  status: false,
  user_id: false,
  userRole: null,
  userDefaultStoreId: null,
  teamMode: false,
  teamModeLock: false,
  employeeId: NO_EMPLOYEE_ID,
  employeeToken: null,
  employeeLastActivity: false,
  employeeIsManager: false,
  type: null,
  lastEmployee: null,
  lastRoute: null,
  deviceId: null,
  storeId: null,
  freshdeskRestoreId: null,
  freshdeskExternalId: null,
  freshdeskEmail: null,
  // userStoreEmployees
  // key is a store_id
  // value is an employee object for the current user for that store
  userStoreEmployees: {},

  employeeAccessStatus: EMPLOYEE_ACCESS_STATUS.noEmployee,
});

const isAuthenticated = (state) => state.token;

const getters = {
  isAuthenticated,
  isPosUser: (state) => state.type === UserType.POS_USER,
  authPayload: (state) => ({
    email: state.email,
    token: state.token,
  }),
  getAuth: (state) => ({
    email: state.email,
    token: state.token,
    user_id: state.user_id,
    employeeId: state.employeeId,
  }),
  authHeaders: (state) => ({
    "X-User-Email": state.email,
    "X-User-Token": state.token,
    "X-User-Employee": state.employeeId,
    "X-Rai-Device": state.deviceId,
  }),

  // TODO: remove routing from Vuex, should be in router
  defaultRoute: () => location.pathname.split("/").filter(Boolean)[0],
  userRole: (state) => Role[state.userRole?.toUpperCase()] ?? null,
  currentUserDefaultStoreId: (state) => state.userDefaultStoreId,

  // Team auth stuff
  hasStoreEmployee: proxyWithTeamAuth(
    (teamAuth) => teamAuth.getters.hasStoreEmployee
  ),
  isTeamMode: proxyWithTeamAuth((teamAuth) => teamAuth.getters.isTeamMode),
  isTeamModeLocked: proxyWithTeamAuth(
    (teamAuth) => teamAuth.getters.isTeamModeLocked
  ),
  activeEmployeeId: proxyWithTeamAuth(
    (teamAuth) => teamAuth.getters.activeEmployeeId
  ),
  // if the frontend has a storeId use that, otherwise use backend
  activeStoreId: proxyWithTeamAuth(
    (teamAuth) => teamAuth.getters.activeStoreId
  ),
  isManager: proxyWithTeamAuth((teamAuth) => teamAuth.getters.isManager),
  employeeAccessStatus: proxyWithTeamAuth(
    (teamAuth) => teamAuth.getters.employeeAccessStatus
  ),
};

const mutations = {
  setAuth(state, auth) {
    state.token = auth.token;
    state.email = auth.email;
    state.status = auth.status;
    state.user_id = auth.user_id;
    state.userRole = auth.userRole;
    state.userEmployeeId = auth.employeeId;
    state.userDefaultStoreId = auth.defaultStoreId;
    state.type = auth.type;
    state.employeeLastActivity = auth.employeeLastActivity;
    state.userStoreEmployees = auth.userStoreEmployees;
    state.accountId = auth.accountId;
    state.freshdeskExternalId = auth.freshdeskExternalId;
    state.freshdeskRestoreId = auth.freshdeskRestoreId;
    state.freshdeskEmail = auth.freshdeskEmail;
  },
  setAuthToken(state, token) {
    if (token) state.token = token;
  },
  setAuthEmail(state, email) {
    if (email) state.email = email;
  },
  setAuthStatus(state, status) {
    if (status) state.status = status;
  },
  setDeviceId(state, deviceId) {
    if (deviceId) state.deviceId = deviceId;
  },
  clearAuth(state) {
    state.email = null;
    state.token = null;
    state.status = null;
    state.user_id = null;
    state.userRole = null;
    state.userEmployeeId = null;
    state.userDefaultStoreId = null;
    state.employeeId = NO_EMPLOYEE_ID;
    state.employeeIsManager = false;
    state.employeeToken = null;
    state.employeeLastActivity = null;
    state.teamMode = false;
    state.teamModeLock = false;
    state.type = null;
    state.deviceId = null;
    state.storeId = null;
    state.userStoreEmployees = {};
    localStorage.removeItem("x-rai-device");
    localStorage.removeItem("rai");
  },

  // Team auth stuff
  setLastRoute: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.setLastRoute
  ),
  // This mutation should be called on store change.
  setActiveStore: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.setActiveStore
  ),
  clearEmployee: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.clearEmployee
  ),
  setEmployeeId: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.setEmployeeId
  ),
  setEmployeeIsManager: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.setEmployeeIsManager
  ),
  setEmployeeLastActivity: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.setEmployeeLastActivity
  ),
  setTeamMode: proxyWithTeamAuth((teamAuth) => teamAuth.mutations.setTeamMode),
  setTeamModeLock: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.setTeamModeLock
  ),
  setLastEmployee: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.setLastEmployee
  ),
  setEmployeeAccessStatus: proxyWithTeamAuth(
    (teamAuth) => teamAuth.mutations.setEmployeeAccessStatus
  ),
  setFreshdeskRestoreId(state, restoreId) {
    state.freshdeskRestoreId = restoreId;
  },
};

const clearAll = (commit) => {
  commit("user/clearUser", null, {
    root: true,
  });
  commit("clearAuth");
};

const updateFeatureFlags = async (authHeaders) => {
  const http = axios.create({
    baseURL: "",
    headers: {
      ...authHeaders,
    },
  });
  const response = await http.get("api/features");
  response.data.forEach((featureFlag) => {
    featureApi.visibility(
      featureFlag.feature,
      featureFlag.variant,
      featureFlag.value
    );
  });
};

const actions = {
  async refresh({ dispatch }) {
    return await dispatch("authenticate", {
      refresh: true,
    });
  },
  // Takes a credentials payload of format
  // { email: '', password: ''}
  async authenticate(
    { dispatch, commit, getters },
    { credentials, refresh = false }
  ) {
    // If this is a `refresh`, but authHeaders values are falsey,
    // return early. This is so we can call +auth/refresh+ anywhere,
    // without having to worry about getting back a 401 when we shouldn't
    // really be calling +auth/refresh+ in the first place.
    if (refresh && !Object.values(getters.authHeaders).filter(Boolean).length)
      return true;

    // If this is a `refresh`, just GET auth, otherwise POST auth
    const params = refresh
      ? {
          headers: getters.authHeaders,
        }
      : credentials;
    const method = refresh ? "get" : "post";
    try {
      const { data } = await $http[method]("", params);
      await dispatch("postLoginTasks", data);
      return true;
    } catch (error) {
      console.log("Error occurred during authenticate", error);
      clearAll(commit);
      throw error;
    }
  },
  tokenAuth({ dispatch, commit, getters }, { email, token }) {
    return new Promise((resolve, reject) => {
      commit("setAuthEmail", email);
      commit("setAuthToken", token);
      $http
        .get("", {
          headers: getters.authHeaders,
        })
        .then((resp) => {
          return dispatch("postLoginTasks", resp.data);
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          commit("clearAuth");
          reject(error);
        });
    });
  },
  signOut({ commit }) {
    return new Promise((resolve) => {
      clearAll(commit);
      resolve(true);
    });
  },
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null);
    return true;
    /*
     * TODO: Good idea to validate the session
    return axios
      .get('/api/session')
      .then((response) => {
        const user = response.data
        commit('SET_CURRENT_USER', user)
        return user
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          commit('SET_CURRENT_USER', null)
        }
        return null
      })
    */
  },

  // Team auth stuff
  enableTeamMode: proxyWithTeamAuth(
    (teamAuth) => teamAuth.actions.enableTeamMode
  ),
  employeeAccessNeeded: proxyWithTeamAuth(
    (teamAuth) => teamAuth.actions.employeeAccessNeeded
  ),
  employeeAccessNoEmployee: proxyWithTeamAuth(
    (teamAuth) => teamAuth.actions.employeeAccessNoEmployee
  ),
  employeeAccessHasEmployee: proxyWithTeamAuth(
    (teamAuth) => teamAuth.actions.employeeAccessHasEmployee
  ),
  lockTeamMode: proxyWithTeamAuth((teamAuth) => teamAuth.actions.lockTeamMode),
  teamModeAuth: proxyWithTeamAuth((teamAuth) => teamAuth.actions.teamModeAuth),

  // Commits
  //   - auth
  //   - user
  //   - team mode
  //   - availableStores
  //   - defaultStore
  async postLoginTasks({ commit, dispatch, getters }, user) {
    try {
      /*
        // TODO: we need an alternative to enableTeamMode like enableSingleUser
        //       that is where we should set the employee.isManager, etc
        //       also, it doesn't appear we are using employeeToken at all, but we need to
      }

      */

      commit("setAuth", {
        email: user.email,
        token: user.authentication_token,
        status: true,
        user_id: user.id,
        userRole: user.role,
        type: user.type || "PosUser",
        employeeLastActivity: false,
        userStoreEmployees: camelize(user.user_store_employees),
        defaultStoreId: user.default_store_id,
        accountId: user.account_id,
        freshdeskRestoreId: user.freshdesk_restore_id,
        freshdeskExternalId: user.freshdesk_external_id,
        freshdeskEmail: user.freshdesk_email,
      });

      await updateFeatureFlags(getters.authHeaders);
    } catch (err) {
      console.log("Error occurred during postLoginTasks.", err);
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
