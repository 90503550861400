const inventoryRoute = {
  components: {
    default: () => import("@/views/InventoryListView"),
    appbar: () => import("@/views/InventoryListView/InventoryAppBar"),
    dialog: () => import("@/views/Common/RouterDefaultViewWrapper"),
    bardcodeScan: () => import("@/views/InventoryListView/InventoryScan"),
  },
  meta: {
    requiresAuth: true,
    title: "Inventory",
  },
  children: [
    {
      name: "inventory_home",
      path: "",
    },
    {
      name: "inventory_show",
      path: "detail/show/:inventoryItemId",
      component: () => import("@/views/InventoryListView/InventoryDetailView"),
    },
  ],
};

export { inventoryRoute };
