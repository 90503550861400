export const SetupType = {
  PENDING: "pending",
  WARNING: "warning",
  COMPLETE: "complete",
  INFO: "info",
};

export const SetupTypeConfig = {
  pending: {
    icon: "$clipboardClockOutline",
  },
  warning: {
    icon: "$alert",
    color: "warning",
  },
  complete: {
    icon: "$checkCircle",
    color: "success",
  },
  info: {
    icon: "$info",
    color: "info",
  },
};
