import parseISO from "date-fns/parseISO";
import formatISO from "date-fns/formatISO";
import { isString, isNumber, isDate } from "lodash";

/**
 * @typedef {Object} DatePart
 * @property {String} COMPLETE - both date and time
 * @property {String} DATE - only date without time
 * @property {String} TIME - only time with local time zone
 */

export const DatePart = {
  COMPLETE: "complete",
  DATE: "date",
  TIME: "time",
};

/**
 * @param {number|String|Date} value - defined as instance of Date, string or total milliseconds since beginning of time
 * @param {DatePart} part - option for defining which part of ISO string to return
 * @returns {String} resolved ISO Date string if value is valid or empty string if invalid
 */
export function toISOString(value, part = DatePart.COMPLETE) {
  if (value && (isDate(value) || isNumber(value))) {
    return formatISO(value, { representation: part });
  } else if (value && isString(value)) {
    return toISOString(parseISO(value), part);
  }

  return "";
}
