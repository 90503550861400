import { trim, pickBy, isEmpty } from "lodash";

export function buildAdvancedSearchText(filterAsObject) {
  return (
    "advanced: " +
    Object.keys(filterAsObject)
      .reduce((acc, current) => {
        acc.push(`${current}:${filterAsObject[current]}`);
        return acc;
      }, [])
      .join(", ")
  );
}

export function buildFilterQueryString(searchValue) {
  const searchValueParsed = pickBy(searchValue, (value, key) => {
    // Explicitly check for cases when we want to clear the value, as we need to support booleans!
    return value !== "" && value !== null && value !== undefined;
  });

  let stringSearchValue = undefined;
  if (!isEmpty(searchValueParsed)) {
    stringSearchValue = trim(JSON.stringify(searchValueParsed));
  }

  return !!stringSearchValue ? stringSearchValue : undefined;
}

export function parseFilter(filterRaw) {
  if (!filterRaw) {
    // If the filter was not present, return that info
    return {
      filterPresent: false,
      isAdvanced: false,
      data: undefined,
      GQLdata: undefined,
    };
  }

  // If the filter is present, try to parse it
  try {
    const filterAsObject = JSON.parse(filterRaw);
    if (typeof filterAsObject === "object") {
      return {
        filterPresent: true,
        isAdvanced: true,
        data: filterAsObject,
        GQLdata: JSON.stringify(filterAsObject),
      };
    }
  } catch (e) {
    // Nothing to do, but we don't wont to throw exception in this case -> we just know that filter is not an object.
  }

  // If we did not parse filter, just return its raw data
  return {
    filterPresent: true,
    isAdvanced: false,
    data: filterRaw,
    GQLdata: filterRaw,
  };
}
