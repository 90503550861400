import { orderBy, first } from "lodash";

/**
 * @typedef {Object} Notice
 * @property {string|number} id - the id of notice
 * @property {string} text - the text to be shown as notice
 * @property {string} [color] - the color of notice
 * @property {number} priority - the priority in which order should notices show
 * @property {string} [actionText] - the text to be shown on action button
 * @property {function} [action] - the action to be performed on action button click
 */

/**
 * @typedef {Object} NoticeState
 * @property {Notice[]} notices - the array of notices
 */

/**
 * @type {NoticeState}
 */
const state = {
  notices: [],
};

/**
 * @type {{ [key: string]: (state: NoticeState) => any }}
 */
const getters = {
  nextNotice: (state) =>
    first(orderBy(state.notices, (x) => x.priority, ["desc"])),
};

const mutations = {
  /**
   * @param {NoticeState} state
   * @param {Notice} notice
   */
  addNotice(state, notice) {
    state.notices.push(notice);
  },
  /**
   * @param {NoticeState} state
   * @param {string|number} noticeId
   */
  clearNotice(state, noticeId) {
    state.notices = state.notices.filter((x) => x.id !== noticeId);
  },
};

const actions = {
  addNotice({ commit }, notice) {
    commit("addNotice", notice);
  },
  clearNotice({ commit }, noticeId) {
    commit("clearNotice", noticeId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
