export const props = {
  groupTitle: {
    type: String,
    default: "",
  },
  columnCssClasses: {
    type: Array,
    default: () => [],
  },
};
