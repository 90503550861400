import Vue from "vue";
import { hasDefinedProp } from "../utils";

export function useForm() {
  return Vue.extend({
    inject: {
      form: {
        // ? -> provided by VxForm after extending VForm
        default: {},
      },
    },
    props: {
      outlined: {
        type: Boolean,
        default: false,
      },
      persistentPlaceholder: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isOutlined() {
        /* istanbul ignore next: hasDefinedProp is tested */
        return hasDefinedProp(this, "outlined")
          ? this.outlined
          : this.outlined || this.form.outlined;
      },
      isPersistentPlaceholder() {
        /* istanbul ignore next: hasDefinedProp is tested */
        return hasDefinedProp(this, "persistentPlaceholder")
          ? this.persistentPlaceholder
          : this.persistentPlaceholder || this.form.outlined;
      },
    },
  });
}
