import { info } from "./info";
import { confirm } from "./confirm";

const DialogPlugin = {
  /**
   * Defines $dialog object on Vue instance
   * @param {import("vue").VueConstructor} Vue
   * @param {{ vuetify: import("vuetify/lib").default}} args
   */
  install(Vue, args = { vuetify: null }) {
    if (!args.vuetify) {
      throw new Error(
        "Vuetify instance is required for dialog plugin to work correctly."
      );
    }

    const dialog = {
      info: (options) => info(Vue, args, options),
      confirm: (options) => confirm(Vue, args, options),
    };

    Vue.prototype.$dialog = dialog;
  },
};

export { DialogPlugin };
export default DialogPlugin;
