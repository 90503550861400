import Vue from "vue";
import { findIndex, clone } from "lodash";

export default {
  showUnreadMessagesBubble(state) {
    const i = findIndex(state.side_menu_items, { title: "Texting" });
    const item = clone(state.side_menu_items[i]);
    item.bubble = true;
    Vue.set(state.side_menu_items, i, item);
  },
  hideUnreadMessagesBubble(state) {
    const i = findIndex(state.side_menu_items, { title: "Texting" });
    const item = clone(state.side_menu_items[i]);
    item.bubble = false;
    Vue.set(state.side_menu_items, i, item);
  },
  toggleDrawer(state) {
    state.drawerExpanded = !state.drawerExpanded;
    state.drawerMobile = !state.drawerMobile;
  },
  setUnreadMessagesExist(state, bool) {
    state.unreadMessagesExist = bool;
  },
  setRehydrated(state) {
    state.rehydrated = true;
  },
};
