import store from "@/store";
import pusher from "@/pusher-config";

// Must implement the entire raiPos API
// methods should either proxy to available POS systems
// or reject the promise with relevant errors
// PosProxy can also be used to mock raiPos in development
export class PosProxy {
  constructor(actionErrorCallback = () => {}) {
    if (!(this instanceof PosProxy)) {
      return new PosProxy();
    }
    this._timecheck = Date.now();
    this._type = "PosProxy";
    this._actionErrorCallback = actionErrorCallback;
    this.isPosProxy = true;

    console.log("PosProxy initialized");

    // enable passing calls to local tablet extension
    if (window.hasOwnProperty("raiTabletAsync")) {
      this.raiTablet = window.raiTabletAsync;
    } else {
      this.raiTablet = {};
    }

    this._lastActionId = 0;
    this._returnDataPromises = {};

    // initialize listener for pos responses
    this.initializeResponseListener();

    // if we already have a device, we are good to go
    if (store.state.ui.printer) {
      return this;
    }

    // otherwise, we need to try and find a device
    const startAt = new Date().getTime();
    const threshold = 1000 * 60 * 5;
    let interval = setInterval(() => {
      const elapsedTime = new Date().getTime() - startAt;

      // Guard -> Don't try this longer than 5 minutes
      if (elapsedTime > threshold) return clearInterval(interval);

      // Guard -> If we have set a printer already, clear
      if (store.state.ui.printer) return clearInterval(interval);

      // Guard -> We ain't gonna do nothin' if we ain't got a channel or members!
      if (!this.channel()) return;
      if (!this.channel().members.count) return;

      const devices = this.availablePosDevices();
      store.commit(
        "ui/setPrinter",
        (devices.length && devices[0]) || undefined
      );
    }, 200);

    return this;
  }

  async initializeResponseListener() {
    // Do this in intervals, because we need to get channels (which does not happen immediately)
    const startAt = new Date().getTime();
    const threshold = 1000 * 60 * 5;
    let interval = setInterval(async () => {
      const elapsedTime = new Date().getTime() - startAt;

      // Guard -> Don't try this longer than 5 minutes
      if (elapsedTime > threshold) {
        return clearInterval(interval);
      }

      // Guard -> We ain't gonna do nothin' if we ain't got a channel or members!
      if (!this.channel()) return;
      if (!this.channel().members.count) return;

      // TODO - we will need to ensure that each device has it own unique name!
      const currentDeviceName = await this.fromName();

      if (currentDeviceName) {
        this.channel().bind(
          `client-proxy:tablet-${currentDeviceName}-message`,
          async (data) => {
            const parsed = typeof data === "string" ? JSON.parse(data) : data;
            const { result, error, actionId } = parsed;

            const returnDataPromise = this._returnDataPromises[actionId];
            if (returnDataPromise) {
              if (!error) {
                clearTimeout(returnDataPromise.timeoutTimer);
                returnDataPromise.resolve(result);
              } else {
                clearTimeout(returnDataPromise.timeoutTimer);
                returnDataPromise.reject(error);
              }
            }

            delete this._returnDataPromises[actionId];

            return result;
          }
        );
        return clearInterval(interval);
      }
    });
  }

  // Triggers a client event on the presence channel
  async trigger({ action, payload }, timeout = 10000) {
    return new Promise((resolve, reject) => {
      // ensure we have a device to send to
      if (!this.printer()) {
        reject(new Error("No POS selected"));
        return;
      }
      if (!this.printerName()) {
        reject(new Error("Unable to determine POS name"));
        return;
      }

      const actionId = this.getNewActionId();

      // set a timeout for the request
      const timeoutTimer = setTimeout(() => {
        delete this._returnDataPromises[actionId];
        reject(
          new Error(
            `Did not receive response from ${this.printerName()}. Timed out after ${timeout}ms`
          )
        );
      }, timeout);

      const destination = this.destinationChannel(this.printerName());

      // trigger the event
      const triggerSuccess = this.channel().trigger(destination, {
        action,
        from: this.fromName(),
        payload,
        actionId,
      });

      // if successful, add it to be resolved later
      // otherwise reject the promise and clear the timeout
      if (triggerSuccess) {
        this._returnDataPromises[actionId] = {
          actionId,
          resolve,
          reject,
          timeoutTimer,
        };
      } else {
        clearTimeout(timeoutTimer);
        this._actionErrorCallback({ name: this.printerName() });
        reject(new Error(`Could not send request to ${this.printerName()}`));
      }
    });
  }

  // public methods that implement the raiPos API
  // TODO: convert to async everywhere
  // TODO: add support for the rest of the API
  raiPosVersion() {
    return process.env.VUE_APP_VERSION;
  }

  async betaMode() {
    return false;
  }

  async computerName() {
    if (window.raiFlexName) return window.raiFlexName;
    if (this.raiTablet.computerName) {
      return JSON.parse(this.raiTablet.computerName());
    }

    throw "Error evaluating `computerName`";
  }

  async autoLogin() {
    if (this.raiTablet.hasOwnProperty("autoLogin")) {
      return JSON.parse(this.raiTablet.autoLogin());
    }
  }

  async setToken(token, email) {
    if (this.raiTablet.hasOwnProperty("setToken")) {
      return this.raiTablet.setToken(token, email);
    }
  }

  async closeWindow() {
    if (this.raiTablet.hasOwnProperty("closeWindow")) {
      this.raiTablet.writeLog("closing window");
      return this.raiTablet.closeWindow();
    }
  }

  async writeLog(logMsg) {
    if (this.raiTablet.hasOwnProperty("writeLog")) {
      return this.raiTablet.writeLog(logMsg);
    }
  }

  async checkIn(buy, customer, loyalty) {
    return this.trigger({
      action: "checkIn",
      payload: { buy, customer, loyalty },
    });
  }

  async printReceipt(buyJSON, customerJSON) {
    return this.trigger({
      action: "printReceipt",
      payload: { buyJSON, customerJSON },
    });
  }

  async printTestPage() {
    return this.trigger({ action: "printTestPage" });
  }

  printer() {
    return store.state.ui.printer;
  }

  printerName() {
    if (!this.printer()) throw "Error in `printerName`; No printer selected";

    return formatName(this.printer().name);
  }

  printerDisplayName() {
    if (!this.printer())
      throw "Error in `printerDisplayName`; No printer selected";

    return this.printer().displayName;
  }

  setPrinter(printer) {
    try {
      store.commit("ui/setPrinter", printer);
    } catch (error) {
      console.error(error);
    }
  }

  availablePosDevices() {
    const devices = [];
    this.channel().members.each(({ info }) => {
      if (
        info &&
        info.type === "PosUser" &&
        info.name &&
        info.name.match(/pos/i) &&
        formatName(info.name) !==
          formatName(this.channel().members.me.info.name)
      ) {
        devices.push({
          displayName: info.name,
          name: formatName(info.name),
          role: info.role,
          type: info.type,
        });
      }
    });

    return devices.sort(
      (a, b) => (a.name < b.name && -1) || (a.name > b.name && 1) || 0
    );
  }

  // private
  channel() {
    return pusher.channel(store.getters["sockets/storePresenceName"]);
  }

  destinationChannel(posName) {
    return `client-proxy:pos-${posName}-message`;
  }

  fromName() {
    return formatName(this.channel().members.me.info.name);
  }

  getNewActionId() {
    return new Date().getTime().toString() + `_${this._lastActionId++}`;
  }
}

PosProxy.prototype.verify = function () {
  return "valid!";
};

// Called from components/RightMenu.vue
PosProxy.prototype.computerName = function () {
  console.log("computerName called");
  return new Promise((resolve) => {
    if (window.raiFlexName) {
      return resolve(window.raiFlexName);
    }
    console.log("computerName proxied");
    if (store.getters) return resolve(store.getters["user/getUser"].full_name);
    if (this.channel()) {
      const {
        info: { name },
      } = this.channel().members.me;
      return resolve(name);
    }
    throw new Error("No user name found");
  });
};

// Called from components/coupons/Coupons.vue
PosProxy.prototype.printBounceBack = (coupon) => {
  return new Promise((resolve) => {
    console.error("PosProxy Not Implemented: printBounceBack");
    resolve(true);
  });
};

const formatName = (name) => {
  if (!name || typeof name !== "string") return name;

  return name.split(" ").join("").toLowerCase();
};
