<script>
import { VxBtn } from "../../vx";
import { getSlot, hasSlot } from "../../../utils";
import { VSkeletonLoader, VExpansionPanelContent, VDivider } from "vuetify/lib";

export default {
  name: "RaiSetupContent",
  props: {
    nextText: {
      type: String,
      default: "$vuetify.rai.setup.nextText",
    },
    nextDisabled: {
      type: Boolean,
      default: false,
    },
    nextLoading: {
      type: Boolean,
      default: false,
    },
    nextEmphasis: {
      type: String,
      default: "text",
      validator: (value) => {
        return ["contained", "outlined", "text"].indexOf(value) !== -1;
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: true,
    },
    hideNext: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return {
        "rai-setup-content pt-4": true,
        "rai-setup-content--loading": this.loading,
      };
    },
    nextProps() {
      return {
        outlined: this.nextEmphasis === "outlined",
        text: this.nextEmphasis === "text",
        disabled: this.nextDisabled,
        loading: this.nextLoading,
      };
    },
  },
  methods: {
    next(e) {
      this.$emit("next", e);
    },
  },
  render(h) {
    const Loading =
      getSlot(this, "loading") ||
      h(VSkeletonLoader, { props: { type: "paragraph" } });

    const Extension = h(
      "div",
      { class: "rai-setup-content__extension text--secondary" },
      [getSlot(this, "extension"), h(VDivider, { class: "my-4" })]
    );

    const Text = h(
      "div",
      { class: "rai-setup-content__text text--primary" },
      getSlot(this)
    );

    const NextAction = h("div", { class: "rai-setup-content__action" }, [
      getSlot(this, "action", { next: this.next }) ||
        h(
          VSkeletonLoader,
          { props: { type: "button", loading: this.loading } },
          [
            h(
              VxBtn,
              { props: this.nextProps, on: { click: this.next } },
              this.$vuetify.lang.t(this.nextText)
            ),
          ]
        ),
    ]);

    return h(
      VExpansionPanelContent,
      { class: this.classes, props: { eager: this.eager } },
      [
        this.loading && Loading,
        !this.loading && hasSlot(this, "extension") && Extension,
        !this.loading && Text,
        !this.hideNext && h(VDivider, { class: "my-4" }),
        !this.hideNext && NextAction,
      ]
    );
  },
};
</script>

<style lang="scss">
.rai-setup-content__action {
  display: flex;
  justify-content: flex-end;
}
</style>
