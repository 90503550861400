// import * as sockets from '@/utils/sockets'

const state = () => ({});

const getters = {
  storeChannelName: (_state, _getters, rootState, rootGetters) => {
    const accountId = rootState.auth.accountId;
    const storeId = rootGetters["auth/activeStoreId"];
    return `private-store-channel-${accountId}-${storeId}`;
  },
  storePresenceName: (_state, _getters, rootState, rootGetters) => {
    const accountId = rootState.auth.accountId;
    const storeId = rootGetters["auth/activeStoreId"];
    return `presence-store-channel-${accountId}-${storeId}`;
  },
};

const actions = {};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
