<script>
import VxConfirmDialog from "../VxDialog/VxConfirmDialog.vue";
import { VToolbarTitle, VIcon } from "vuetify/lib";
import { useModelValue } from "../../../mixins";
import { props } from "../VxDialog/VxDialogBase";

export default {
  name: "VxBlockingError",
  components: {
    VxConfirmDialog,
    VToolbarTitle,
    VIcon,
  },
  mixins: [useModelValue()],
  inheritAttrs: false,
  props: {
    ...props,
    okText: {
      type: String,
      default: "$vuetify.vx.blockingError.defaultPrimaryText",
    },
    okColor: {
      type: String,
      default: "primary",
    },
    okDisabled: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "$vuetify.vx.blockingError.defaultSecondaryText",
    },
    cancelColor: {
      type: String,
      default: "grey",
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: [String, Boolean],
      default: false,
    },
    hSize: {
      type: String,
      default: "xsmall",
    },
    cancelRefresh: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleShow() {
      this.modelValue = true;
      this.$emit("show");
    },
    handleHide() {
      this.modelValue = false;
      this.$emit("hide");
    },
    handlePrimaryClick(e) {
      this.$emit("ok", e);
      !this.cancelRefresh && window.location.reload();
      if (e.defaultPrevented) return;
      this.$refs.dialog.hide();
    },
    handleSecondaryClick(e) {
      this.$emit("cancel", e);
      if (e.defaultPrevented) return;
      this.$refs.dialog.hide();
    },
  },
};
</script>

<template>
  <VxConfirmDialog
    ref="dialog"
    v-model="modelValue"
    v-bind="{ ...$props, noBack: true }"
    @show="handleShow"
    @hide="handleHide"
    @ok="handlePrimaryClick"
    @cancel="handleSecondaryClick"
  >
    <slot></slot>

    <template v-slot:toolbarTitle>
      <v-icon small left color="error">$error</v-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </template>
  </VxConfirmDialog>
</template>
