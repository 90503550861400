<script>
import { props } from "./VxDialogBase";
import { hasSlot } from "../../../utils";
import { useModelValue } from "../../../mixins";
import {
  VCard,
  VCardActions,
  VCardTitle,
  VCardText,
  VDialog,
  VToolbar,
  VToolbarTitle,
  VBtn,
  VIcon,
  VSpacer,
} from "vuetify/lib";

export default {
  name: "VxDialogViewMobile",
  components: {
    VCard,
    VCardActions,
    VCardTitle,
    VCardText,
    VDialog,
    VToolbar,
    VToolbarTitle,
    VBtn,
    VIcon,
    VSpacer,
  },
  mixins: [useModelValue()],
  inheritAttrs: false,
  props,
  methods: {
    hasSlot(slotName) {
      return hasSlot(this, slotName);
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="modelValue"
    scrollable
    fullscreen
    :eager="eager"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="innerDialog">
      <v-card class="vx-dialog-view--card--mobile fill-height">
        <slot name="toolbar">
          <v-card-title class="pt-0 pl-0 pr-0">
            <v-toolbar
              dense
              flat
              :color="errorToolbar ? 'error' : toolbarColor"
            >
              <v-btn v-if="!noBack" icon @click="$emit('back')">
                <v-icon>$back</v-icon>
              </v-btn>

              <slot name="toolbarTitle">
                <v-toolbar-title class="mr-auto">{{ title }}</v-toolbar-title>
              </slot>

              <slot name="smallAction"></slot>

              <template #extension>
                <slot name="toolbarExtension"></slot>
              </template>
            </v-toolbar>
          </v-card-title>
        </slot>

        <v-card-text>
          <slot></slot>
        </v-card-text>

        <div v-if="hasSlot('actions')" class="large-actions-wrapper">
          <v-card-actions>
            <v-spacer v-if="alignActionsRight"></v-spacer>
            <slot name="actions"></slot>
          </v-card-actions>
        </div>
      </v-card>
    </slot>
  </v-dialog>
</template>

<style lang="scss">
.v-toolbar__title {
  text-overflow: ellipsis;
}

.vx-dialog-view--card--mobile {
  position: relative;
}

.large-actions-wrapper {
  border-top: 1px solid #000000;
}
</style>
