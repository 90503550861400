export default {
  visible: function (el, binding) {
    var value = binding.value;
    if (!!value) {
      el.style.visibility = "visible";
    } else {
      el.style.visibility = "hidden";
    }
  },
};
