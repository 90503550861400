export const UserType = Object.freeze({
  USER: "User",
  POS_USER: "PosUser",
});

export const Role = Object.freeze({
  USER: 0,
  MANAGER: 1,
  DISTRICT: 2,
  OWNER: 3,
  SUPPORT: 4,
});

export const roles = [
  { text: "User", value: Role.USER },
  { text: "Manager", value: Role.MANAGER },
  { text: "District manager", value: Role.DISTRICT },
  { text: "Account Owner", value: Role.OWNER },
  { text: "Support User", value: Role.SUPPORT },
];
