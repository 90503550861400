<script>
import { DialogSize } from "./constants";
import { useModelValue } from "../../../mixins";
import { props } from "./VxDialogBase";
import {
  VCard,
  VCardActions,
  VCardTitle,
  VCardText,
  VDialog,
  VToolbar,
  VToolbarTitle,
  VBtn,
  VIcon,
  VSpacer,
} from "vuetify/lib";

export function convertSize(value) {
  switch (value) {
    case DialogSize.HUGE:
      return "90vw";
    case DialogSize.LARGE:
      return "1100";
    case DialogSize.MEDIUM:
      return "800";
    case DialogSize.SMALL:
      return "500";
    case DialogSize.XSMALL:
      return "400";
    default:
      return "800";
  }
}

export default {
  name: "VxDialogViewDesktop",
  components: {
    VCard,
    VCardActions,
    VCardTitle,
    VCardText,
    VDialog,
    VToolbar,
    VToolbarTitle,
    VBtn,
    VIcon,
    VSpacer,
  },
  mixins: [useModelValue()],
  inheritAttrs: false,
  props: {
    ...props,
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    maxWidth() {
      return this.fullscreen ? undefined : convertSize(DialogSize.HUGE);
    },
    width() {
      return this.fullscreen ? undefined : convertSize(this.hSize);
    },
    sizeClasses() {
      return {
        [`size-v-${this.vSize}`]: !this.fullscreen,
        [`size-h-${this.hSize}`]: !this.fullscreen,
      };
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="modelValue"
    :fullscreen="fullscreen"
    :max-width="maxWidth"
    :width="width"
    v-bind="$attrs"
    scrollable
    eager
    v-on="$listeners"
  >
    <slot name="innerDialog">
      <div class="desktop-dialog-content" :class="sizeClasses">
        <v-card
          class="d-flex flex-column vx-dialog-view--card--desktop--content"
          :class="sizeClasses"
        >
          <slot name="toolbar">
            <v-card-title class="pt-0 pl-0 pr-0">
              <v-toolbar
                flat
                short
                dense
                :color="errorToolbar ? 'error' : toolbarColor"
              >
                <v-btn v-if="!noBack" icon @click="$emit('back')">
                  <v-icon>$back</v-icon>
                </v-btn>

                <slot name="toolbarTitle">
                  <v-toolbar-title>{{ title }}</v-toolbar-title>
                </slot>

                <template #extension>
                  <slot name="toolbarExtension"></slot>
                </template>
              </v-toolbar>
            </v-card-title>
          </slot>

          <v-card-text>
            <slot></slot>
          </v-card-text>

          <v-card-actions class="mt-auto">
            <v-spacer v-if="alignActionsRight"></v-spacer>
            <slot name="actions"></slot>
            <slot name="smallAction"></slot>
          </v-card-actions>
        </v-card>
      </div>
    </slot>
  </v-dialog>
</template>

<style lang="scss">
.v-card {
  height: 100%;
}

.v-card__text {
  overflow: auto;
  flex-grow: 1;
}

.desktop-dialog-content,
.vx-dialog-view--card--desktop--content {
  &.size-v-huge {
    height: 90vh;
    max-height: 90vh;
  }

  &.size-v-large {
    height: 760px;
    max-height: 90vh;
  }

  &.size-v-medium {
    height: 560px;
    max-height: 90vh;
  }

  &.size-v-small {
    height: 360px;
    max-height: 90vh;
  }

  &.size-v-xsmall {
    height: 260px;
    max-height: 90vh;
  }

  &.size-h-huge {
    width: 90vw;
  }

  &.size-h-large {
    width: 1100px;
  }

  &.size-h-medium {
    width: 800px;
  }

  &.size-h-small {
    width: 500px;
  }

  &.size-h-xsmall {
    width: 400px;
  }
}
</style>
