import { format } from "date-fns";

export const globalCurrentTime = {
  inject: ["globalNow", "globalDay"],
  computed: {
    // Date object for current time within a minute (see interval below)
    CURRENT_TIME() {
      return this.globalNow();
    },
    // formatted string for current day
    CURRENT_DAY() {
      return this.globalDay();
    },
  },
};

export const providesCurrentTime = {
  nowInterval: undefined,
  data: () => ({
    localNow: new Date(),
    // this breaks in US time zones and causes a day off in the evenings
    // it is not a localDay function but rather a UTCDay function
    //localDay: new Date().toISOString().split("T")[0],
    localDay: format(new Date(), "yyyy-MM-dd"),
  }),
  computed: {
    computedNow() {
      return this.localNow;
    },
    computedDay() {
      return this.localDay;
    },
  },
  methods: {
    incrementLocalNow() {
      this.localNow = new Date();
      this.localDay = format(this.localNow, "yyyy-MM-dd");
    },
  },
  mounted() {
    // TODO - think about a minute interval. Why do we need to be precise to a second ?
    // And should be easier on system to calculate more rarely
    // UPDATE: Agreed. Setting to 1 minute interval, this may have been part of gql retry issue
    this.$options.nowInterval = setInterval(this.incrementLocalNow, 60000);
  },
  beforeDestroy() {
    clearInterval(this.$options.nowInterval);
  },
  provide() {
    return {
      globalNow: () => this.computedNow,
      globalDay: () => this.computedDay,
    };
  },
};
