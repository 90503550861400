<script>
import { props } from "./VxDataListGroupBase";

export default {
  name: "VxDataListGroupDesktop",
  props,
};
</script>

<template>
  <tbody>
    <tr v-if="columnCssClasses.length" class="fake-layout-headers">
      <th
        v-for="(columnStyle, index) in columnCssClasses"
        :key="index"
        :class="columnStyle"
      />
    </tr>
    <tr class="group-title">
      <th
        :colspan="columnCssClasses.length"
        class="text--secondary text-uppercase font-weight-medium pl-4"
      >
        {{ groupTitle }}
      </th>
    </tr>
    <slot></slot>
  </tbody>
</template>

<style scoped lang="scss">
.fake-layout-headers {
  visibility: collapse;
}

.group-title {
  cursor: unset;

  &:hover {
    cursor: unset;
  }
}
</style>
