import { PosProxy } from "@/utils/pos-proxy";
import axios from "axios";
const $http = axios.create({
  baseURL: ``,
});

export const setupRaiPos = ({ window, vue, store, pusher }) => {
  // This is POS with the C# extension
  if (window.hasOwnProperty("raiPosAsync")) {
    vue.prototype.$raiPos = window.raiPosAsync;
  }
  // This is an Flex with the Java extension
  else if (window.hasOwnProperty("raiTabletAsync")) {
    vue.prototype.$raiTablet = window.raiTabletAsync;
    vue.prototype.$raiPos = new PosProxy();
  }
  // Otherwise, as long as PosProxy is not disabled via an environment
  // variable and this is still a device user, initialize
  // PosProxy (for tablets)
  // TODO: prevent this from running in production?
  else if (
    process.env.VUE_APP_ENABLE_POSPROXY !== "false" &&
    store.state.auth.type === "PosUser"
  ) {
    vue.prototype.$raiPos = new PosProxy();

    // Grant access to the PosProxy in development
    if (process.env.NODE_ENV === "development") {
      window.$raiPosAPI = vue.prototype.$raiPos;
    }
  }
  // Finally, if this isn't a POS or an PosUser, disable
  // $raiPos
  else {
    vue.prototype.$raiPos = false;

    // Grant access to the PosProxy in development
    // TODO: move to a separate file
    if (
      process.env.NODE_ENV === "development" &&
      window?.localStorage?.getItem("RAI:TEST_POS") == "true"
    ) {
      console.log("CREATING DEV pos proxy");
      vue.prototype.$raiPos = new PosProxy();

      vue.prototype.$raiPos.upsertCustomer = async (
        code,
        firstName,
        lastName,
        phoneNumber,
        email,
        customerId
      ) => {
        await $http.post(
          `api/v3/drs/dev/${window.$raiPosAPI.storeNumber}/upsert_customer`,
          {
            customer_id: customerId,
            drs_customer: {
              code: code,
              fname: firstName,
              lname: lastName,
              ph: phoneNumber,
              e_mail: email,
            },
          }
        );

        return { status: "ok" };
      };

      vue.prototype.$raiPos.createBuy = async (
        code,
        buyId,
        activeEmployeeId
      ) => {
        await $http.post(
          `api/v3/drs/dev/${window.$raiPosAPI.storeNumber}/create_drs_buy`,
          {
            drs_buy: {
              trans_id: 10000,
              // TODO: remove toISOString
              open_date: new Date().toISOString().split("T")[0],
              x_ref_code: code,
            },
            buy_id: buyId,
            employeeId: activeEmployeeId,
          }
        );

        return { status: "ok" };
      };

      vue.prototype.$raiPos.printBuySlips = async (...args) => {
        console.log("print buy slips", args);
        return { status: "ok" };
      };

      vue.prototype.$raiPos.raiPosVersion = () => {
        return "dev";
      };

      window.$raiPosAPI = vue.prototype.$raiPos;
    }
  }
};

export const getRaiPos = ({ window }) => {
  if (!window) return null;

  return (
    window.raiPosAsync ||
    window.raiTabletAsync ||
    window.raiFlex ||
    window.raiPos ||
    false
  );
};

export const lockScreenIfPos = async (store) => {
  // debugger;
  // console.log("locking POS screen");
  // const type = get(JSON.parse(localStorage.getItem("rai")), "auth.type");
  // console.log(`User type: ${type}`);
  const { type } = store.state.auth;
  if (type && type === "PosUser") {
    console.log("Locking TeamMode for PosUser");
    await store.dispatch("auth/lockTeamMode");
  }
};
