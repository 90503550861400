<script>
export default {
  name: "RaiSetupGroup",
  functional: true,
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  render(h, { props, slots }) {
    return h("div", { class: "rai-setup-group mb-2" }, [
      h("div", { class: "rai-setup-group__title text-overline grey--text" }, [
        slots()?.title || props.title,
      ]),
      h("div", { class: "rai-setup-group__content text--primary" }, [
        slots()?.default,
      ]),
    ]);
  },
};
</script>

<style lang="scss">
.rai-setup-group__title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  padding: 16px 0px;
}
</style>
