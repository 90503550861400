<template>
  <VList ref="list">
    <!--
    Show the currently selected employee at the top of the list,
    with a "clearable" icon
    -->
    <template v-if="value">
      <VListItem
        v-if="value"
        class="px-5 pt-0 pb-2 my-0 v-list-item--active"
        color="accent"
      >
        <RaiEmployeeAvatar
          :employee="value"
          list-item
          hide-status
          class="mr-3 my-0"
          size="40"
        />
        <VListItemContent>
          <VListItemTitle v-text="name(value)" />
        </VListItemContent>
        <VListItemAction v-if="clearable">
          <VxBtn icon text x-small @click="onClear">
            <VIcon>$vuetify.icons.clear</VIcon>
          </VxBtn>
        </VListItemAction>
      </VListItem>
      <VDivider />
    </template>
    <VListItem v-if="showFilter">
      <VxTextField
        ref="search"
        v-model="search"
        autofocus
        placeholder="Name, email, intials"
        autocomplete="nope"
        clearable
      />
    </VListItem>
    <VListItem
      v-for="emp in filteredEmployees"
      :key="emp.id"
      style="padding: 5px 20px"
      data-testid="employeeSelectListItem"
      @click="handleSelect(emp)"
    >
      <RaiEmployeeAvatar
        :employee="emp"
        list-item
        hide-status
        size="40"
        class="mr-3 my-0"
      />
      <VListItemContent>
        <VListItemTitle
          data-test-ref="rai-employee-menu__item-name"
          v-text="name(emp)"
        />
      </VListItemContent>
    </VListItem>
  </VList>
</template>

<script>
import { partition, orderBy } from "lodash";
import RaiEmployeeAvatar from "../RaiEmployeeAvatar/RaiEmployeeAvatar.vue";
import { VxTextField, VxBtn } from "../../vx";
import {
  VList,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemAction,
  VDivider,
  VIcon,
} from "vuetify/lib";

export default {
  name: "EmployeeSelectList",
  components: {
    RaiEmployeeAvatar,
    VxTextField,
    VxBtn,
    VList,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VListItemAction,
    VDivider,
    VIcon,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    employees: {
      type: Array,
      default: null,
    },
    hideSearch: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    clearSearch: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    employee: {},
    search: undefined,
  }),
  computed: {
    // Returns the index of `this.value` in `employees`
    // so it can be removed.
    selectedEmployeeIndex() {
      if (!this.value) return -1;
      return (this.employees || []).findIndex((e) => e.id === this.value.id);
    },
    // Does `this.computedEmployees` include `this.value`?
    // Returns true if `this.value` is falsy
    // Otherwise, evaluates normally
    computedEmployeesContainsSelectedEmployee() {
      // If `this.value` is falsy, we want to return `true`
      // so it's not included in `this.computedEmployees`
      if (!this.value) return false;
      return this.selectedEmployeeIndex >= 0;
    },
    // Returns computed employees, excluding `this.value`.
    computedEmployees() {
      const employees = this.employees || [];
      if (!this.computedEmployeesContainsSelectedEmployee) return employees;
      return employees
        .slice(0, this.selectedEmployeeIndex)
        .concat(employees.slice(this.selectedEmployeeIndex + 1));
    },
    employeesOrderedByAction() {
      const parts = partition(this.computedEmployees, (e) => !!e.lastActionAt);
      return parts.reduce(
        (accum, current) =>
          accum.concat(orderBy(current, "lastActionAt", ["desc"])),
        []
      );
      // return _.orderBy(parts[0], "lastActionAt", ["desc"]).concat(parts[1])
    },
    // Filtered by search field
    showFilter() {
      return !this.hideSearch && this.employeesOrderedByAction.length > 5;
    },
    filteredEmployees() {
      if (!this.search || !this.search.length)
        return this.employeesOrderedByAction;
      const regex = new RegExp(`^${this.search}`, "i");
      return this.employeesOrderedByAction.filter(
        (emp) =>
          (emp.fullName && emp.fullName.match(regex)) ||
          (emp.lastName && emp.lastName.match(regex)) ||
          (emp.email && emp.email.match(regex)) ||
          (emp.drsEmployeeCode && emp.drsEmployeeCode.match(regex))
      );
    },
  },
  watch: {
    clearSearch(newVal) {
      if (!newVal) {
        this.search = undefined;
      }
    },
  },
  methods: {
    name(employee) {
      return employee.fullName || "";
    },
    handleSelect(employee) {
      this.$emit("input", employee);
    },
    onClear() {
      return this.$emit("clear");
    },
  },
};
</script>
