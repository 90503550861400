import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { storeRoutes } from "./store-routes.js";
import authRoutes from "./auth-routes.js";
//import devRoutes from "./dev-routes.js";
import onboardingRoutes from "./onboarding-routes.js";
import {
  guardAgainst,
  ensureAuth,
  ensureStoreId,
  setActiveStore,
  checkManagerAccess,
  redirectThroughSplash,
  ensureNotPosUser,
} from "./guards";

Vue.use(VueRouter);

// Top level routes are "Layouts"
// Each layout is a full Vuetify application layout.
const routes = [
  //...devRoutes,
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "splash" */ "@/views/SplashView"),
    meta: {
      requiresAuth: true,
    },
  },
  // onboarding routes
  {
    path: "/onboarding/:storeId",
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */ "@/layouts/OnboardingLayout.vue"
      ),
    meta: {
      // TODO - we should somehow ensure that for ex.
      // requiresManager -> means requiresAuth and requiresStore are also implicitly active
      // requiresStore -> means requiresAuth is implicitly active
      requiresAuth: true,
      requiresStore: true,
      requiresManager: true,
      requiresSplashRedirect: true,
    },
    props: (route) => ({
      storeId: route.params.storeId,
    }),
    children: onboardingRoutes,
  },
  // store routes
  {
    path: "/s/:storeId",
    component: () =>
      import(/* webpackChunkName: "store" */ "@/layouts/StoreLayout"),
    meta: {
      requiresAuth: true,
      requiresStore: true,
      requiresSplashRedirect: true,
    },
    props: (route) => ({
      storeId: route.params.storeId,
    }),
    children: storeRoutes,
  },
  // Authentication
  {
    path: "/auth/",
    component: () => import("@/layouts/AuthLayout"),
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/isAuthenticated"]) {
        next({ path: "/" });
      } else {
        next();
      }
    },
    children: authRoutes,
  },
];

export function createRouter() {
  const router = new VueRouter({
    routes,
  });

  const oldRouterPush = router.push;
  router.push = (...args) => {
    // args[0] is the route where we need to go.
    if (args[0] && !!args[0]["preserveQuery"]) {
      args[0].query = Object.assign(
        {},
        router.currentRoute.query,
        args[0].query
      );
    }
    return oldRouterPush.call(router, ...args);
  };

  const oldRouterReplace = router.replace;
  router.replace = (...args) => {
    // args[0] is the route where we need to go.
    if (args[0] && !!args[0]["preserveQuery"]) {
      args[0].query = Object.assign(
        {},
        router.currentRoute.query,
        args[0].query
      );
    }
    return oldRouterReplace.call(router, ...args);
  };

  router.beforeEach(
    ensureAuth({
      active: guardAgainst("requiresAuth"),
    })
  );
  router.beforeEach(
    ensureStoreId({
      active: guardAgainst("requiresStore"),
    })
  );
  router.beforeEach(
    setActiveStore({
      active: guardAgainst("requiresStore"),
    })
  );
  router.beforeEach(
    checkManagerAccess({
      active: guardAgainst("requiresManager"),
    })
  );

  router.beforeEach(
    ensureNotPosUser({
      active: guardAgainst("requiresNotPosUser"),
    })
  );

  // Easy to disable this, we just remove the requiresSplashRedirect from any route that has it. And this guard will not be used.
  router.beforeEach(
    redirectThroughSplash({
      active: (to, from) => {
        return (
          from === VueRouter.START_LOCATION &&
          guardAgainst("requiresSplashRedirect")(to)
        );
      },
    })
  );

  return router;
}
