<script>
import { getSlot } from "../../../utils";
import { VxBtn } from "../../vx";
import { VIcon } from "vuetify/lib";

export default {
  name: "RaiSwappable",
  data: () => ({
    isSwapped: false,
  }),
  methods: {
    swap() {
      this.isSwapped = !this.isSwapped;
    },
  },
  render(h) {
    const content = this.isSwapped
      ? getSlot(this, "swapped")
      : getSlot(this, "default");
    const icon = this.isSwapped ? "$up" : "$down";

    return h("div", { class: "d-flex" }, [
      content,
      h(VxBtn, { props: { icon: true }, on: { click: this.swap } }, [
        h(VIcon, icon),
      ]),
    ]);
  },
};
</script>
