<template>
  <component
    :is="avatarComponent"
    :id="avatarId"
    class="employee-avatar"
    :size="size"
    :color="color"
    v-on="on"
  >
    <VxTooltip v-if="showTooltip" :activator="`#${avatarId}`" bottom>
      <span>{{ employee.fullName }}</span>
    </VxTooltip>
    <VFadeTransition mode="out-in">
      <VOverlay v-if="loading" absolute opacity="0.2" z-index="0">
        <VProgressCircular indeterminate :size="size - 20" :width="1" />
      </VOverlay>
      <VImg v-else-if="src" :src="src" :alt="alt" />
      <span v-else :class="[textClass, 'white--text']">{{ initials }}</span>
    </VFadeTransition>
  </component>
</template>

<script>
import { defineComponent, computed } from "vue";
import { VxTooltip } from "../../vx";
import {
  VAvatar,
  VListItemAvatar,
  VFadeTransition,
  VOverlay,
  VProgressCircular,
  VImg,
} from "vuetify/lib";
import { convertToUnit } from "vuetify/es5/util/helpers";

import { makeTextProps, useText } from "../../../utils/text";

/**
 * @param {String} fullName - The full name of the person.
 * @returns The initials of the full name.
 */
function createInitials(fullName) {
  return fullName
    .split(" ")
    .map((x) => x.charAt(0).toUpperCase())
    .filter((x) => /[A-Z0-9]/.test(x))
    .slice(0, 3)
    .join("")
    .toUpperCase();
}

export default defineComponent({
  name: "EmployeeAvatar",
  inheritAttrs: false,
  components: { VxTooltip, VFadeTransition, VOverlay, VProgressCircular, VImg },
  props: {
    size: {
      type: [Number, String],
      default: 40,
    },
    statusSize: {
      type: [Number, String],
      default: 16,
    },
    srcUrl: {
      type: String,
      default: "",
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    listItem: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    employee: {
      type: Object,
      required: true,
    },
    ...makeTextProps({ text: "overline" }),
  },
  setup(props, { listeners }) {
    const { textClass } = useText(props);

    const avatarId = computed(() => `employee-avatar-${props.employee.id}`);

    const avatarComponent = computed(() => {
      return props.listItem ? VListItemAvatar : VAvatar;
    });

    const avatarVars = computed(() => ({
      statusSize: convertToUnit(props.statusSize),
    }));

    const showTooltip = computed(() => {
      return props.tooltip && props.employee.fullName;
    });

    const src = computed(() => props.employee.avatarUrl ?? props.srcUrl);

    const alt = computed(() => `${props.employee.fullName}'s avatar`);

    const color = computed(() => {
      if (!props.employee.id) return "ui";
      return props.employee.avatarUrl || props.employee.drsEmployeeCode
        ? "primary lighten-1"
        : "secondary lighten-2";
    });

    const initials = computed(() => {
      const { drsEmployeeCode, fullName } = props.employee;
      if (drsEmployeeCode) return drsEmployeeCode;
      return fullName ? createInitials(fullName) : "N / A";
    });

    const on = computed(() => {
      return listeners;
    });

    return {
      avatarId,
      avatarVars,
      avatarComponent,
      textClass,
      showTooltip,
      src,
      alt,
      color,
      initials,
      on,
    };
  },
});
</script>

<style lang="scss" scoped>
.employee-avatar.v-avatar,
.employee-avatar.v-list-item__avatar {
  position: relative;
  justify-content: center;
  overflow: visible;
  transition: background-color 0.2s ease-in-out;
}
</style>
