<script>
import { props } from "./VxDataListHeaderBase";
import VxDataListHeaderMobile from "./VxDataListHeaderMobile.vue";
import VxDataListHeaderDesktop from "./VxDataListHeaderDesktop.vue";
import { defineResponsiveComponent } from "../../../utils/component";

export default defineResponsiveComponent({
  name: "VxDataListHeader",
  desktop: VxDataListHeaderDesktop,
  mobile: VxDataListHeaderMobile,
  props,
});
</script>
