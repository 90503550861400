import { parseFilter } from "@/utils/filter";

export default [
  {
    name: "team.home",
    path: "team",
    components: {
      default: () => import("@/views/Team/AppView"),
      appbar: () => import("@/views/Team/AppView/TeamAppBar.vue"),
    },
    meta: {
      requiresAuth: true,
    },
    props: {
      default: (route) => ({
        filter: route.query.filter,
        parsedFilter: parseFilter(route.query.filter),
        storeId: route.params.storeId,
      }),
      appbar: (route) => ({
        filter: route.query.filter,
        parsedFilter: parseFilter(route.query.filter),
      }),
    },
  },
];
