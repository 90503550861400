import { extend } from "vee-validate";
import { messages } from "vee-validate/dist/locale/en.json";
import { naPhone } from "@/core-ui";
import {
  required,
  required_if,
  confirmed,
  email,
  min_value,
  max_value,
  numeric,
  max,
  min,
  length,
  integer,
} from "vee-validate/dist/rules";
/**
 * Add new VeeValidate rule
 * @param {String} name - rule name with snake_case convention
 * @param {import("vee-validate/dist/types/types").ValidationRule} rule - VeeValidate validation rule
 */
const addRule = (name, rule) => {
  return extend(name, { ...rule, message: messages[name] ?? rule.message });
};

addRule("email", email);
addRule("confirmed", confirmed);
addRule("required", required);
addRule("required_if", required_if);
addRule("max_value", max_value);
addRule("min_value", min_value);
addRule("numeric", numeric);
addRule("na_phone", naPhone);
addRule("max", max);
addRule("min", min);
addRule("length", length);
addRule("integer", integer);
addRule("winmark_number", {
  message: "{_field_} must be a valid Winmark number",
  validate: (value) => /^[12468][0-9]{4}$/.test(value),
});
addRule("integer_goal", {
  message: "{_field_} must be a whole number.",
  validate: integer.validate,
});
