<script>
import { VTextField } from "vuetify/lib";
import { useForm, useModelValue, useValidationProvider } from "../../../mixins";

export default {
  name: "VxTextField",
  components: {
    VTextField,
  },
  mixins: [useForm(), useModelValue(), useValidationProvider()],
  inheritAttrs: false,
  computed: {
    isNumber() {
      return this.$attrs.type === "number";
    },
    classes() {
      return {
        "vx-text-field--number": this.isNumber,
      };
    },
  },
  methods: {
    focus() {
      this.$refs.textField.focus();
    },
    validator() {
      return this.$refs.provider;
    },
  },
};
</script>

<template>
  <ValidationProvider
    ref="provider"
    :vid="name"
    :name="label"
    :rules="rules"
    :mode="mode"
    slim
  >
    <template v-slot="{ errors }">
      <VTextField
        ref="textField"
        v-model="modelValue"
        :name="name"
        :label="label"
        :error-messages="errors"
        :outlined="isOutlined"
        :persistent-placeholder="isPersistentPlaceholder"
        v-bind="$attrs"
        :class="classes"
        v-on="$listeners"
      >
        <template v-slot:append>
          <slot name="append"></slot>
        </template>
        <template v-slot:append-outer>
          <slot name="append-outer"></slot>
        </template>
        <template v-slot:label>
          <slot name="label"></slot>
        </template>
        <template v-slot:prepend>
          <slot name="prepend"></slot>
        </template>
        <template v-slot:prepend-inner>
          <slot name="prepend-inner"></slot>
        </template>
        <template v-slot:progress>
          <slot name="progress"></slot>
        </template>
      </VTextField>
    </template>
  </ValidationProvider>
</template>

<style lang="scss">
.vx-text-field--number input {
  text-align: right;
}
</style>
