import Vue from "vue";
import Vuetify from "vuetify/lib";
import { ICONS_RESALEAI } from "../utils/icons";
import { preset } from "@resaleai/vue-cli-plugin-theme/preset";
import en from "@/core-ui/locale/en";
require("../sass/rai-global.scss");

Vue.use(Vuetify);

/** @type {import("vuetify").VuetifyPreset} */
const raiPreset = {
  preset,
  icons: ICONS_RESALEAI,
  lang: {
    current: "en",
    locales: { en },
  },
};

const vuetify = new Vuetify(raiPreset);

export { vuetify, raiPreset };
export default vuetify;
