<script>
import { getSlot } from "../../../utils";
import { VBtn, VIcon } from "vuetify/lib";
import { useModelValue } from "../../../mixins";

export default {
  name: "RaiEditable",
  mixins: [useModelValue({ event: "change" })],
  props: {
    // Override modelValue default value
    value: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    saveText: {
      type: String,
      default: "$vuetify.rai.editable.saveText",
    },
    cancelText: {
      type: String,
      default: "$vuetify.rai.editable.cancelText",
    },
    saveDisabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "rai-editable": true,
        "rai-editable--readonly": this.readonly,
        "rai-editable--edit": this.modelValue,
      };
    },
  },
  methods: {
    save() {
      // If there is no save listener, close edit immediately
      if (!this.$listeners["save"]) {
        this.modelValue = false;
      }
      this.$emit("save");
    },
    cancel() {
      this.modelValue = false;
      this.$emit("cancel");
    },
    edit() {
      this.modelValue = true;
    },
    onDblclick() {
      if (!this.readonly) {
        this.edit();
      }
    },
  },
  render(h) {
    const Input = h("div", { class: this.classes }, [
      h("div", { class: "rai-editable-control" }, [
        h("div", { class: "rai-editable-control__input" }, [
          getSlot(this, "edit"),
        ]),
        !this.hideButtons &&
          h("div", { class: "rai-editable-control__actions" }, [
            h(
              VBtn,
              {
                props: { text: true },
                on: { click: this.cancel },
              },
              [this.$vuetify.lang.t(this.cancelText)]
            ),
            h(
              VBtn,
              {
                props: {
                  text: true,
                  color: "primary",
                  disabled: this.saveDisabled,
                  loading: this.loading,
                },
                on: { click: this.save },
              },
              [this.$vuetify.lang.t(this.saveText)]
            ),
          ]),
      ]),
    ]);

    const Text = h(
      "div",
      {
        class: this.classes,
        on: { dblclick: this.onDblclick },
      },
      [
        h("div", { class: "rai-editable__text" }, [getSlot(this, "preview")]),
        !this.readonly &&
          !this.hideButtons &&
          h(
            VBtn,
            {
              attrs: { "data-testid": "edit" },
              class: "rai-editable__edit",
              props: { icon: true, large: true },
              on: { click: this.edit },
            },
            [h(VIcon, null, ["$edit"])]
          ),
      ]
    );

    return this.modelValue ? Input : Text;
  },
};
</script>

<style lang="scss" scoped>
.rai-editable {
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 50px;
  grid-template-rows: 1fr;
  column-gap: 12px;

  &.rai-editable--edit,
  &.rai-editable--readonly {
    grid-template-columns: 1fr;
  }
}

.rai-editable__text {
  align-self: center;
}

.rai-editable__edit {
  justify-self: center;
}

.rai-editable-control__actions {
  display: flex;
  justify-content: flex-end;
}
</style>
