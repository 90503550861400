import { format } from "date-fns";

// TODO - Cleanup report-routes.js and break each into a separate route-view.
export default [
  {
    path: "reports",
    components: {
      default: () => import("@/views/Report/AppView"),
      appbar: () => import("@/views/Report/AppView/ReportsAppBar"),
    },
    props: true,
    meta: {
      requiresAuth: true,
      title: "Reports",
    },
    children: [
      {
        path: "",
        name: "reports.home",
        component: () => import("@/views/Report/AppView/ReportsHome"),
        props: true,
        meta: {
          title: "Reports",
        },
      },
      {
        path: "monthly",
        name: "reports.monthly",
        component: () => import("@/views/Report/AppView/StoreReportView"),
        props: (route) => ({
          ...route.params,
          displayDate: route.query.d || format(new Date(), "yyyy-MM-dd"),
          type: "month",
        }),
        meta: {
          toolbarDate: "monthly",
          title: "Monthly RAI",
          showBack: true,
          requiresManager: true,
        },
      },
      {
        path: "weekly",
        name: "reports.weekly",
        component: () => import("@/views/Report/AppView/StoreReportView"),
        props: (route) => ({
          ...route.params,
          displayDate: route.query.d || format(new Date(), "yyyy-MM-dd"),
          type: "week",
        }),
        meta: {
          showBack: true,
          title: "Weekly RAI",
          toolbarDate: "weekly",
          requiresManager: true,
        },
      },
      {
        path: "bounceback",
        name: "reports.bounceback",
        component: () => import("@/views/Report/AppView/BouncebackView"),
        props: true,
        meta: {
          showBack: true,
          title: "Bounceback",
        },
      },
      {
        path: "backstock",
        name: "reports.backstock",
        component: () => import("@/views/Report/AppView/AgingBackstockView"),
        props: true,
        meta: {
          showBack: true,
          title: "Aging Backstock",
        },
      },
      {
        path: "buylog",
        name: "reports.buylog",
        component: () => import("@/views/Report/AppView/BuyLogView"),
        props: (route) => ({
          ...route.params,
          displayDate: route.query.d || format(new Date(), "yyyy-MM-dd"),
        }),
        meta: {
          showBack: true,
          toolbarDate: "day",
          title: "Daily Buy Log",
        },
        children: [
          {
            path: ":buyId",
            name: "reports.buylog.buy",
            components: {
              reportDialog: () => import("@/views/Buy/AppView/BuyDetailView"),
            },
            props: {
              reportDialog: (route) => ({
                ...route.params,
                customerRouteName: "reports.buylog.customer",
                hideActions: true,
              }),
            },
          },
          {
            name: "reports.buylog.customer",
            path: "customer/:customerId",
            components: {
              reportDialog: () =>
                import("@/components/customers/CustomerDetailDialog"),
            },
            props: {
              reportDialog: (route) => ({
                ...route.params,
                buysRouteName: "buys_edit",
              }),
            },
          },
        ],
      },
      {
        path: "ledger",
        name: "reports.ledger",
        component: () => import("@/views/Report/AppView/LedgerAndJournalView"),
        props: (route) => ({
          ...route.params,
          displayDate: route.query.d || format(new Date(), "yyyy-MM-dd"),
        }),
        meta: {
          title: "Ledger and Journal",
          showBack: true,
          toolbarDate: "day",
          type: "day",
          requiresManager: true,
        },
      },
      {
        path: "day_close",
        name: "reports.day-close",
        component: () => import("@/views/Report/AppView/DayCloseReportView"),
        props: true,
        meta: {
          title: "Day Close Report",
          showBack: true,
          toolbarDate: "day",
          type: "day",
          requiresManager: true,
        },
      },
    ],
  },
  {
    // TODO - put this into separate route if it ever becomes a real setup
    path: "reports/settings",
    component: () => import("@/views/StoreSettingsView"),
    meta: {
      requiresAuth: true,
      requiresManager: true,
      title: "Reports",
    },
    children: [
      {
        path: "",
        name: "reports.setup",
        props: true,
        component: () => import("@/views/Report/SetupView"),
        meta: {
          title: "Reports setup",
          toolbarTitle: "Reports",
        },
      },
    ],
  },
];
