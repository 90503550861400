<script>
import { VSwitch } from "vuetify/lib";
import { useModelValue, useValidationProvider } from "../../../mixins";

export default {
  name: "VxSwitch",
  components: {
    VSwitch,
  },
  mixins: [
    useModelValue({ event: "change", prop: "inputValue" }),
    useValidationProvider(),
  ],
  inheritAttrs: false,
  props: {
    inputValue: {
      type: [Boolean, Array],
      default: null,
    },
    inset: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /* istanbul ignore next */
    validator() {
      return this.$refs.provider;
    },
  },
};
</script>

<template>
  <ValidationProvider
    ref="provider"
    :vid="name"
    :name="label"
    :rules="rules"
    :mode="mode"
    slim
  >
    <template v-slot="{ errors }">
      <VSwitch
        ref="switch"
        v-model="modelValue"
        :name="name"
        :label="label"
        :inset="inset"
        :error-messages="errors"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template v-slot:default>
          <slot name="default"></slot>
        </template>
        <template v-slot:label>
          <slot name="label"></slot>
        </template>
      </VSwitch>
    </template>
  </ValidationProvider>
</template>
