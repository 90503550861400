import i18n from "@/plugins/vue-i18n";

const backstockRoute = {
  // This route has no name, as when using nested routes (with a default nested route)
  // it's better to use directly default child route
  // Also it has no path, because addition of path is managed by buildRoute function (in store-routes.js)
  components: {
    default: () => import("@/views/Backstock/AppView"),
    appbar: () => import("@/views/Backstock/AppView/BackstockAppBar"),
    dialog: () => import("@/views/Common/RouterDefaultViewWrapper"),
    bardcodeScan: () =>
      import("@/views/Backstock/AppView/BackstockScanListWrapper"),
  },
  meta: {
    requiresAuth: true,
    title: i18n.t("backstock.app.toolbar.title"),
  },
  children: [
    {
      // this is a default backstock component that should be shown when navigating to '/backstocks'
      name: "backstock_home",
      path: "",
      component: () => import("@/views/Common/NewDetailButton"),
      props: (route) => ({
        routeName: "backstock_new",
      }),
    },
    {
      name: "backstock_new",
      path: "detail/new",
      component: () =>
        import("@/views/Backstock/AppView/BackstockNewBinDialog"),
      props: (route) => ({
        binId: route.query.binId,
      }),
    },
    {
      name: "backstock_edit",
      path: "detail/edit/:backstockId",
      component: () =>
        import("@/views/Backstock/AppView/BackstockEditBinDialog"),
    },
  ],
};

const backstockCommonRouteProps = (route) => {
  return {
    categoryId: route.query.categoryId,
    locationId: route.query.locationId,
  };
};

const backstockSetupRoute = {
  // This route has no name, as when using nested routes (with a default nested route)
  // it's better to use directly default child route
  // Also it has empty path, because addition of path is managed by buildRoute function (in store-routes.js)
  path: "",
  meta: {
    requiresAuth: true,
    requiresManager: true,
  },
  components: {
    default: () => import("@/views/StoreSettingsView"),
  },
  children: [
    {
      path: "",
      name: "backstock.setup",
      component: () => import("@/views/Backstock/SetupView"),
      meta: {
        title: i18n.t("backstock.setup.title"),
        toolbarTitle: i18n.t("backstock.setup.toolbarTitle"),
        description: i18n.t("backstock.setup.description"),
      },
      children: [
        {
          name: "backstock_settings_new_bins",
          path: "bins/new",
          component: () =>
            import("@/views/Backstock/SetupView/AddBinsDialogView"),
          props: (route) => ({
            startNumber: route.query.startNumber,
          }),
        },
        {
          name: "backstock_settings_new_category",
          path: "categories/detail/new",
          component: () =>
            import("@/views/Backstock/SetupView/EditCategoryDialogView"),
        },
        {
          name: "backstock_settings_categories_list_edit_category",
          path: "categories/detail/edit/:categoryId",
          component: () =>
            import("@/views/Backstock/SetupView/EditCategoryDialogView"),
        },
        {
          name: "backstock_settings_new_location",
          path: "locations/detail/new",
          component: () =>
            import("@/views/Backstock/SetupView/EditLocationDialogView"),
        },
        {
          name: "backstock_settings_locations_list_edit_location",
          path: "locations/detail/edit/:locationId",
          component: () =>
            import("@/views/Backstock/SetupView/EditLocationDialogView"),
        },
      ],
    },
    {
      name: "backstock_settings_bins_list",
      path: "bins",
      component: () => import("@/views/Backstock/SetupView/BinsView"),
      meta: {
        title: i18n.t("backstock.setup.bins.viewAll.title"),
        toolbarTitle: i18n.t("backstock.setup.bins.viewAll.toolbarTitle"),
        description: i18n.t("backstock.setup.bins.viewAll.description"),
      },
      children: [
        {
          name: "backstock_settings_bins_list_new_bins",
          path: "detail/new",
          props: (route) => ({
            startNumber: route.query.startNumber,
          }),
          component: () =>
            import("@/views/Backstock/SetupView/AddBinsDialogView"),
        },
        {
          name: "backstock_settings_bins_list_edit_bin",
          path: "detail/edit/:binId",
          component: () =>
            import("@/views/Backstock/SetupView/EditBinNumberDialogView"),
        },
      ],
    },
  ],
};

const backstockSetupCommonRouteProps = (route) => {
  return {
    raiLinkTo: {
      showBins: "backstock_settings_bins_list",
      editBin: "backstock_settings_bins_list_edit_bin",
      addBinsInList: "backstock_settings_bins_list_new_bins",
      addBins: "backstock_settings_new_bins",
      editCategory: "backstock_settings_categories_list_edit_category",
      addCategory: "backstock_settings_new_category",
      editLocation: "backstock_settings_locations_list_edit_location",
      addLocation: "backstock_settings_new_location",
    },
  };
};

export {
  backstockRoute,
  backstockCommonRouteProps,
  backstockSetupRoute,
  backstockSetupCommonRouteProps,
};
