// TODO - remove parse filter import when all routes changed to use route builder
import { buildRoute } from "@/utils/router";

import reportRoutes from "./report-routes";
import teamRoutes from "./store/team-routes";
import softwareUpdateRoutes from "@/views/Settings/SoftwareUpdateView/routes.js";

import {
  daybookSetupRoute,
  daybookSetupCommonRouteProps,
} from "./store/daybook-routes.js";

import {
  goalsSetupRoute,
  goalsSetupCommonRouteProps,
} from "./store/goal-routes";

import {
  backstockRoute,
  backstockSetupRoute,
  backstockCommonRouteProps,
  backstockSetupCommonRouteProps,
} from "./store/backstock-routes";
import {
  bouncebackRoute,
  bouncebackSetupRoute,
  bouncebackSetupCommonRouteProps,
} from "./store/bounceback-routes";
import {
  buysRoute,
  buysCommonRouteProps,
  buysSetupRoute,
  buysSetupCommonRouteProps,
} from "./store/buys-routes";

import {
  textingRoute,
  textingSetupRoute,
  textingSetupCommonRouteProps,
} from "./store/texting-routes";

import {
  backstockHistoryRoute,
  backstockHistoryCommonRouteProps,
} from "./store/report/backstock-history-routes";

import { orderRoute } from "./store/order-routes";
import { customersRoute } from "./store/customer-routes";
import { inventoryRoute } from "./store/inventory-routes";
import { settingsRoute } from "./store/settings-routes.js";

const storeRoutes = [
  ...softwareUpdateRoutes,
  ...reportRoutes,
  ...teamRoutes,

  ///////////
  // REPORTS
  ///////////
  ...buildRoute(
    "reports/backstock_history",
    backstockHistoryRoute,
    backstockHistoryCommonRouteProps
  ),

  ///////////
  // DAYBOOK
  ///////////
  ...buildRoute(
    "daybook/settings",
    daybookSetupRoute,
    daybookSetupCommonRouteProps
  ),

  ///////////
  // BACKSTOCK
  ///////////
  ...buildRoute("backstock", backstockRoute, backstockCommonRouteProps),
  ...buildRoute(
    "backstock/settings",
    backstockSetupRoute,
    backstockSetupCommonRouteProps
  ),

  ///////////
  // BOUNCEBACK
  ///////////
  ...buildRoute("bounceback", bouncebackRoute),
  ...buildRoute(
    "bounceback/settings",
    bouncebackSetupRoute,
    bouncebackSetupCommonRouteProps
  ),

  ///////////
  // TEXTING
  ///////////
  ...buildRoute("texting", textingRoute),
  ...buildRoute(
    "texting/settings",
    textingSetupRoute,
    textingSetupCommonRouteProps
  ),

  ///////////
  // CUSTOMERS
  ///////////
  ...buildRoute("customers", customersRoute),

  ///////////
  // BUYS
  ///////////
  ...buildRoute("buys", buysRoute, buysCommonRouteProps),
  ...buildRoute("buys/settings", buysSetupRoute, buysSetupCommonRouteProps),

  ///////////
  // SETTINGS
  ///////////
  ...buildRoute("settings", settingsRoute),
  ...buildRoute("goal/settings", goalsSetupRoute, goalsSetupCommonRouteProps),

  ///////////
  // OTHERS
  ///////////
  ...buildRoute("inventory", inventoryRoute),
  ...buildRoute("orders", orderRoute),

  {
    name: "allNotesDialog",
    path: "daybook/all-notes",
    components: {
      default: () => import("@/views/DayView"),
      appbar: () => import("@/views/DayView/DayViewAppBar"),
      dialog: () => import("@/views/DaybookView/AllNotesDialog"),
    },
    meta: {
      requiresAuth: true,
      title: "Daybook",
    },
  },
  {
    name: "newShiftDialog",
    path: "daybook/shifts/new",
    components: {
      default: () => import("@/views/DayView"),
      appbar: () => import("@/views/DayView/DayViewAppBar"),
      dialog: () => import("@/views/DaybookView/NewShiftDialog"),
    },
    meta: {
      requiresAuth: true,
      title: "Daybook",
    },
  },
  {
    name: "editShiftDialog",
    path: "daybook/shift/:shiftId",
    components: {
      default: () => import("@/views/DayView"),
      appbar: () => import("@/views/DayView/DayViewAppBar"),
      dialog: () => import("@/views/DaybookView/EditShiftDialog"),
    },
    meta: {
      requiresAuth: true,
      title: "Daybook",
    },
  },
  {
    name: "newTaskDialog",
    path: "daybook/tasks/new",
    components: {
      default: () => import("@/views/DayView"),
      appbar: () => import("@/views/DayView/DayViewAppBar"),
      dialog: () => import("@/views/DaybookView/NewTaskDialog"),
    },
    meta: {
      requiresAuth: true,
      title: "Daybook",
    },
  },
  {
    name: "newNoteDialog",
    path: "daybook/notes/new",
    components: {
      default: () => import("@/views/DayView"),
      appbar: () => import("@/views/DayView/DayViewAppBar"),
      dialog: () => import("@/views/DaybookView/NewNoteDialog"),
    },
    meta: {
      requiresAuth: true,
      title: "Daybook",
    },
  },
  {
    name: "editNoteDialog",
    path: "daybook/note/:noteId",
    components: {
      default: () => import("@/views/DayView"),
      appbar: () => import("@/views/DayView/DayViewAppBar"),
      dialog: () => import("@/views/DaybookView/EditNoteDialog"),
    },
    meta: {
      requiresAuth: true,
      title: "Daybook",
    },
  },
  {
    name: "orderSharedSecret",
    path: "orders/sharedsecret",
    components: {
      default: () => import("@/views/OrderListView"),
      appbar: () => import("@/views/OrderListView/OrderAppBar"),
      dialog: () => import("@/views/OrderListView/SharedSecretDialog"),
    },
  },
  // {
  //   name: "day",
  //   path: "day/:date?",
  //   components: {
  //     default: () => import("@/views/DayView"),
  //     appbar: () => import("@/views/DayView/DayViewAppBar")
  //   },
  //   meta: {
  //
  //     title: "Daybook"
  //   }
  // },

  {
    name: "daybook",
    path: "daybook/:date?",
    components: {
      default: () => import("@/views/DayView"),
      appbar: () => import("@/views/DayView/DayViewAppBar"),
    },
    meta: {
      title: "Daybook",
    },
  },
  {
    name: "about",
    path: "about",
    components: {
      dialog: () => import("@/views/AboutView"),
    },
  },
  // default redirect to daybook
  {
    path: "",
    redirect: { name: "daybook" },
  },
];

export { storeRoutes };
