/* eslint-disable no-console */

(async () => {
  // Only register SW in production
  if (process.env.NODE_ENV !== "production") return;

  // Check to we have access to the serviceWorker API
  if (!("serviceWorker" in navigator)) return;

  // Import the workbox-window module and create the Workbox Window object
  // Workbox Window is a simplified interface around workbox:
  //   https://developers.google.com/web/tools/workbox/modules/workbox-window
  const { Workbox } = await import("workbox-window");
  const wb = new Workbox("service-worker.js");

  wb.addEventListener("installed", (event) => {
    // This is the first time this SW has been installed
    if (!event.isUpdate) {
      console.log("Service worker has been registered for RAI.");
    }
    // This is an update. Tell our Vue app, so we can show a prompt
    if (event.isUpdate) {
      console.log(
        "Service worker has been updated for RAI.",
        process.env.VUE_APP_VERSION
      );

      document.dispatchEvent(new CustomEvent("swUpdated", { detail: wb }));
    }
  });

  try {
    // Register the sw
    const registration = await wb.register();

    // Tell our Vue app the registration has occurred
    document.dispatchEvent(
      new CustomEvent("swRegistered", { detail: registration })
    );

    // Periodically check for updates (every 2 minutes)
    setInterval(() => {
      // TODO: remove toISOString
      console.log(
        "checking for service worker update",
        process.env.VUE_APP_VERSION,
        new Date().toISOString()
      );
      registration.update();
    }, 1000 * 60 * 30);
  } catch (error) {
    console.log("Error registering ServiceWorker", error);
  }
})();
