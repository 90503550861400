<template>
  <VxBtn v-if="$attrs.loading" v-bind="$attrs" />
  <div v-else class="d-flex">
    <VxBtn
      class="px-2 rounded-r-0"
      v-bind="$attrs"
      @click="handleClick($event)"
      >{{ buttonText }}</VxBtn
    >

    <VMenu
      v-model="modelValue"
      transition="slide-y-transition"
      left
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <slot name="activator" v-bind="{ on }">
          <VxBtn
            class="px-0 rounded-l-0"
            min-width="0"
            v-bind="$attrs"
            v-on="on"
          >
            <VIcon>$menuDown</VIcon>
          </VxBtn>
        </slot>
      </template>
      <template v-slot:default>
        <slot></slot>
      </template>
    </VMenu>
  </div>
</template>

<script>
import { VxBtn } from "../../vx";
import { VMenu, VIcon } from "vuetify/lib";
import { useModelValue } from "../../../mixins";

export default {
  name: "RaiCompoundButton",
  components: {
    VxBtn,
    VMenu,
    VIcon,
  },
  mixins: [useModelValue()],
  props: {
    buttonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>
