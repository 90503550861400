const defaultState = {
  employee: () => ({
    id: null,
    email: null,
    fullName: null,
    clockedIn: null,
    isOnBreak: null,
  }),
};

const state = {
  employee: defaultState.employee(),
  lastEmployee: null,
  timeSheetDialog: null,
  employeeWelcomeDialog: null,
  resetTimeoutId: null,
  resetTimeoutSeconds: 150,
};

const mutations = {
  setEmployee(state, payload) {
    state.employee = payload;
  },
  setLastEmployee(state, payload) {
    state.lastEmployee = payload;
  },
  setClockedIn(state, payload) {
    state.employee.clockedIn = payload;
  },
  setIsOnBreak(state, payload) {
    state.employee.isOnBreak = payload;
  },
  setTimeSheetDialog(state, payload) {
    state.timeSheetDialog = payload;
  },
  setResetTimeoutId(state, payload) {
    state.resetTimeoutId = payload;
  },
  setEmployeeWelcomeDialog(state, payload) {
    state.employeeWelcomeDialog = payload;
  },
};

const actions = {
  showTimeSheetDialog({ commit }) {
    commit("setTimeSheetDialog", true);
  },
  showEmployeeWelcomeDialog({ commit }) {
    commit("setEmployeeWelcomeDialog", true);
  },
  hideEmployeeWelcomeDialog({ commit }) {
    commit("setEmployeeWelcomeDialog", false);
  },
  punchClock({ commit }, { clockedIn }) {
    commit("setClockedIn", clockedIn);
    commit("setIsOnBreak", false);
  },
  startBreak({ commit }) {
    commit("setIsOnBreak", true);
  },
  endBreak({ commit }) {
    commit("setIsOnBreak", false);
  },
  reset({ commit }) {
    commit("setTimeSheetDialog", false);
    commit("setLastEmployee", null);
    commit("setEmployee", defaultState.employee());
  },
  resetWithTimeout({ state, commit, dispatch }) {
    commit("setTimeSheetDialog", false);
    commit("setLastEmployee", state.employee);
    commit("setEmployee", defaultState.employee());

    dispatch("setResetTimeout");
  },
  setResetTimeout({ state, commit }) {
    clearTimeout(state.resetTimeoutId);

    const resetTimeoutId = setTimeout(() => {
      commit("setLastEmployee", null);
      commit("setResetTimeoutId", null);
    }, 1000 * state.resetTimeoutSeconds);

    commit("setResetTimeoutId", resetTimeoutId);
  },
  clearResetTimeout({ state, commit }) {
    clearTimeout(state.resetTimeoutId);
    commit("setLastEmployee", null);
    commit("setResetTimeoutId", null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
