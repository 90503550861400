import { trim } from "lodash";
import { formatPhoneNumber } from "@/utils/phone";

export default {
  fullPhoneNumber: function (numberToFormat) {
    return `+1${this.fullPhoneNumberNoCountry(numberToFormat)}`;
  },
  fullPhoneNumberNoCountry: function (numberToFormat) {
    numberToFormat = trim(numberToFormat);
    if (
      numberToFormat &&
      numberToFormat.length > 0 &&
      numberToFormat[0] === "+" &&
      numberToFormat[1] === "1"
    ) {
      // It means it has country code, so we remove it
      numberToFormat = numberToFormat.substring(2, numberToFormat.length);
    }
    return formatPhoneNumber(numberToFormat);
  },
};
