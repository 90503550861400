import dollarsFilter from "./dollars";
import dateFormats from "./date-formatting";
import phoneFormats from "./phone-formatting";

const AllFilters = {
  ...dollarsFilter,
  ...dateFormats,
  ...phoneFormats,
};

export { AllFilters };
