<script>
import { getSlot, hasSlot } from "../../../utils";
import { ValidationObserver } from "vee-validate";
import RaiEditable from "../RaiEditable/RaiEditable.vue";

export default {
  name: "RaiInlineEdit",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    saveText: {
      type: String,
      default: "$vuetify.rai.inlineEdit.saveText",
    },
    cancelText: {
      type: String,
      default: "$vuetify.rai.inlineEdit.cancelText",
    },
  },
  data: (vm) => ({
    isEdit: vm.value,
    inputRef: "input",
    inputValue: "",
  }),
  computed: {
    isReadonly() {
      return this.readonly || !this.hasInputSlot;
    },
    hasText() {
      return this.text && this.text.trim() !== "";
    },
    hasInputSlot() {
      return hasSlot(this, "input");
    },
    inputSlotProps() {
      return {
        ref: this.inputRef,
        attrs: {
          outlined: true,
          loading: this.loading,
          value: this.inputValue,
          placeholder: this.placeholder,
        },
        on: {
          input: this.onInput,
        },
      };
    },
  },
  watch: {
    text: {
      immediate: true,
      handler: "onInput",
    },
    value(value) {
      this.isEdit = value;
    },
    isEdit(value) {
      if (value === true && this.hasInputSlot) {
        this.$nextTick(() => this.focusInput());
      }

      this.$emit("input", value);
    },
  },
  methods: {
    save() {
      this.isEdit = false;
      this.$emit("update:text", this.inputValue);
    },
    cancel() {
      this.isEdit = false;
      this.inputValue = this.text;
      this.$emit("cancel");
    },
    focusInput() {
      const { context } = this.$scopedSlots.input()[0];
      const inputEl = context.$refs[this.inputRef];
      inputEl?.focus();
    },
    onInput(value) {
      this.inputValue = value;
    },
  },
  render(h) {
    const Placeholder = h("span", { class: "font-italic text--secondary" }, [
      this.placeholder,
    ]);

    return h(ValidationObserver, { props: { slim: true } }, [
      ({ invalid }) =>
        h(
          RaiEditable,
          {
            attrs: this.$attrs,
            props: {
              value: this.isEdit,
              readonly: this.isReadonly,
              loading: this.loading,
              saveText: this.$vuetify.lang.t(this.saveText),
              cancelText: this.$vuetify.lang.t(this.cancelText),
              saveDisabled: invalid || this.loading,
            },
            on: {
              save: this.save,
              cancel: this.cancel,
              change: (value) => {
                this.isEdit = value;
              },
            },
          },
          [
            h("template", { slot: "preview" }, [
              !this.hasText && Placeholder,
              this.hasText && this.text,
            ]),
            h("template", { slot: "edit" }, [
              getSlot(this, "input", this.inputSlotProps),
            ]),
          ]
        ),
    ]);
  },
};
</script>
