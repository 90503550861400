<script>
import { genSlots, getSlot } from "../../../utils";
import { VAppBar, VToolbarTitle, VSpacer } from "vuetify/lib";

export default {
  name: "VxAppBar",
  extends: VAppBar,
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    props() {
      return { ...this.$props, app: true, elevateOnScroll: true };
    },
  },
  render(h) {
    const ToolbarTitle = this.title && h(VToolbarTitle, {}, this.title);

    return h(
      VAppBar,
      {
        class: "vx-app-bar",
        attrs: this.$attrs,
        props: this.props,
        scopedSlots: this.$scopedSlots,
      },
      [
        getSlot(this, "nav-icon"),
        getSlot(this, "title") || ToolbarTitle,
        getSlot(this, "left"),
        h(VSpacer),
        getSlot(this, "right"),
        ...genSlots(this, ["default", "extension"]),
      ]
    );
  },
};
</script>
