<script>
import { convertToUnit } from "../../../utils";
import useTableCell from "./mixins/useTableCell";

export default {
  name: "VxTableHeaderCell",
  mixins: [useTableCell()],
  props: {
    width: {
      type: [String, Number],
      default: "",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        "vx-table__header-cell--fixed": this.fixed,
        ...this.cellClasses,
      };
    },
    styles() {
      return {
        width: convertToUnit(this.width),
      };
    },
  },
  render(h) {
    return h(
      "th",
      {
        class: [this.classes, this.className, "vx-table__header-cell"],
        style: this.styles,
      },
      this.genContent()
    );
  },
};
</script>

<style lang="scss" scoped>
.vx-table th.vx-table__header-cell--fixed {
  position: sticky;
  z-index: 1;
}

.vx-table th.vx-table__header-cell--fixed:first-child {
  left: 0;
}

.vx-table th.vx-table__header-cell--fixed:last-child {
  right: 0;
}

.vx-table thead th.vx-table__header-cell--fixed:first-child,
.vx-table thead th.vx-table__header-cell--fixed:last-child {
  z-index: 3 !important; // -> VSimpleTable 'fixedHeader' prop puts th z-index to 2 so this overrides for first and last column so we can get horizontal and vertical overlap
}
</style>
