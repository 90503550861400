export const TableCellType = {
  TEXT: "text",
  NUMBER: "number",
  PHONE: "phone",
  PERCENT: "percent",
  CURRENCY: "currency",
  DATE: "date",
  BOOLEAN: "boolean",
  DURATION: "duration",
};

export const TableCellAlign = {
  START: "start",
  CENTER: "center",
  END: "end",
};
