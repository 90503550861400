<script>
import { props } from "./VxDataListGroupBase";
import VxDataListGroupMobile from "./VxDataListGroupMobile.vue";
import VxDataListGroupDesktop from "./VxDataListGroupDesktop.vue";
import { defineResponsiveComponent } from "../../../utils/component";

export default defineResponsiveComponent({
  name: "VxDataListGroup",
  desktop: VxDataListGroupDesktop,
  mobile: VxDataListGroupMobile,
  props,
});
</script>
