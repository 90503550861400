import { renderConfirmDialog } from "./helpers";

/**
 * @typedef {Object} InfoDialogOptions
 * @property {String|JSX.Element} message - message to show
 * @property {String} [title] - dialog title
 * @property {String} [okText] - button OK text
 * @property {String} [okColor] - button OK color
 * @property {String} [attach] - where to attach dialog in DOM
 */

/**
 * Renders info dialog in DOM on demand
 * @param {import("vue").VueConstructor} Vue - Vue constructor
 * @param {{ vuetify: import("vuetify/lib").default }} args - arguments provided by plugin
 * @param {InfoDialogOptions} options - for rendering dialog
 * @returns {Promise<void>} a promise to be resolved by dialog
 */
function info(Vue, args, options) {
  const { title, message, okText, okColor, attach } = options;
  return renderConfirmDialog(Vue, args, {
    title,
    message,
    okText,
    okColor,
    attach,
    hideCancel: true,
  });
}

export { info };
export default { info };
