import store from "@/store";

/**
 * Global notification displayed at the top of the app.
 * Intended use includes showing DRS is not connected, billing notice, global disruption notice, etc.
 *
 * - Priority (a numeric value indicating the priority. Higher priority notices trump others)
 *    - 9 -> Device offline
 *    - 8 -> Global system disruption
 *    - 7 -> UI state
 */

class Notice {
  constructor({ id, text, color, priority, actionText, action }) {
    this.id = id;
    this.text = text;
    this.color = color;
    this.priority = priority;
    this.actionText = actionText;
    this.action = action;
  }

  show() {
    store.dispatch("notice/addNotice", {
      id: this.id,
      text: this.text,
      color: this.color,
      priority: this.priority,
      actionText: this.actionText,
      action: this.action,
    });
  }

  clear() {
    store.dispatch("notice/clearNotice", this.id);
  }
}

export { Notice };
export default Notice;
