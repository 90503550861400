// This is a helper file for graphql
//
// Any general/global-ish shared code can go here.

// When importing a graphql file, like `employee/fragments.gql`,
// the entire document is imported with a "definitions" property,
// which is a list of all the definitions in that file.
//
// In order to select one fragment, we take the document,
// find a fragment by name, then ensure that is the only
// definition on the document.
export const findDefinitionByName = (document, name) => {
  if (!document) throw `Expected gql document but got ${document}`;
  if (!document.definitions || document.definitions.length < 1)
    throw `There are no definitions on document, ${document}`;
  if (!name) throw `Expected name but got ${name}`;

  const fragment = document.definitions.find(
    ({ name: { value } }) => value === name
  );

  if (fragment) {
    document.definitions = [fragment];
  }
  if (!fragment) {
    throw `Could not find fragment ${name} in definitions on ${document}`;
  }

  return document;
};
