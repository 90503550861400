<script>
import { VStepper } from "vuetify/lib";
import { getSlot } from "../../../utils";
import { useModelValue } from "../../../mixins";

export default {
  name: "VxStepper",
  extends: VStepper,
  mixins: [useModelValue()],
  provide() {
    return {
      getStepper: this.getStepper,
      stepPrevious: this.stepPrevious,
      stepNext: this.stepNext,
    };
  },
  props: {
    vertical: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /* istanbul ignore next: called from VxStepperContent */
    stepPrevious(step) {
      this.$emit("previous", step);
    },
    /* istanbul ignore next: called from VxStepperContent */
    stepNext(step) {
      this.$emit("next", step);
    },
    getStepper() {
      return this.$refs.stepper;
    },
  },
  render(h) {
    return h(
      VStepper,
      {
        ref: "stepper",
        class: "vx-stepper",
        props: {
          ...this.$props,
          value: this.modelValue,
        },
      },
      getSlot(this, "default", null)
    );
  },
};
</script>
