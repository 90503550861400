import { featureApi } from "./featureTogglerApi.js";
import Vue from "vue";
import { debounce } from "lodash";

/**
 * Defines options for creating responsive component
 * @typedef {Object} ResponsiveComponentOptions
 * @property {String} name - component name as part of Vue constructor
 * @property {import("vue").VueConstructor} desktop - Vue component that renders on desktop breakpoint
 * @property {import("vue").VueConstructor} mobile  - Vue component that renders on mobile breakpoint
 * @property {Object?} props - define common Vue component props
 * @returns
 */

/**
 * A responsive Vue component
 * @typedef {import("vue").VueConstructor} VueResponsiveComponent
 */

/**
 * Creates a functional responsive Vue component
 * @param {ResponsiveComponentOptions} options - required options for creating responsive component
 * @returns {VueResponsiveComponent} a functional component that renders desktop or mobile based on mobile breakpoint
 * @example
 * import mobile from "./ExampleComponentMobile.vue"
 * import desktop from "./ExampleComponentDesktop.vue"
 *
 * export default defineResponsiveComponent({ name: "ExampleComponent", desktop, mobile })
 */
export const defineResponsiveComponent = (options) => {
  const { name, desktop, mobile, props = {} } = options;

  return {
    name,
    props: {
      ...props,
      mobile: { type: Boolean, default: () => undefined },
    },
    functional: true,
    inheritAttrs: false,
    /**
     * @param {import("vue").CreateElement} h
     * @param {import("vue").RenderContext} context
     * */
    render(h, context) {
      // If mobile defined, override the rendered component
      const responsiveComponent =
        (context.props.mobile === undefined &&
          context.parent.$vuetify.breakpoint.mobile) ||
        context.props.mobile
          ? mobile
          : desktop;

      return h(
        responsiveComponent,
        {
          props: context.props,
          ...context.data,
          scopedSlots: context.scopedSlots,
        },
        context.$slots.default
      );
    },
  };
};

const getFeaturedComponent = (features) => {
  const foundFeature = features.find((x) =>
    featureApi.isVisible(x.feature, x.variant)
  )?.component;

  if (!foundFeature) {
    return features[0]?.component;
  }
  return foundFeature;
};

/**
 * Defines options for creating feature component
 * @typedef {Object} SingleFeatureOptions
 * @property {String} feature - name of the feature
 * @property {String} variant - name of the variant of the feature
 * @property {import("vue").VueConstructor} component - Vue component that renders when the feature and variant are active
 */
/**
 * Defines options for creating feature component
 * @typedef {Object} FeatureComponentOptions
 * @property {String} name - name of the component
 * @property {Array<SingleFeatureOptions>} features - definition which component is used for which feature
 * @returns
 */

/**
 * A responsive Vue component
 * @typedef {import("vue").VueConstructor} VueFeatureComponent
 */

/**
 * Creates a functional responsive Vue component
 * @param {FeatureComponentOptions} options - required options for creating responsive component
 * @returns {VueFeatureComponent} a functional component that renders component based of active feature flags
 *
 * import V1 from "./ExampleComponentV1.vue"
 * import V2 from "./ExampleComponentV2.vue"
 *
 * export default defineFeatureComponent({
 *  name: 'componentName',
 *  features: [
 *    {
 *      feature: "lockScreen",
 *      variant: "V1",
 *      component: V1,
 *    },
 *    {
 *      feature: "lockScreen",
 *      variant: "V2",
 *      component: V2,
 *    },
 *  ]
 * })
 */
export const defineFeatureComponent = (options) => {
  const { name, features } = options;

  const state = Vue.observable({
    componentToRender: getFeaturedComponent(features),
  });

  featureApi.on(
    "visibilityrule",
    debounce(function () {
      // can also use function(rule) - if needed in future.
      state.componentToRender = getFeaturedComponent(features);
    }, 200)
  );

  return {
    name,
    functional: true,
    inheritAttrs: false,
    /**
     * @param {import("vue").CreateElement} h
     * @param {import("vue").RenderContext} context
     * */
    render(h, context) {
      return h(
        state.componentToRender,
        {
          props: context.props,
          ...context.data,
          scopedSlots: context.scopedSlots,
        },
        context.$slots.default
      );
    },
  };
};
