<script>
import { genSlots } from "../../../utils";
import { VBtn } from "vuetify/lib";

export default {
  name: "VxBtn",
  extends: VBtn,
  props: {
    color: {
      type: String,
      default: "primary",
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    props() {
      return {
        ...this.$props,
        ...(this.icon && { color: this.$options.propsData.color || "" }),
        ...(this.secondary && { color: "grey", text: true }),
      };
    },
  },
  render(h) {
    return h(VBtn, { props: this.props, on: this.$listeners }, [
      genSlots(this, ["default", "loader"]),
    ]);
  },
};
</script>
