import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import store from "@/store";

export default function setup(instance = undefined, vue = undefined) {
  if (!instance) instance = axios;
  instance.interceptors.request.use(
    (config) => {
      config = putStandardHeaders(config);
      config = putAuthHeaders(config);
      config = putStoreIdParam(config);
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  const vm = vue ?? Vue;
  vm.use(VueAxios, axios);
}

// Add auth headers (if they exist) to the request
const putAuthHeaders = (config) => {
  const authHeaders = store.getters["auth/authHeaders"];
  if (authHeaders["X-User-Email"] && authHeaders["X-User-Token"]) {
    config.headers = Object.assign({}, config.headers, authHeaders);
    config.baseURL = `${process.env.VUE_APP_RAI_DOMAIN}/api/vue`;
  }
  return config;
};

// Add selectedStore.id param to the request
const putStoreIdParam = (config) => {
  const selectedStoreId = store.getters["auth/activeStoreId"];
  if (selectedStoreId) {
    config.params = Object.assign({}, config.params, {
      store_id: selectedStoreId,
    });
  }
  return config;
};

const putStandardHeaders = (config) => {
  config.headers = Object.assign({}, config.headers, {
    "Content-Type": "application/json",
    Accept: "application/json",
  });
  return config;
};
