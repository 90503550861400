import { Notice } from "./base";

class ServiceWorkerUpdateNotice extends Notice {
  constructor() {
    super({
      id: "serviceWorkerUpdate",
      text: "Update required to continue using ResaleAI!",
      color: "error",
      priority: 8,
      actionText: "Reload to continue",
      action: () => {
        window.location.reload();
      },
    });
  }
}

export default new ServiceWorkerUpdateNotice();
