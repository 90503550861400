export default {
  dollars: function (value) {
    // If no value, return empty string
    // TODO -> check if special case needed for  && value !== 0
    if (!value) {
      return "";
    }

    try {
      value = parseFloat(value.toString());
      return `$ ${value.toFixed(2)}`;
    } catch {
      // Nothing special to do, just don't throw anything
    }

    // if we could not parse it, return empty string
    return "";
  },
};
