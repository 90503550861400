import i18n from "@/plugins/vue-i18n";

const daybookSetupRoute = {
  path: "",
  meta: {
    requiresAuth: true,
    requiresManager: true,
  },
  components: {
    default: () => import("@/views/StoreSettingsView"),
  },
  children: [
    {
      path: "",
      name: "daybook.setup",
      component: () => import("@/views/Daybook/SetupView"),
      meta: {
        title: i18n.t("daybook.setup.routes.setup.title"),
        toolbarTitle: i18n.t("daybook.setup.routes.setup.toolbarTitle"),
        description: i18n.t("daybook.setup.routes.setup.description"),
      },
    },
    {
      path: "task-lists/:taskListId",
      name: "daybook.setup.task-lists.edit",
      component: () => import("@/views/Daybook/SetupView/EditTaskListView.vue"),
      meta: {
        title: i18n.t("daybook.setup.routes.edit.title"),
        toolbarTitle: i18n.t("daybook.setup.routes.edit.toolbarTitle"),
      },
    },
    {
      path: "task-lists",
      name: "daybook.setup.task-lists.new",
      component: () => import("@/views/Daybook/SetupView/NewTaskListView.vue"),
      meta: {
        title: i18n.t("daybook.setup.routes.new.title"),
        toolbarTitle: i18n.t("daybook.setup.routes.new.toolbarTitle"),
      },
    },
  ],
};

const daybookSetupCommonRouteProps = (route) => {
  return {
    taskListId: route.params.taskListId,
    raiLinkTo: {
      newTaskList: "daybook.setup.task-lists.new",
      editTaskList: "daybook.setup.task-lists.edit",
    },
  };
};

export { daybookSetupRoute, daybookSetupCommonRouteProps };
