import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { isString, isNumber, isDate } from "lodash";

/**
 * @param {number|String|Date} value - defined as instance of Date, string or total milliseconds since beginning of time
 * @param {String} formatString - format in which to present provided Date value eg. dd/MM/yyyy
 * @returns formatted Date string if value is valid or empty string if invalid
 */
function formatDate(value, formatString) {
  if (isDate(value) || isNumber(value)) {
    return format(value, formatString);
  }

  return value && isString(value) ? format(parseISO(value), formatString) : "";
}

export { formatDate };
export default formatDate;
