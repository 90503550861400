import { Notice } from "./base";

class DeviceOfflineNotice extends Notice {
  constructor() {
    super({
      id: "deviceOffline",
      text: "Device offline",
      color: "error",
      priority: 9,
    });
  }
}

export default new DeviceOfflineNotice();
