export default [
  {
    path: "softwareUpdates",
    name: "SoftwareUpdateView",
    component: () => import("@/views/Settings/SoftwareUpdateView"),
    meta: {
      requiresAuth: true,
      requiresEmployee: false,
      title: "Software updates",
    },
  },
  {
    path: "softwareUpdates/:releaseId/feedback",
    name: "currentRelease/feedback",
    // component: () => import('@/views/settings/SoftwareUpdateView'),
    components: {
      default: () => import("@/views/Settings/SoftwareUpdateView"),
      dialog: () => import("@/views/Settings/SoftwareUpdateView/SendFeedback"),
    },
    meta: {
      requiresAuth: true,
      requiresEmployee: false,
      title: "Software updates",
    },
  },
];
