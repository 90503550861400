import { format as formatDate, addHours, setMinutes, set } from "date-fns";

export default class QuotedTimeSlot {
  /**
   * Creates RaiQuotedTimeSlot instance
   * @param {Date} date - for which to create time slot
   * @param {Number} interval - by which to resolve time slot
   * @returns {QuotedTimeSlot} instance of RaiQuotedTimeSlot class
   */
  constructor(date, interval) {
    this.value = set(date, {
      seconds: 0,
      milliseconds: 0,
    });

    const minutes = this._getAvailableMinuteInterval(this.value, interval);

    if (minutes !== undefined) {
      this.value = setMinutes(this.value, minutes);
    } else {
      const roundedToHour = addHours(this.value, 1);
      this.value = setMinutes(roundedToHour, 0);
    }

    Object.defineProperty(this, "value", { writable: false });
  }

  /**
   * Resolves available minute interval by given date
   * @param {Date} date - to compare with minute intervals
   * @param {Number} interval - by which to get minute intervals
   * @returns {Number|undefined} an minute interval that is less or equal to given `date` minutes
   */
  _getAvailableMinuteInterval(date, interval) {
    return this._getMinuteIntervals(interval).find(
      (minute) => date.getMinutes() <= minute
    );
  }

  /**
   * Creates minute intervals
   * @param {Number} interval - by which to create minute intervals
   * @returns an array of numbers which count up to 60 from `0` by `interval`, excluding 60.
   * @example getMinuteIntervals(15) //> returns an array of [0,15,30,45]
   */
  _getMinuteIntervals(interval) {
    const intervals = [];

    for (let i = 0; i <= 60 - interval; i += interval) {
      intervals.push(i);
    }

    return intervals;
  }

  toString(format = "h:mm a") {
    return formatDate(this.value, format);
  }
}
