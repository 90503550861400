const state = () => ({
  theme: "system",
  systemDark: false,
  loading: false,
  loaders: [],
  mobileDrawer: false,
  scheduleDrawer: false,
  newBuyFlow: true,
  posProxy: false,
  fullSettings: false,
  idle: 90,
  keepAlive: false,
  printer: undefined,
  useFreshdesk: false,
});

const mutations = {
  setTheme(state, val) {
    state.theme = val;
  },
  setSystemDark(state, val) {
    state.systemDark = val;
  },
  startLoading(state) {
    state.loading = true;
  },
  stopLoading(state) {
    state.loading = false;
  },
  loading(state, key) {
    state.loaders.push(key);
    if (!state.loading) {
      state.loading = true;
    }
  },
  loaded(state, key) {
    state.loaders = state.loaders.filter((job) => job !== key);
    if (state.loaders.length === 0) {
      state.loading = false;
    }
  },
  toggleMobileDrawer(state) {
    state.mobileDrawer = !state.mobileDrawer;
  },
  mobileDrawer(state, val) {
    state.mobileDrawer = val;
  },
  toggleScheduleDrawer(state) {
    state.scheduleDrawer = !state.scheduleDrawer;
  },
  toggleNewBuyFlow(state) {
    state.newBuyFlow = !state.newBuyFlow;
  },
  togglePosProxy(state) {
    state.posProxy = !state.posProxy;
  },
  toggleFullSettings(state) {
    state.fullSettings = !state.fullSettings;
  },
  toggleKeepAlive(state) {
    state.keepAlive = !state.keepAlive;
  },
  setIdle(state, val) {
    state.idle = val;
  },
  setPrinter(state, val) {
    state.printer = val;
  },
  toggleFreshdesk(state) {
    state.useFreshdesk = !state.useFreshdesk;
  },
};

const getters = {
  isLoading: (state) => (key) => state.loaders.includes(key),
};

const actions = {
  toggleDarkMode({ commit }) {
    commit("toggleDarkMode");
  },
  startLoading({ commit }) {
    commit("startLoading");
  },
  stopLoading({ commit }) {
    commit("stopLoading");
  },
  toggleMobileDrawer({ commit }) {
    commit("toggleMobileDrawer");
  },
  toggleScheduleDrawer({ commit }) {
    commit("toggleScheduleDrawer");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
