import store from "@/store";
import { baseGuard } from "./baseGuard";

export const ensureAuth = (options = { active: (to, from) => true }) => {
  return baseGuard(options, (to, from, next) => {
    if (!store.getters["auth/isAuthenticated"]) {
      next({
        name: "auth.login",
        query: {
          go: to.fullPath,
        },
      });
    } else {
      next();
    }
  });
};
