<script>
export default {
  name: "SetupLayoutContent",
  functional: true,
  render(h, context) {
    return h("div", { class: "setup-layout__content mx-n3 mx-md-0" }, [
      context.slots().default,
    ]);
  },
};
</script>
