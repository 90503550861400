var render = function render(){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"v-data-table-header"},[_c('tr',{staticClass:"vx-data-list-headers"},_vm._l((_vm.headers),function(header){return _c('th',{key:header.propertyPath,class:{
        sortable: header.sortable,
        active: _vm.sortOptions && header.propertyPath === _vm.sortOptions.sortBy,
        'text-right': header.align === 'right',
        [header.cssClass]: true,
      },on:{"click":function($event){$event.stopPropagation();return _vm.headerClick(header)}}},[_c('span',{staticClass:"header-label"},[_vm._v(" "+_vm._s(header.label)+" ")]),(_vm.sortOptions && header.propertyPath === _vm.sortOptions.sortBy)?_c('v-icon',{class:{
          'sort-asc': _vm.sortOptions.sortOrder === _vm.SORT_ORDER.ASC,
          'sort-desc': _vm.sortOptions.sortOrder === _vm.SORT_ORDER.DESC,
          'v-data-table-header__icon': true,
        },attrs:{"data-testid":"sortIcon","small":""}},[_vm._v(" $sort ")]):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }